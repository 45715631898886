.lk-start-title {
	font-size: 60px;
	font-family: $F_Title;
	font-weight: 500;
}

.lk-start-desc {
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.02em;
	width: 480px;
	max-width: 100%;
	margin-top: 40px;
}

.lk-start-padding {
	padding: 0 64px;

	&-small {
		padding: 0 40px;
	}
}

.lk-start-first {
	display: flex;

	&__img {
		width: 250px;
		flex-shrink: 0;
		margin-left: 80px;
	}

	&__absolute-img {
		position: absolute;
		right: 20px;
		top: 0;
	}
}

.lk-start-slider {
	position: relative;
	display: flex;
	margin-top: 56px;
	margin-bottom: 56px;

	> .lk-start-slider-itm {
		min-width: 100%;
	}

	&-itm {
		position: relative;
		padding-bottom: 70px;
		height: 100%;

		&__bottom {
			position: absolute;
			bottom: 0;
			left: 64px;
			right: 64px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.btn-main {
				width: 295px;
				max-width: 100%;
			}
		}
	}

	.owl-dots {
		position: absolute;
		bottom: 20px;
		left: 0;
		width: 100%;
		text-align: center;
	}
}

.lk-start-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 24px;
	padding-right: 150px;
	margin-top: 40px;

	&-itm {
		background: #F7F7F7;
		border-radius: 20px;
		min-height: 150px;
		padding: 26px;
		display: flex;
		align-items: flex-end;
		color: var(--text);
		letter-spacing: 0.02em;

		p {
			margin: 0;
		}
	}
}

.partner-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	align-items: center;
	gap: 80px;
	margin-top: 50px;
	margin-bottom: 50px;

	&-itm {
		text-align: center;

		img {
			max-width: 100%;
		}
	}
}

@include tablet {

	.lk-start-title {
		font-size: 40px;
	}

	.lk-start-slider-itm {
		padding-left: 24px;
		padding-right: 24px;
	}

	.lk-start-slider {
		margin-top: 24px;
		margin-bottom: 24px;
	}

	.lk-start-grid {
		grid-template-columns: repeat(2, 1fr);

		&-itm {
			min-height: 100px;
		}
	}

}

@include extra-tablet {
	.lk-start-title {
		font-size: 30px;
	}

	.lk-start-desc {
		font-size: 16px;
	}

	.lk-start-slider-itm {
		padding-left: 16px;
		padding-right: 16px;
	}
	.lk-start-first {
		&__img {
			margin-left: 0;
		}

		&__absolute-img {
			margin-left: 0;
			width: 290px;
			right: 10px;
			top: -30px;
		}
	}

	.lk-start-slider {
		margin-bottom: 50px;
		margin-left: -16px;
		margin-right: -16px;

		.owl-dots {
			bottom: -40px;
		}
	}

	.lk-start-grid {
		margin-bottom: 40px;
	}

	.partner-grid {
		grid-template-columns: repeat(2, 1fr);
		padding: 0 40px;
		gap: 20px 50px;

		img {
			width: 80%;
		}
	}
}

@include mobile {
	.lk-start-slider-itm__bottom {
		left: 16px;
		right: 16px;

		.btn-main {
			padding: 16px 5px;
			font-size: 14px;
		}

		.btn-link {
			font-size: 14px;
			padding-left: 5px;
			padding-right: 5px;
		}
	}
	.lk-start-first {
		display: block;

		&__img {
			width: 190px;
			margin-left: auto;
			margin-top: 20px;
		}
	}

	.lk-start-grid {
		gap: 8px;
		padding-right: 0;
		margin-top: 20px;

		&-itm {
			font-size: 12px;
			padding: 14px;
		}
	}

	.lk-start-first__absolute-img {
		bottom: 60px;
		top: auto;
		width: 160px;
	}

	.partner-grid {
		padding: 0;
		gap: 20px 10px;
	}
}