/* stylelint-disable value-keyword-case */
@use "sass:map";
@use "sass:math";

@mixin flex-layout($options) {

	.#{map-get($options, parentClass)} {
		--gap: #{map.get($options, desktopGap)};
		--elems: #{map.get($options, desktopElems)};

		display: flex;
		flex-wrap: wrap;
		gap: var(--gap);
	}

	.#{map.get($options, itemsClass)} {
		width: calc((100% - ((var(--elems) - 1) * var(--gap))) / (var(--elems)));

		@media (max-width: map-get($options, tablet)) {
			--gap: #{map.get($options, tabletGap)};
			--elems: #{map.get($options, tabletElems)};
		}

		@media (max-width: map-get($options, mobile)) {
			--gap: #{map.get($options, mobileGap)};
			--elems: #{map.get($options, mobileElems)};
		}
	}
}

// $options: (
//   parentClass: "cards",
//   itemsClass: "cards__item",
//   desktopGap: 30px,
//   desktopElems: 3,
//   tablet: "1024px",
//   tabletElems: 2,
//   tabletGap:  30px,
//   mobile: "600px",
//   mobileElems: 1,
//   mobileGap: 20px
// );

// @include flex-layout($options);


@mixin hover {
	@media screen and (min-width: 900px) {
		&:hover {
			@content;
		}
	}
}

@mixin fill($p:0) {
	position: absolute;
	left: $p;
	top: $p;
	right: $p;
	bottom: $p;
}

@mixin img-fill() {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

@mixin pos-center() {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
}

@function toRem($value) {
	$remValue: math.div($value, 16) + rem;
	@return $remValue;
}

@mixin line-clamp($n) {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $n;
	overflow: hidden;
}


@mixin slyled-scrollbar($color1:var(--accent-color),$color2:#F0F1F2) {
	/* W3C standard
   сейчас только для Firefox */
	scrollbar-width: thin;
	scrollbar-color: $color1 $color2;

	/* для Chrome/Edge/Safari */
	&::-webkit-scrollbar {
		height: 10px;
		width: 10px;
		border-radius: 6px;
		cursor: pointer;
	}

	&::-webkit-scrollbar-track {
		background: $color2;
		border-radius: 6px;
		cursor: pointer;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $color1;
		border: 3px solid $color2;
		cursor: pointer;
		border-radius: 6px;

		&:hover {
			background-color: var(--accent-hover-color);
		}
	}
}


@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
}