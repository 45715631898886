/* Sliders */

.ais-RangeSlider {
	margin-bottom: 1.5rem;
	margin-top: .75rem;
	padding: 0 .375rem;

	.rheostat {
		&-tooltip {
			font-weight: bold;
			-moz-user-select: none;
			-webkit-user-select: none;
			-ms-user-select: none;
			user-select: none;
			font-size: 0;
		}

		&-handle {
			background-image: linear-gradient(to top, #f5f5fa, #fff);
			box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),
			0 2px 3px 0 rgba(93, 100, 148, 0.2);
			margin-left: -5px;
			top: -9px;
			width: 1.25rem;
			height: 1.25rem;
			border-radius: 50%;
			border: 2px solid var(--second-color);
			z-index: 2;
		}

		&-background{
			height: 4px;
			border-radius: 0.5rem;
			background: var(--V2-Grey-Mid, #B7BABE);
		}

		&-progress {
			position: absolute;
			height: 4px;
			top: 0px;
			background: var(--accent-color);
		}

	}


	.rheostat-horizontal {
		cursor: pointer;
		width: calc(100% - 10px);
	}
}


.ais-RangeSlider .rheostat-marker {
	background-color: rgba(65, 66, 71, 0.08);
}

/* RangeInput */


.ais-RangeInput {
	&-form {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem 2.4rem;
	}

	&-label {
		margin: 0;
		flex: 1;
	}

	&-submit {
		grid-column: span 2;
		@extend .btn-main;
		@extend .btn--small;
	}

	&-separator {
		display: none;
	}

	&-input {
		background: none;
		color: #21243d;
		font-family: inherit;
		font-size: 0.875rem;
		font-weight: 600;
		min-width: none;
		height: 2rem;
		border-radius: 0.5rem;
		border: 1px solid var(--V2-Grey-Mid, #B7BABE);
		width: 100%;
		text-align: center;
		outline: none;
	}
}

.ais-RangeInput-label:first-of-type {
	margin-right: 6px;
}

.ais-RangeInput-label:last-of-type {
	margin-left: 6px;
}


.ais-RangeSlider .rheostat-tooltip::before {
	color: #e2a400;
	content: '$';
	font-size: 0.6;
	margin-right: 4px;
}

@media (max-width: 899px) {
	.ais-RangeSlider .rheostat-handle {
		height: 1.5rem;
		top: -12px;
		width: 1.5rem;
	}
}