.stand-card {
  margin-bottom: 24px;
  padding: 16px 0 16px 0;

  &__container {
    padding: 0 16px 0 16px;
  }
  &__wrapper {
		display: flex;
	}

  &__top {
		display: flex;
		flex-direction: column;
	}

  &__middle {
    display: flex;
    margin-bottom: 32px;
    gap: 20px 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__name {
    display: flex;
    flex-direction: column;
  }

  &__row {
		margin-bottom: 70px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

  &__logo {
		margin-bottom: 8px;
		display: flex;
		align-items: center;
		line-height: 0;
	}

	&__flag {
		margin-right: 8px;
	}

  &__brand {
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		color: var(--dark-color);
	}

	&__title {
		margin: 0;
		font-weight: 600;
		font-size: 32px;
		line-height: 40px;
		color: var(--extra-dark-color);
	}
  &__inf {
		display: flex;
		align-items: center;
		flex-direction: column;

		&-wrap {
			display: flex;
			margin-left: 170px;
			flex-grow: 1;

			&:not(:last-child) {
				margin-right: 24px;
			}
		}

		&::after {
			width: 100%;
			display: block;
			flex-shrink: 0;
			content: "";
			height: 4px;
			background-color: var(--divider-color);
		}

		&-item {
			display: flex;
			align-items: baseline;
			padding-bottom: 4px;
		}

		&:not(:last-child) {
			margin-right: 24px;
		}
	}

  &__equipment {
    max-width: 300px;
    margin-right: 76px;
  }

  &__number {
		display: block;
		margin-right: 4px;
		font-weight: 600;
		font-size: 32px;
		line-height: 40px;
		color: var(--extra-dark-color);
	}

	&__cur {
		display: block;
		margin-right: 4px;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: var(--extra-dark-color);
	}

  &__prices {
    display: flex;
    flex-direction: column;
  }

  &__price {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: var(--dark-color);

	  &--dealer{
		  color: var(--accent-color);
	  }
  }

  &__p-price {
    margin: 0;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: var(--dark-color);
    font-family: $F_Title;

    span {
      font-weight: 500;
    }
  }

  &__p-price-text {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #4e5359;
    font-family: $F_Title;
    margin-bottom: 20px;
  }
}



@include big-desktop {
  .stand-card {
    &__equipment {
      margin-right: 30px;
    }

    &__packing {
      margin-right: 30px;
    }
  }

  .stand-nav-wrap__right {
    flex-shrink: 0;
  }

}

@include min-desktop {
  .stand-card {
    &__visual {
      margin-left: 20px;
    }

    &__middle {

    }

    &__equipment {
      width: 200px;
      flex-shrink: 0;
      margin-right: 0;
      align-self: center;
    }

    &__prices {
      width: 200px;
      flex-shrink: 0;
      margin-top: 20px;
    }

  }
}

@include extra-tablet {
  .stand-card {
    &__top {
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    &__name {
      width: calc(100% - 200px);
    }

  }
}

@include big-mobile {
  .stand-card {

    &__container{
      overflow: hidden;
    }

    &__top {
      display: block;
    }

    &__name {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
