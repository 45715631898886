@supports(-webkit-appearance: none) or (-moz-appearance: none) {
        input[type='checkbox'],
        input[type='radio'] {
          --active: var(--accent-color);
          --active-inner: #fff;
          --focus: 2px var(--accent-focus-color);
          --border: var(--gray-dark);
          --border-hover: var(--accent-hover-color);
          --background: #fff;
          --disabled: var(--accent-color-disabled);
          --disabled-inner: var(--accent-color-disabled);
          -webkit-appearance: none;
          -moz-appearance: none;
          height: 21px;
          outline: none;
          display: inline-block;
          vertical-align: top;
          position: relative;
          margin: 0;
          cursor: pointer;
          border: 1px solid var(--bc, var(--border));
          background: var(--b, var(--background));
          transition: background .3s, border-color .3s, box-shadow .2s;
          &:after {
            content: '';
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
          }
          &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: .3s;
            --d-t: .6s;
            --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
          }
          &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: .9;
            &:checked {
              --b: var(--disabled-inner);
              --bc: var(--border);
            }
            & + label {
              cursor: not-allowed;
            }
          }
          &:hover {
            &:not(:checked) {
              &:not(:disabled) {
                --bc: var(--border-hover);
              }
            }
          }
          &:focus {
            box-shadow: 0 0 0 var(--focus);
          }
          &:not(.switch) {
            width: 21px;
            &:after {
              opacity: var(--o, 0);
            }
            &:checked {
              --o: 1;
            }
          }
          & + label {
            font-size: 14px;
            line-height: 21px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 4px;
          }
        }
        input[type='checkbox'] {
          &:not(.switch) {
            border-radius: 7px;
            &:after {
              width: 5px;
              height: 9px;
              border: 2px solid var(--active-inner);
              border-top: 0;
              border-left: 0;
              left: 7px;
              top: 4px;
              transform: rotate(var(--r, 20deg));
            }
            &:checked {
              --r: 43deg;
            }
          }
          &.switch {
            width: 38px;
            border-radius: 11px;
            &:after {
              left: 2px;
              top: 2px;
              border-radius: 50%;
              width: 15px;
              height: 15px;
              background: var(--ab, var(--border));
              transform: translateX(var(--x, 0));
            }
            &:checked {
              --ab: var(--active-inner);
              --x: 17px;
            }
            &:disabled {
              &:not(:checked) {
                &:after {
                  opacity: .6;
                }
              }
            }
          }
        }
        input[type='radio'] {
          border-radius: 50%;
          &:after {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            background: var(--active-inner);
            opacity: 0;
            transform: scale(var(--s, .7));
          }
          &:checked {
            --s: .5;
          }
        }
      }