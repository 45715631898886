.cart-page {
	display: grid;
	align-items: flex-start;
	grid-template-columns: 1fr 266px;
	gap: 1.5rem;
	font-family: $F_Title;
	padding-bottom: 2rem;
}

.cart-content {
	h1 {
		margin: 0 0 2rem 0;
		color: var(--v-2-header-color, #346);
		font-weight: 500;
	}
}

.hr-light {
	border: none;
	border-bottom: 1px solid var(--gray-light);
}

.cart-result {
	position: sticky;
	top: 90px;

	.check_box__text .h5 {
		display: inline-block;
		margin-left: 8px;
	}

	ul {
		padding-left: 0;
	}
}

.cart-attr {
	font-size: $fs_small;

	&:not(:last-child){
		margin-bottom: 1rem;
	}

	&__val {
		font-weight: 500;
		font-size: 1rem;
		margin-left: 0.25rem;
	}
}

.cart-cost-result {
	margin-top: 1rem;
	padding-top: 1rem;
	border-top: 1px solid var(--accent-color);
	font-size: 1rem;
	margin-bottom: 2rem;

	&__val {
		display: inline-block;
		margin-left: 0.5rem;
		font-weight: 500;
		font-size: 1.25rem;
		color: var(--accent-color);
	}
}

.cart-steps {
	margin-top: 1.5rem;
}

.cart-step {
	display: none;

	&.active {
		display: block;
	}

	&-head {
		padding: 1rem;
		border-radius: 8px;
		background: var(--v-2-background, #F7F8FA);
		display: flex;
		gap: 1rem;
		align-items: center;
		color: var(--accent-color);
		@extend .h4;

		svg {
			width: 24px;
			height: 24px;
			fill: var(--gray-mid);
			flex-shrink: 0;
		}

		&__name {
			flex: 1;
		}
		&__switch {
			display: flex;
			gap: 1rem;
			font-size: 1rem;
			color: var(--gray-mid);
			flex: 1;
			&__label {
				flex: 1;
				text-align: right;
			}
			&__control {
				flex: 1;
			}

		}

		&__desc {
			color: var(--gray-mid);

		}
	}

	.delivery-price {
		color: var(--accent-color);
	}

	&-content {
		padding: 1.5rem 0;
	}
}

.delivery-line {
	display: flex;
	flex-wrap: wrap;
	gap: 0 1.5rem;

	.select {
		width: 325px;
		max-width: 100%;
	}
}

.cart-remove {
	display: block;
	width: 16px;
	height: 16px;

	svg {
		max-width: 100%;
		height: 100%;
	}

	@include hover {
		svg {
			fill: var(--accent-color);
		}
	}
}

.td-price {
	min-width: 80px;
}

.stat-desc{
	font-size: 15px;
	margin-bottom: .5rem;
}

@include tablet {
	.cart-page {
		display: block;
	}

	.cart-content{
		margin-bottom: 2rem;
	}
}

@include mobile{
	.cart-step-head{
		font-size: $fs_small;
		gap: 0.5rem;
	}

	.cart-content{
		margin-left: -1rem;
		margin-right: -1rem;
	}

	.cart-result{
		margin-left: -1rem;
		margin-right: -1rem;
	}
}