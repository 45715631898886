.decor-brand {
  margin-bottom: 24px;
  padding: 24px 0 16px 0;

  &__container {
    padding: 0 24px 0 16px;
  }

  &__image {
    @include flex-all-center;

    max-width: 194px;
    width: 100%;
    height: 194px;
    border-radius: 8px;
    background-color: var(--main-bg);
  }

  &__content {
    display: flex;
  }

  &__descr {
    max-width: 1022px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 24px;
  }

  &__text {
    margin: 0;
    margin-bottom: 16px;
  }

  &__link {
    margin-bottom: 52px;
    color: var(--accent-color);
  }
}

@include big-mobile {
  .decor-brand {
    &__image {
      flex-shrink: 0;
      width: 80px;
      height: 80px;

      img {
        width: 80%;
        margin: auto;
        display: block;
      }
    }
  }
}
