.page__body.expanded {
	.main-content, .main-content-col {
		width: calc(100% - 246px);
	}
}

.svg-fill {
	display: none;
}

.fill-img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.show-svg-fill {
	.svg-fill {
		display: inline-block;
	}

	.svg-standart {
		display: none;
	}
}

.show-calc-price {
	.js-calc-price-hide {
		display: none;
	}

	.js-calc-price {
		display: inline-block;

		&.td-nowrap {
			display: table-cell;
		}
	}
}

.js-calc-price {
	display: none;
	color: var(--accent-color);
}

.relative {
	position: relative;
}

h1, h2, h3, h4, h5 {
	font-weight: 500;
	margin-top: 0;
}

.h1 {
	font-weight: 500;
	font-size: var(--h1);
	line-height: 1.2;
	font-family: $F_Title;
}

.h2 {
	font-size: var(--h2);
	font-weight: 500;
}

.h3 {
	font-weight: 500;
	font-size: var(--h3);
	line-height: 1.2;
	font-family: $F_Title;
}

.h4 {
	font-weight: 500;
	font-size: var(--h4);
	line-height: 1.2;
	font-family: $F_Title;
}

.h5 {
	font-weight: 500;
	font-size: var(--h5);
	line-height: 20px;
	font-family: $F_Title;
}
a.link:link {
	color:var(--accent-color);
	text-decoration: underline;
}
  
  /* visited link */
a.link:visited {
	color: var(--accent-color);
	text-decoration: underline;
}
  
  /* mouse over link */
a.link:hover {
	color: var(--accent-hover-color);
	text-decoration: none;
}
  
  /* selected link */
a.link:active {
	color: var(--accent-hover-color);
	text-decoration: none;
}
.attention	{
	color: var(--error-color);
}
.w100 {
	width: 100%;
}

.fs-smallest {
	font-size: $fs_smallest;
}

.va-m {
	align-items: center;
	vertical-align: middle;
}

.margin-title {
	margin-bottom: 24px;
}

.margin-small {
	margin-bottom: 0.5rem;
}

.margin-big {
	margin-bottom: 3rem;
}

.main-content {
	padding: 16px;
	width: calc(100% - 104px);
}

.widget {
	background: #fff;
	padding: 24px 16px;
	box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
	border-radius: 8px;
	overflow: hidden;
	color: var(--v-2-header-color);

	&.visible {
		overflow: visible;
	}

	&-title {
		margin-bottom: 1.5rem;
		margin-top: 0;
		font-weight: 500;
	}

	&-subtitle {
		margin-bottom: 1.5rem;
		color: var(--v-2-header-color);
		font-weight: 500;
	}

	&--col-2 {
		grid-column: span 2;
	}
        select{
            border: 0px;
            border-bottom: 1px dashed var(--v-2-header-color);
            font-size: var(--h2);
            font-weight: 500;
            color: var(--v-2-header-color);
        }
}

.text-format {
	h1 {
		margin-bottom: 2.5rem;
	}

	h4 {
		margin-bottom: 0;
	}

	h5 {
		margin-bottom: 0;
	}

	b {
		font-weight: 500;
	}

	ul {
		margin: 0;
		padding-left: 1.75rem;
		margin-bottom: 1rem;

		li {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: -.75rem;
				top: .33rem;
				width: 3px;
				height: 3px;
				background: var(--dark-color);
				transform: rotate(45deg);
			}
		}
	}

	ol {
		padding-left: 1.75rem;
		margin: 0 0 1rem;
	}

	a {
		color: var(--accent-color);

		@include hover {
			color: var(--accent-hover-color);
		}
	}
}

.c-main {
	color: var(--accent-color);
}

.c-gray {
	color: var(--v-2-header-color, #346);
}

.owl-nav.disabled {
	display: none;
}

.owl-dots.disabled {
	display: none;
}

.owl-stage {
	display: flex;
	transition: transform .3s ease-in-out;
	backface-visibility: hidden;
}

.owl-dots {
	pointer-events: none;
}

.owl-dot {
	pointer-events: auto;
	width: 8px;
	height: 8px;
	background: none;
	padding: 0;
	margin: 0 4px;
	border: none;
	background: var(--laying-type-hover);
	transition: all .3s ease-in-out;

	&.active {
		width: 24px;
		background: var(--accent-color);
	}
}

.square {
	display: inline-block;
	vertical-align: top;
	transform: translateY(-5px);
	font-size: 0.6em;
}

.modal-block {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1111;
	opacity: 0;
	pointer-events: none;
	transition: opacity .3s ease-in-out;

	&.active {
		opacity: 1;
		pointer-events: auto;
	}
}

.modal {
	@include fill();
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: all .3s ease-in-out;
	transform: translateY(15px);

	&.active {
		opacity: 1;
		transform: translateY(0px);

		.modal-content {
			pointer-events: auto;
		}
	}

	&-fade {
		@include fill();
		background: #000;
		opacity: 0.8;
		cursor: pointer;
	}

	&-content {
		background: #fff;
		border-radius: 8px;
		padding: 24px;
		max-height: 90%;
		overflow: auto;

		@include slyled-scrollbar();
	}

	&__title-desc {
		font-family: $F_Title;
		margin-bottom: 24px;
	}
}

.modal-close {
	float: right;
	position: sticky;
	top: 0;
	transform: translateY(-15px);
}

#modal-copy-buffer {
	.modal-content {
		width: 350px;
		text-align: center;

		.h1 {
			margin-bottom: 30px;
		}
	}
}
#modal-success {
	.modal-content {
		width: 544px;
		text-align: center;
		overflow: visible;
	}

	.btn-second {
		width: 177px;
	}
}

#modal-bonus-output {
	.modal-content {
		width: 330px;
		text-align: center;
		overflow: visible;
	}

	.btn-second {
		width: 177px;
	}
}

#modal-bonus-add {
	.modal-content {
		width: 544px;
		padding: 40px 16px;
	}

	.btn-main {
		margin: 30px auto 0;
		display: block;
	}
}

.modal-stas-top {
	margin: -65% auto 15px;
	display: block;
}

.cross {
	position: relative;
	width: 18px;
	height: 18px;
	cursor: pointer;

	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		width: 100%;
		height: 2px;
		background: black;
		transform: rotate(45deg);
		border-radius: 4px;
	}

	&:after {
		transform: rotate(-45deg)
	}

	@include hover {
		&:before, &:after {
			background: var(--gray-dark);
		}
	}
}

.style-table {
	width: 100% !important;
	background-color: var(--light-color);
	font-family: $F_Title;
	border-spacing: 0;

	th {
		font-weight: 600;
		font-size: 10px;
		line-height: 12px;
		color: var(--dark-color);
	}

	thead th.t-cat__header {
		border-bottom: 1px solid var(--gray-mid);
	}

	tr:first-child{
		td:nth-child(4){
			&:hover .popup-preview {
				top: 0;
			}
		}
	}
	td {
		padding: 13.5px 26px 13.5px 16px !important;
		font-weight: 400;
		font-size: 10px;
		line-height: 12px;
		color: var(--dark-color);
		border-bottom: 1px solid var(--t-select-row-color);

		&:nth-child(4) {
			position: relative;

			&:hover .popup-preview {
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
				pointer-events: auto;
			}
		}
	}

	&__row {
		&:hover {
			background-color: var(--line-color) !important;

			& td:nth-child(1) {
				background-color: var(--line-color) !important;
			}

			& td:nth-last-child(2) {
				background-color: var(--line-color) !important;
			}
		}
	}
}

.accordion {
	background: #fff;
	border-radius: 8px;

	.check_box__item {
		margin-right: 5px;
	}

	&-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: var(--extra-dark-color);
		min-height: 52px;
		padding: 5px 16px;
		cursor: pointer;

		&.active {
			&:after {
				transform: rotate(180deg);
			}
		}

		&:after {
			content: "";
			border: 5px solid transparent;
			border-top: 7px solid var(--gray-dark);
			position: relative;
			top: 2px;
			transition: all .3s ease-in-out;
			transform-origin: center 25%;
		}

		@include hover {
			color: var(--gray-dark);

			.check_box__text {
				color: var(--gray-dark);
			}
		}
	}

	&-content {
		padding: 16px;

		&--link {
			padding: 0;
		}

		.check_box {
			align-items: center;

			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}

	&-link-itm {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 16px;
		color: var(--black);

		@include hover {
			background: var(--gray-light);
		}

		.status-label {
			flex-shrink: 0;
		}

		&__text {
			font-weight: 400;
			font-size: 10px;
			line-height: 13px;
			font-family: $F_Title;
		}
	}
}

.flex-sb {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.accordion-all-link {
	padding: 9px 12px 7px;
	margin: 16px;
	display: inline-block;
	color: #ABB8CA;
	border: 1px solid #ABB8CA;
	border-radius: 4px;
	font-family: $F_Title;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;

	@include hover {
		background: #ABB8CA;
		color: #fff;
	}
}

.main-content-col {
	height: auto;
	display: flex;
	flex-direction: column;
}

.td-nowrap {
	white-space: nowrap;
}

.table-scroll {
	overflow: auto;
	@include slyled-scrollbar();

	.dataTables_wrapper {
		@include slyled-scrollbar();
	}
}
.discount {
	input {
		padding: 0.5rem 1.5rem 0.5rem 0.5rem;
		width: 64px;
		height: 32px;
		text-align: center;
		border-radius: 8px;
		border: 1px solid var(--v-2-grey-light, #F0F1F2);
		outline: none;
	}
}

.counter {
	position: relative;

	input {
		padding: 0.5rem 1.5rem 0.5rem 0.5rem;
		width: 64px;
		height: 32px;
		text-align: center;
		border-radius: 8px;
		border: 1px solid var(--v-2-grey-light, #F0F1F2);
		outline: none;
	}

	&-btn {
		position: absolute;
		right: 0;
		top: 0;
		width: 24px;
		height: 50%;
		cursor: pointer;

		&:before {
			content: "";
			position: absolute;
			left: 4px;
			top: 50%;
			margin-top: -6px;
			border: 5px solid transparent;
			border-bottom: 5px solid var(--gray-mid);
			transform-origin: center 75%;
		}

		@include hover {
			&:before {
				border-bottom-color: var(--accent-color);
			}
		}

		&.js-counter-down {
			top: 50%;

			&:before {
				transform: rotate(180deg);
				margin-top: -10px;
			}
		}
	}

	&--text {
		input {
			padding: 0;
			border: none;
		}
	}
}

.td-hide {
	display: none;
}

@include tablet {
	.page__body.expanded {
		.main-content {
			width: 100%;
		}
	}

	.main-content {
		width: 100%;
	}

	.main-content-col {
		min-height: calc(100vh - 82px);
	}

	.widget--col-2 {
		grid-column: auto;
	}


}

@include mobile {
	.accordion-head {
		padding-left: 8px;
	}
	.accordion-content {
		padding: 8px;

		&--link {
			padding: 0;
		}
	}
	.accordion-link-itm {
		padding-left: 8px;
		padding-right: 8px;
	}

	.accordion-all-link {
		margin: 8px;
	}
}