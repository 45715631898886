.popup-menu {
  position: absolute;
  right: 78px;
  top: 52px;
  border-radius: 4px;
  max-width: 194px;
  width: 100%;
  padding: 8px 6px;
  box-shadow: 4px 8px 20px rgba(0, 13, 84, 0.2);
  font-family: var(--extra-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-transform: initial;
  color: var(--dark-color);
  background-color: var(--light-color);
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.1);
  border-radius: 8px;
  z-index: 3;

  @include mobile {
    top: 30px;
  }

  &__item {
    transition: all 0.3s ease-in-out;

    &:hover {
      border-radius: 8px;
      background-color: var(--bg-btn-action);
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  @include mobile {
    top: 34px;
    left: -178px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &--active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  &__title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.03em;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    color: #334466;
	  border: none;
	  background: none;
	  padding: 0;
	  cursor: pointer;

    &:hover,
    &:focus {
      color: var(--accent-color);
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 26px;
    padding: 4px 5px;
  }
}
