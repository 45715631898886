.chart_legend{
    width: 10px; 
    height: 10px; 
    border-radius: 50%;
    margin-right: 1rem;
    display: inline-block;
    &__black{
        background: #1D1D1B;
    }
    &__red{
        background: #e62e2e;
    }

}