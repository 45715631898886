.decor-spec {
  margin-bottom: 24px;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  background-color: var(--light-color);

  &__container {
    padding: 0 16px;
  }

  &__content {
    display: flex;
    overflow: hidden;
  }

  &__left {
    max-width: 810px;
    width: 50%;
    margin-right: 48px;
    display: flex;
    flex-direction: column;
  }

  &__right {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    overflow-y: scroll;
    overflow: overlay;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: var(--light-color);
      background: rgba(255, 255, 255, 0.8);
    }
  }

  &__image {
    flex: 50%;
    max-width: 50%;
    padding: 10px;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  &__image_full {
    flex: 100%;
    max-width: 100%;
    padding: 10px;
  }

  &__cols {
    max-width: 790px;
    width: 100%;
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 64px;
    }
  }

  &__col {
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 62px;
    }
  }

  &__line {
    @include flex-all-sb;

    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--line-color);
  }

  &-main__prop {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark-color);
  }

  &-main__value {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--dark-color);
  }

  &-extra {
    &__line {
      @include flex-all-sb;

      padding-bottom: 8px;
      margin-bottom: 16px;
      border-bottom: 1px solid var(--line-color);
    }

    &__prop {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: var(--dark-color);
    }

    &__value {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: var(--dark-color);
    }
  }

  &__tooltip {
    margin-left: 10px;
  }
}

@include desktop {
  .decor-spec {
    &__cols {
      display: block;
    }

    &__col {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

@include min-desktop {
  .decor-spec {
    &__image {
      flex: 100%;
      max-width: 100%;
    }
  }
}

@include big-mobile {
  .decor-spec {
    &__content {
      display: block;
    }
    &__left{
      width: 100%;
    }
    &__right{
      width: 100%;
      max-height: 350px;
    }
    &__image {
      flex: 100%;
      max-width: 100%;
    }
  }
}
