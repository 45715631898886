.table-stat {
	font-size: $fs_smallest;

	table.dataTable {
		th, td {
			padding: 1rem;
			font-weight: 400;

			&:first-child{
				position: sticky !important;
				left: 0;
				background: #fff;
				border-right: 1px solid var(--gray-mid);
				z-index: 1;
				max-width: 100%;
			}
		}
	}

	.dataTables_wrapper {
		overflow: auto;
		width: 100%;
	}

	.dataTables_paginate{
		position: sticky;
		left: 0;
	}
}
