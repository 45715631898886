.brand {
  background-color: var(--main-bg);

  &__flex {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__container {
    padding: 0;
    display: flex;
  }

  &__title {
    margin: 0;
    padding-bottom: 16px;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: var(--extra-dark-color);

    &--small {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    &--indent {
      margin-left: 16px;
    }
  }
}

.t-catalog__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  flex-wrap: wrap;
  gap: 20px 0;
}

@include tablet {
  .brand__container {
    padding-right: 0;
  }
  .brand__flex {
    padding-right: 16px;
  }
}

@include big-mobile {
  .brand__flex {
    section {
      margin-left: -15px;
      margin-right: -15px;
      width: auto;
    }
  }
}
