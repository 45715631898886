.breadcrumb-lk {
  padding: 24px 0;

  &__list {
    display: flex;
  }

  &__item {
    @include flex-all-center;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &:not(:last-child)::after {
      display: block;
      margin-left: 8px;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 5px;
      border-color: transparent transparent transparent #c3cad9;
    }

    &--active {
      color: var(--extra-dark-color);
    }

    &:last-child {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__link {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: var(--breadcrumb-color);
    transition: all 0.3s ease-in-out;

    &:hover {
      color: var(--accent-color);
    }
  }
}
