/* stylelint-disable */

@mixin for-desktop {
  @media (min-width: (1025px)) {
    @content;
  }
}

@mixin high-quality {
  @media (max-width: (1600px)) {
    @content;
  }
}

@mixin big-desktop {
  @media (max-width: (1440px)) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: (1300px)) {
    @content;
  }
}

@mixin min-desktop {
  @media (max-width: (1200px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin extra-tablet {
  @media (max-width: (800px)) {
    @content;
  }
}

@mixin main-tablet {
  @media (max-width: (768px)) {
    @content;
  }
}

@mixin big-mobile {
  @media (max-width: (658px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (576px)) {
    @content;
  }
}

@mixin min-mobile {
  @media (max-width: (390px)) {
    @content;
  }
}

@mixin limit-height {
  @media (max-height: (596px)) {
    @content;
  }
}

@mixin limit-height-mobile {
  @media (min-height: 690px) and (max-width: 576px) {
    @content;
  }
}
