.decor-collection {
  margin-bottom: 24px;
  padding: 24px 0 40px 0;

  &__container {
    padding: 0 20px 0 24px;
  }

  &__wrapper {
    display: flex;
  }

  &__top {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    line-height: 0;
  }

  &__flag {
    margin-right: 8px;
  }

  &__brand {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark-color);
  }

  &__row {
    margin-bottom: 70px;
    display: flex;
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--extra-dark-color);
  }

  &__subtitle {
    margin: 0;
    padding-bottom: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark-color);
  }

  &__inf {
    display: flex;
    align-items: center;
    flex-direction: column;

    &-wrap {
      display: flex;
      margin-left: 170px;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    &::after {
      width: 100%;
      display: block;
      flex-shrink: 0;
      content: "";
      height: 4px;
      background-color: var(--divider-color);
    }

    &-item {
      display: flex;
      align-items: baseline;
      padding-bottom: 4px;
    }

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__funs {
    margin-right: 245px;
    flex-shrink: 0;
  }

  &__fun {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &-wrap {
      display: flex;
    }
  }

  &__type {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--main-bg);

    &-wrap {
      display: flex;
      flex-direction: column;
    }

    &-name {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: var(--extra-dark-color);
    }

    svg {
      width: 32px;
      height: 32px;
      fill: var(--extra-bg);
    }

    &-item {
      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }

  &__drop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border: 2px solid var(--main-bg);
  }

  &__size {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-item {
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    &-num {
      @include flex-all-center;

      width: 46px;
      height: 26px;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      background-color: var(--main-bg);

      &--active {
        color: var(--light-color);
        background-color: var(--main-svg-color) !important;
      }
    }
  }

  &__adv {
    max-width: 198px;
    width: 100%;
    display: flex;
    align-items: center;

    &-text {
      margin: 0;
      padding-left: 16px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: var(--dark-color);
    }

    &-wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 92px;
      row-gap: 32px;
    }
  }

  &__svgs {
    position: relative;
    width: 56px;
    height: 56px;
    flex-shrink: 0;

    .adv-ramka {
      position: absolute;
      width: 56px;
      height: 56px;
      fill: var(--main-svg-color);
    }

    .adv-craft {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      fill: var(--main-svg-color);
      transform: translate(-50%, -50%);
    }
  }

  &__number {
    display: block;
    margin-right: 4px;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--extra-dark-color);
  }

  &__cur {
    display: block;
    margin-right: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--extra-dark-color);
  }

  &__price {
    margin: 0;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--extra-dark-color);
  }

  &__text {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #4e5359;
  }
}

@include desktop {
  .decor-collection {
    &__wrapper {
      display: block;
    }

    &__inf-wrap {
      margin-left: 30px;
    }

    &__subtitle {
      width: 100%;
    }

    &__prices {
      align-self: flex-end;
    }

    &__price {
      font-size: 22px;
      line-height: 1.2;
    }

    &__number {
      font-size: 22px;
    }

    &__cur {
      font-size: 14px;
    }

    &__text {
      font-size: 12px;
      line-height: 1.2;
    }

    &__funs {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 60px;
      margin-right: 0;
    }

    &__fun {
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
}

@include big-mobile {
  .decor-collection {
    &__row {
      display: block;
      margin-bottom: 40px;
    }

    &__inf-wrap {
      margin-left: 0;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    &__funs{
      display: block;
    }

    &__adv-wrap{
      display: block;
    }

    &__adv{
      &:not(:last-child){
        margin-bottom: 30px;
      }
    }
  }
}
