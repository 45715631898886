:root {
	// base
	--font-family: "Inter", sans-serif;
	--content-width: 1440px;
	--content-fluid-width: 1840px;
	--container-offset: 40px;
	--container-width: calc(var(--content-width) + (var(--container-offset) * 2));
	--container-fluid-width: calc(
			  var(--content-fluid-width) + (var(--container-offset) * 2)
	);

	// colors
	--light-color: #fff;
	--dark-color: #202b40;
	--extra-dark-color: #334466;
	--more-dark-color: #0d111a;
	--accent-color: #e62e2e;
	--second-color: #e62e2e;
	--accent-hover-color: #C01722;
	--accent-color-disabled: #bf656b;
	--accent-focus-color: rgba(192, 23, 34, 0.3);
	--main-bg: #f7f8fa;
	--extra-bg: #7d8fb3;
	--bg-btn-action: #f7f8fa;
	--bg-pagination-color: #edeff2;
	--t-select-row-color: #edeff2;
	--user-border-color: #dfe3eb;
	--theme-checkbox-bg: #e3e7eb;
	--input-placeholder-color: #c3cad9;
	--input-color: #0d111a;
	--main-svg-color: #7d8fb3;
	--extra-svg-color: #dfe3eb;
	--action-svg-color: #62708c;
	--filter-svg-color: #62708c;
	--input-focus-color: #e62e2e;
	--copy-descr-color: #7d8fb3;
	--divider-color: #dfe3eb;
	--tree-arrow-color: #c3cad9;
	--search-svg-color: #c3cad9;
	--light-gray-color: #c3cad9;
	--laying-type-color: #f5333f;
	--v2-red: #f5333f;
	--V2-Background: #F7F8FA;
	--laying-type-hover: #cacdd2;
	--breadcrumb-color: #7d8fb3;
	--line-color: #edeff2;
	--gray-mid: #B7BABE;
	--gray-dark: #4E5359;
	--gray-light: #F0F1F2;
	--black: #171717;
	--text: #0F2744;
	--error-color: #e62e2e;
	--promotion-color: linear-gradient(16deg, #FFCF3C 5.42%, #FFD500 94.55%);
	--fire-color: #FFD500;
	--sale-color:  #F5333F;
	--markdown-color: #FF6610;

	--h1: 2rem;
	--h2: 1.75rem;
	--h3: 1.5rem;
	--h4: 1.25rem;
	--h5: 1rem;
	--v-2-header-color: #346;

	--small: 0.875rem;
	--smallest: 0.75rem;
}

// colors
.template--fineapp {
	--accent-color: #00B1E6;
	--accent-color-disabled:  #5ccbed;
	--accent-hover-color: #008ECB;
	--accent-focus-color:rgba(0, 142, 203, 0.3);

	--second-color: #97BF0D;
	--second-hover-color: #63A000;

	--input-focus-color: #00B1E6;
}

.template--iteem {
	--accent-color: #1D1D1B;
	--accent-color-disabled: #cacac4;
	--accent-hover-color: #000000;
	--accent-focus-color:rgba(0, 0, 0, 0.3);

	--input-focus-color:rgba(0, 0, 0, 0.4);
}

$F_Title: "Stapel";
$F_Main: "Inter", sans-serif;

$fs_h1: var(--h1);
$fs_h2: var(--h2);
$fs_h3: var(--h3);
$fs_h4: var(--h4);
$fs_h5: var(--h5);
$fs_small: var(--small);
$fs_smallest: 10px;

@include big-mobile {
	:root {
		--container-offset: 20px;
	}
}
