html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
	font-family: $F_Title;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ul {
  list-style-type: none;
}

.page {
  height: 100%;
  font-family: $F_Title;
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  height: 100%;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  // overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  // cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

// убрать стрелки у input во всех браузерах
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.container-fluid {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-fluid-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.fixed-scroll {
  scroll-margin-top: 100px;
}

section {
  background-color: var(--light-color);
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 8px;
}
