.btn {
	border-radius: 4px;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	transition: 0.2s all ease;
	cursor: pointer;

	&-main {
		display: inline-block;
		text-align: center;
		border-radius: 8px;
		font-weight: 500;
		transition: 0.2s all ease;
		cursor: pointer;
		background: var(--accent-color);
		border: none;
		outline: none;
		padding: 15px 19px;
		color: #fff;
		font-size: 15px;
		line-height: 19px;
		font-family: $F_Title;

		@include hover {
			background: var(--accent-hover-color);
		}

		&.disabled {
			pointer-events: none;
			background: var(--gray-light);
			color: var(--gray-mid);
		}
	}

	&-line {
		display: inline-block;
		text-align: center;
		border-radius: 8px;
		font-weight: 500;
		transition: 0.2s all ease;
		background: none;
		cursor: pointer;
		border: 1px solid var(--accent-color);
		color: var(--accent-color);
		outline: none;
		padding: 15px 19px;
		font-size: 15px;
		line-height: 19px;
		font-family: $F_Title;

		@include hover {
			background: var(--accent-hover-color);
			border-color: var(--accent-hover-color);
			color: #fff;
		}

		&--gray {
			border-color: var(--gray-mid);
			color: var(--gray-mid);

			@include hover {
				background: var(--gray-mid);
				border-color: var(--gray-mid);
			}
		}

		&.disabled {
			pointer-events: none;
			background: var(--gray-light);
			color: var(--gray-mid);
		}

		&-second{
			border: 1px solid var(--second-color);
			color: var(--second-color);

			@include hover {
				background: var(--second-hover-color);
				border-color: var(--second-hover-color);
			}
		}
	}

	&-second {
		@extend .btn-main;
		background: var(--gray-light);
		color: var(--gray-dark);

		@include hover {
			background: var(--gray-mid);
			color: #000;
		}
	}

	&--small {
		padding: 10px 19px;
	}

	&__pagination {
		padding: 9.5px 4px;
		border: 2px solid transparent;
		color: var(--dark-color);
		min-width: 35px;
		display: inline-block;
		text-align: center;

		&:not(:last-child) {
			margin-right: 8px;
		}

		&:hover {
			background-color: var(--bg-pagination-color);
		}

		&--active {
			border: 2px solid var(--accent-color);

			&:hover {
				background-color: transparent;
			}
		}
	}

	&__show {
		border: 1px solid #dfe3eb;
		padding: 8.5px 16px;

		&:hover {
			background-color: var(--bg-pagination-color);
			color: var(--dark-color);
		}
	}

	&__up {
		border: 1px solid #dfe3eb;
		padding: 8.5px 16px;
		margin-left: 14px;

		&:hover {
			background-color: var(--bg-pagination-color);
			color: var(--dark-color);
		}
	}

	&__action {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		width: 24px;
		height: 26px;
		transition: all 0.3s ease-in-out;

		&:hover {
			background-color: var(--bg-btn-action);
			cursor: pointer;
		}

		&.active {
			background-color: var(--bg-btn-action);

			svg {
				fill: var(--accent-color);
			}
		}

		&:hover svg {
			fill: var(--accent-color);
		}

		svg {
			width: 16px;
			height: 16px;
			fill: var(--action-svg-color);
			transition: all 0.3s ease-in-out;
		}
	}

	&__search {
		@include flex-all-center;

		all: unset;
		width: 48px;
		padding: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.3s ease-in-out;

		&:hover svg {
			fill: var(--accent-color);
		}
	}

	&__tree {
		font-weight: 600;
		font-size: 12px;
		line-height: 1.2;
		color: var(--extra-dark-color);
		transition: all 0.3s ease-in-out;
		cursor: pointer;

		&--no-active {
			color: var(--light-gray-color);
		}

		&--active {
			color: var(--dark-color);
		}
	}

	&__all-catalog {
		display: flex;
		align-items: center;

		svg {
			margin-right: 4px;
		}
	}

	&__reflect {
		padding: 25px 4px 0 4px;

		svg {
			width: 16px;
			height: 16px;
			fill: #0d111a;
			flex-shrink: 0;
		}
	}

	&__cart-popup {
		width: 100%;
		padding: 12px 0;
		border: 1px solid var(--accent-color);
		border-radius: 6px;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: 0.02em;
		text-align: center;
		color: var(--accent-color);
		background-color: var(--light-color);
		transition: all 0.2s ease;

		&:hover {
			color: var(--light-color);
			background-color: var(--accent-color);
		}

		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	&__decor-popup {
		width: 48px;
		height: 48px;
		margin-left: 24px;
		background-color: var(--main-bg);

		svg {
			width: 22px;
			height: 22px;
			fill: var(--action-svg-color);
		}
	}

	&__cart-add {
		display: flex;
		align-items: center;
		padding: 12px;
		border-radius: 8px;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: 0.02em;
		color: var(--light-color);
		background-color: var(--accent-color);
		transition: all 0.3s ease-in-out;

		&:hover {
			cursor: pointer;
			background-color: #bc2626;
		}

		svg {
			width: 24px;
			height: 24px;
			margin-right: 8px;
			fill: var(--light-color);
		}
	}

	&__about {
		&-more {
			padding: 15px 24px;
			margin-top: auto;
			border-radius: 8px;
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			letter-spacing: 0.02em;
			color: var(--light-color);
			background-color: var(--extra-bg);
			transition: all 0.2s ease-in-out;

			&:hover {
				background-color: #677694;
			}
		}
	}
}

.btn-link {
	color: var(--text);
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.02em;
	padding: 15px 16px;
	text-align: center;
	cursor: pointer;
	border-radius: 8px;
	transition: all .2s ease-in-out;

	@include hover {
		background: var(--text);
		color: #fff;
	}
}