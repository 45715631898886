.header {
	position: sticky;
	top: 0;
	z-index: 201;

	&-gamburger {
		display: none;
	}

	&__container {
		position: relative;
		padding-top: 16px;
		padding-bottom: 16px;
		border-bottom: 2px solid #edeff2;
		background-color: var(--light-color);

		@include flex-all-sb;
	}

	&__logo {
		display: flex;
		align-items: center;

		&__fineapp {
			width: 9.25rem;
			margin-right: 4.7rem;
		}
	}

	&-main {
		flex: 1;
	}

	&-phone {
		display: inline-block;
		color: var(--v-2-grey-dark);
		font-weight: 500;
		font-family: $F_Title;
		transition: color .2s;
		line-height: 100%;
		margin-bottom: -.2rem;

		@include hover {
			color: var(--accent-color);
		}
	}
}

.menu {
	&__list {
		@include flex-all-sb;
	}

	&__item {
		position: relative;
		width: 48px;
		height: 48px;
		padding: 12px;
		border-radius: 8px;
		transition: all 0.3s ease-in-out;

		&:not(:first-child):not(:last-child):hover {
			background-color: var(--main-bg);

			.menu__item__ico {
				fill: var(--accent-color);
			}
		}

		&:first-child:hover {
			background-color: var(--main-bg);
		}

		&--user {
			border: 2px solid var(--user-border-color);
			padding: 0;
			flex-shrink: 0;
		}

		&--switch {
			padding: 12px 0;

			&:hover {
				background: none;
			}
		}

		&:not(:nth-last-child(-n + 2)) {
			margin-right: 16px;
		}

		&:nth-last-child(1) {
			margin-left: 24px;
		}

		svg {
			width: 24px;
			height: 24px;
			fill: var(--main-svg-color);
			transition: all 0.3s ease-in-out;
		}

		&--active {
			width: 100%;
			background-color: var(--main-bg);
		}

		&--active:nth-child(n + 2):nth-child(-n + 4)::before {
			position: absolute;
			top: -4px;
			left: 44px;
			content: "";
			display: block;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: var(--accent-color);
			transform: translateX(-24px);
		}
	}
}

.avatar-menu {
	position: absolute;
	right: 40px;
	top: 100%;
	background: #fff;
	z-index: 2;
	margin-top: 8px;
	box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.1);
	border-radius: 8px;
	width: 230px;
	padding: 16px;
	font-size: 12px;
	line-height: 1.3;
	letter-spacing: 0.02em;
	color: var(--extra-dark-color);
	transition: all .2s ease-in-out;
	transform: translateY(-8px);
	opacity: 0;
	pointer-events: none;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 10px;
	}

	&.active {
		transform: translateY(0);
		opacity: 1;
		pointer-events: auto;
	}

	&-head {
		padding-bottom: 16px;
		margin-bottom: 16px;
		border-bottom: 1px solid var(--user-border-color);

		&__name {
			margin-bottom: 6px;
		}

		&__login {
			font-size: 10px;
			color: var(--extra-bg);
		}
	}

	&-list {
		padding: 0;
		margin: 0;

		&:not(:last-child) {
			padding-bottom: 16px;
			margin-bottom: 16px;
			border-bottom: 1px solid var(--user-border-color);
		}

		li:not(:last-child) {
			margin-bottom: 8px;
		}

		a {
			display: flex;
			gap: 4px;
			align-items: center;
			min-height: 34px;
			color: inherit;
			font-weight: 500;
			border-radius: 8px;
			transition: all .2s ease-in-out;

			.svg {
				width: 24px;
				object-fit: none;

				&.svg-user-ico {
					height: 16px;
				}
			}

			@include hover {
				background: var(--main-bg);
				color: var(--accent-color);
			}

			&.active {
				background: var(--accent-color);
				color: #fff;

				path {
					fill: #fff;
				}
			}

		}
	}
}

.cart-info {
	display: flex;

	&__descr {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		margin-left: 20px;
		font-weight: 400;
		font-size: 10px;
		line-height: 12px;
	}

	&__count {
		font-weight: 600;
	}

	&__sum {
		font-weight: 600;
	}

	.menu__item__close {
		display: none;
	}
}

.cart-popup-hover {
	@include hover {
		.cart-popup {
			opacity: 1;
			pointer-events: auto;
			transform: translateY(0);
		}
	}
}

.user {
	&__icon {
		height: 100%;

		@include flex-all-center;
	}

	&__image {
		border-radius: 8px;
	}
}

.switch-theme {
	position: relative;
	display: block;
	width: 48px;
	height: 24px;

	&__input {
		opacity: 0;
		width: 0;
		height: 0;

		&:checked + .switch-theme__slider {
			background-color: var(--accent-color);

			@include hover {
				background-color: var(--accent-hover-color);
			}
		}

		&:checked + .switch-theme__slider::before {
			transform: translateX(24px);
		}
	}

	&__slider {
		position: absolute;
		border-radius: 16px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: var(--theme-checkbox-bg);
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		&:hover {
			background-color: #c7ced5;
		}

		&::before {
			position: absolute;
			content: "";
			border-radius: 50%;
			width: 20px;
			height: 20px;
			top: 2px;
			left: 2px;
			background-color: var(--light-color);
			box-shadow: 0px 2px 4px rgba(29, 31, 33, 0.2);
			transition: all 0.3s ease-in-out;
		}
	}
}

.top-search {
	&__wrapper {
		max-width: 100%;
		padding: 0;
		flex-shrink: 0;

		&--wider {
			width: 360px;
		}
	}

	&__container {
		display: flex;
		width: 48px;
		height: 48px;
		max-width: 100%;
		border: 2px solid transparent;
		border-radius: 8px;
		transition: color 0.5s ease-in-out;

		&--expanded {
			width: 100%;
			background-color: var(--main-bg);

			& .top-search__form {
				width: 100%;
				padding: 4px;
			}
		}
	}

	&__form {
		width: 0px;
		align-self: center;
	}

	&__input {
		all: unset;
		width: 100%;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: var(--input-color);
		transition: all 0.3s ease-in-out;

		&::placeholder {
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
			color: var(--input-placeholder-color);
		}
	}
}

.gamburger {
	display: inline-block;
	position: relative;
	height: 20px;
	width: 20px;
	transition: all 0.4s ease-in-out;
	margin-top: -2px;

	&:after, &:before {
		content: '';
		left: 0;
		width: 100%;
		height: 2px;
		position: absolute;
		top: 66%;
		background-color: var(--accent-color);
		transition: all 0.5s;
	}

	&:before {
		top: 30%;
	}

	&.active {
		&:after {
			transform: rotate(45deg);
			top: 57%;
		}

		&:before {
			transform: rotate(-45deg);
			top: 53%;
		}
	}
}

.header__logo__small {
	display: none;
}

.header-wallet-itm {
	display: flex;
	align-items: center;
	width: auto;
	color: var(--extra-bg);

	span {
		display: inline-block;
		margin-left: 10px;
		font-weight: 500;
		font-size: 16px;
	}

	@include hover {
		color: var(--accent-color);

		svg {
			fill: var(--accent-color);
		}
	}
}

.cart-popup {
	position: absolute;
	top: 100%;
	margin-top: 32px;
	right: 0;
	width: 375px;
	padding: 0.5rem 1.5rem 1.5rem;
	background: #fff;
	border-radius: 0.5rem;
	box-shadow: 0px 8px 24px 0px rgba(21, 21, 22, 0.10);
	max-height: 726px;
	display: flex;
	flex-direction: column;
	opacity: 0;
	pointer-events: none;
	transform: translateY(-10px);
	transition: all .3s ease-in-out;

	.table-scroll {
		margin-right: -1.5rem;
		padding-right: 1.5rem;
	}

	&:before {
		content: "";
		position: absolute;
		bottom: 100%;
		height: 35px;
		left: 0;
		width: 100%;
		z-index: 1;
	}

	.order-detail-table {
		th {
			position: sticky;
			top: 0;
			background: #fff;
			border-bottom: 1px solid var(--gray-light);
		}

		tbody tr:first-child td {
			border-top: none;
		}
	}

	&-price {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		justify-content: space-between;

		.js-calc-price {
			color: var(--laying-type-color);
		}

		&__itm {
			&__desc {
				font-size: $fs_smallest;
				margin-bottom: 0.44rem;
			}

			&__val {

			}
		}
	}

	&__footer {
		border-top: 1px solid var(--gray-mid);
		padding-top: 1rem;
		margin-top: .5rem;

		.h4 {
			margin-bottom: .5rem;
		}

		.btn-main, .btn-line {
			width: 100%;
			margin-top: .5rem;
		}

	}
}

@include tablet {
	.header {
		&-gamburger {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			background: var(--gray-light);
			border-radius: 8px;
			margin-right: 8px;
			transition: all .3s ease-in-out;
			flex-shrink: 0;

			&.menu-open {
				background: var(--accent-color);

				.gamburger {
					&:before, &:after {
						background: #fff;
					}
				}
			}
		}

		&__logo {
			flex: 1;

			&__fineapp {
				margin-right: 1rem;
			}
		}

		&__container {
			padding-left: 24px;
			padding-right: 24px;
		}

		&-phone {
			display: none;
		}
	}

	.top-search__wrapper {
		position: inherit;
	}
	.top-search__container--expanded {
		width: 48px;

		.top-search__form {
			opacity: 1;
			pointer-events: auto;
		}
	}

	.top-search__form {
		position: absolute;
		top: 100%;
		left: 1.5rem;
		right: 1.5rem;
		margin-top: 1rem;
		background: #fff;
		padding: 1rem !important;
		width: auto !important;
		border: 1px solid var(--gray-light);
		border-radius: .5rem;
		box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.15);
		opacity: 0;
		pointer-events: none;
	}

	.top-search__wrapper--wider {
		width: auto;
	}
}

@include main-tablet {
	.cart-info {
		&__descr {
			display: none;
		}

		.menu__item__close {
			@include pos-center();
			display: block;
			opacity: 0;
			transition: all .3s ease-in-out;

			.cross {
				&:before, &:after {
					background: var(--accent-color);
				}
			}
		}

		&.active {
			.menu__item__ico {
				opacity: 0;
			}

			.menu__item__close {
				opacity: 1;
			}
		}

	}

	.cart-popup {
		position: fixed;
		top: 82px;
		right: 0;
		margin-top: 0;
		transform: translateX(100%);

		&.active {
			transform: translateX(0);
			pointer-events: auto;
			opacity: 1;

		}
	}
}


@include big-mobile {
	.menu__item {
		&:not(:nth-last-child(-n+2)) {
			margin-right: 4px;
		}

		&:nth-last-child(1) {
			margin-left: 8px;
		}
	}

	.header__logo {
		min-width: 48px;
		max-width: 48px;
		height: 48px;
		flex-shrink: 0;
		background: var(--gray-light);
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;

		&__small {
			display: block;
			width: 26px;
		}

		&__full {
			display: none;
		}
	}

	.menu__item--switch {
		display: none;
	}


	.cart-popup {
		position: fixed;
		top: 82px;
		width: 100%;
		height: calc(100% - 82px);
		max-height: calc(100% - 82px);
		margin-top: 0;
		transform: translateX(100%);
		opacity: 1;

		&__close {
			position: absolute;
			right: 0;
			top: 0;
			display: block;
			padding: 1rem;

			.cross {
				&:before, &:after {
					background: var(--v-2-header-color);
				}
			}
		}

		&.active {
			transform: translateX(0);
			pointer-events: auto;
		}
	}

}

@include min-mobile {
	.header__logo {
		display: none;
	}


}
