.grid-4 {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}
.grid-3 {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}
.grid-2 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.g1 {
	gap: 1rem;
}
.g1_5 {
	gap: 1.5rem;
}

.grid-cs-2{
	grid-column-start: 2;
}
.grid-ce-3{
	grid-column-end: 4;
}

@include tablet {
	.grid-t-2 {
		grid-template-columns: repeat(2, 1fr);
	}
	.grid-t-1 {
		grid-template-columns: 1fr;
	}
}

@include mobile {
	.grid-p-2 {
		grid-template-columns: repeat(2, 1fr);
	}
	.grid-p-1 {
		grid-template-columns: 1fr;
	}
	
	.g-p-05{
		gap: .5rem;
	}
}