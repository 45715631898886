.brand-statistics {
  padding: 16px 0 16px 0;
  margin-bottom: 24px;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 0 24px;
    flex-wrap: wrap;
    gap: 15px 0;
  }

  &__left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__num {
    display: block;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: var(--accent-color);
  }

  &__text {
    display: block;
    margin-left: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark-color);
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__image {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

@include extra-tablet{

}
