.template--fineapp {
	.menu__list {
		justify-content: flex-end;
		margin-left: 2rem;
	}

	.menu__item--active {
		width: auto;
	}

	.header-main {
		flex: inherit;
	}

	.header__menu {
		flex: 1;
	}

	.top-search__wrapper--wider {
		width: auto;
		flex: 1;
	}
}

.widget-slider {
	position: relative;
	display: flex;
	overflow: hidden;

	&-itm {
		min-width: 100%;
		background: #fff;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		border-radius: 0.5rem;
		box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);

		&-head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 1rem;
		}

		&__image {
			position: relative;
			overflow: hidden;
			border-radius: 0 0.5rem 0.5rem 0;

		}

		&__content {
			padding: 1.5rem 1.5rem 5rem;

			.h2 {
				margin-bottom: 1.5rem;
			}

			&__text {
				margin-bottom: 1.5rem;
			}
		}
	}

	.owl-nav {
		position: absolute;
		right: 1.5rem;
		bottom: 1.5rem;
	}

	.owl-dots {
		position: absolute;
		display: flex;
		align-items: center;
		left: 1.5rem;
		bottom: 1.5rem;
		justify-content: center;
		gap: .5rem;
	}

	.owl-dot {
		position: relative;
		display: inline-block;
		width: 0.375rem;
		height: 0.375rem;
		border-radius: 50%;
		background: var(--accent-color);
		transform: rotate(90deg);
		cursor: pointer;

		&.active {
			path {
				animation: dash 10s linear infinite;
			}
		}

		svg {
			width: 1rem;
			height: 1rem;
			@include pos-center();
			opacity: .8;

			path {
				stroke-dasharray: 240px;
				stroke-dashoffset: 240px;
			}
		}

		@include hover {
			background: var(--accent-hover-color);
		}
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: 0px;
		opacity: 0;

	}
	5% {
		stroke-dashoffset: 0px;
		opacity: 1;

	}
	100% {
		stroke-dashoffset: 240px;

	}
}

.owl-nav {
	button {
		position: relative;
		font-size: 0;
		width: 2rem;
		height: 2rem;
		background: var(--accent-color);
		transition: .2s ease-in-out;
		border: none;
		border-radius: .25rem;
		cursor: pointer;

		&:before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -.25rem;
			margin-top: -.25rem;
			width: .5rem;
			height: .5rem;
			background: url("../img/svg/arrow-small.svg") no-repeat center;
		}

		&.owl-next {
			&:before {
				transform: scaleX(-1);
			}
		}

		&:not(:last-child) {
			margin-right: .5rem;
		}

		@include hover {
			background: var(--second-color);
		}

		&.disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}
}

.widget-margin {
	margin-bottom: 2.5rem;
}

.widget-tile-list {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 1rem;
}

.widget-tile-itm {
	position: relative;
	display: block;
	aspect-ratio: 10/15;
	box-shadow: 0px 2px 4px 0px rgba(29, 31, 33, 0.20);
	border-radius: 0.5rem;


	&:before {
		content: "";
		@include fill(-2px);
		border-radius: 0.5rem;
		opacity: 0;
		border: 2px solid var(--accent-color);
		transition: opacity .2s;
	}

	.fill-img {
		transition: all .4s;
		will-change: transform;
		border-radius: 0.5rem;
		overflow: hidden;
	}

	@include hover {
		&:before {
			opacity: 1;
		}
	}

	&--block {
		.fill-img {
			filter: grayscale(1);
		}

		pointer-events: none;
	}

	&__block {
		@include pos-center();
		background: #F5333F;
		display: flex;
		align-items: center;
		color: #fff;
		font-size: 0.75rem;
		font-weight: 500;
		padding: .25rem;
		border-radius: 0.25rem;
		white-space: nowrap;

		img {
			margin-right: .15rem;
		}

		span {
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
		}
	}
}

.widget-price {
	h2 {
		color: var(--accent-color);
	}

	&--green {
		h2 {
			color: var(--second-color);
		}

		.widget-tile-itm:before {
			border-color: var(--second-color);
		}
	}
}

.widget-contact {
	color: var(--extra-dark-color);

	b {
		font-weight: 500;
	}

	h2 {
		margin-top: 0;
		margin-bottom: 2.5rem;
		font-weight: 500;
		color: var(--accent-color);
	}

	&__link {
		color: var(--accent-color);
		font-weight: 500;

		@include hover {
			color: var(--accent-hover-color);
		}
	}

	&__mail {
		color: inherit;
		@include hover {
			color: var(--accent-hover-color);
		}
	}

	&__map {
		display: flex;
		align-items: center;

		&__img {
			width: 7.5rem;
			height: 5rem;
			border-radius: 0.5rem;
			margin-right: 1.5rem;
			flex-shrink: 0;
		}
	}
}

.user-block {
	display: flex;
	align-items: center;

	&__ava {
		position: relative;
		width: 5rem;
		height: 5rem;
		border-radius: 50%;
		flex-shrink: 0;
		overflow: hidden;
		margin-right: 1rem;
	}

	&__name {
		font-weight: 500;
	}
}

.widget-contact-table {

	tr:last-child td {
		padding-bottom: 0;
	}

	td {
		padding-bottom: 2.5rem;

		&:first-child {
			max-width: 33%;
		}

		.text-light {
			font-weight: 400;
		}

		&:first-child {
			font-weight: 500;
		}

		&:not(:last-child) {
			padding-right: 2.5rem;
		}
	}
}

.promo-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	color: var(--dark-color);
	gap: 2.5rem;
	margin-bottom: 2.5rem;

	&-itm {
		background: #fff;
		border-radius: .5rem;
		padding: 1rem;
		margin-bottom: .1rem;

		&__head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 1rem;

			&__logo {
				max-height: 2.5rem;
				max-width: 5.6rem;
			}
		}

		&__img {
			position: relative;
			display: block;
			aspect-ratio: 23.75 / 11.5;
			margin: 0 -1rem 1rem;

			@include hover {
				~ .promo-grid-itm__name {
					color: var(--accent-color);
				}
			}
		}

		&__name {
			color: inherit;
			display: block;
			margin-bottom: 1rem;
			transition: color .2s;

			@include hover {
				color: var(--accent-color);
			}
		}

		&__text {
			font-size: var(--small);

			ul {
				margin: 0;

				li {
					position: relative;

					&:before {
						content: '';
						position: absolute;
						left: -.5rem;
						top: .33rem;
						width: 3px;
						height: 3px;
						background: var(--dark-color);
						transform: rotate(45deg);
					}
				}
			}
		}
	}
}

.widget-detail-row {
	display: grid;
	grid-template-columns: 1fr 40%;
	gap: 2rem;

	&__img {
		position: relative;
		aspect-ratio: 35.4/26.2;
	}
}

table.style-table .decor-preview-td {
	padding: 0 !important;
}

.decor-preview-td {
	width: 4.5rem;
	max-width: 4.5rem !important;
	min-width: 4.5rem !important;

	img {
		width: 2rem;
		height: 2rem;
	}
}

.promo-product-table {
	td, th {
		&:last-child {
			display: none;
		}
	}

	.t-decor-menu {
		width: 90px;
	}
}

.widget-faq-head {
	display: grid;
	grid-template-columns: 1fr 40%;

	h1 {
		margin: 0;
		align-self: center;
	}
}

.faq-search {
	position: relative;
	display: block;
	align-self: center;

	svg {
		width: 1.25rem;
		height: 1.25rem;
		position: absolute;
		left: 1rem;
		top: 50%;
		transform: translateY(-50%);
		fill: #B7BABE;
	}

	input {
		display: block;
		height: 3rem;
		padding: 1rem 1rem 1rem 3rem;
		box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
		border-radius: 0.5rem;
		background: var(--v-2-background, #F7F8FA);
		border: none;
		outline: none;
		width: 100%;
	}
}

.accordion {

	&-head {
		position: relative;
	}

	&.hide {
		display: none;
	}

	.hide {
		display: none;
	}

	&-arrow {
		position: absolute;
		right: 1rem;
		top: 50%;
		width: 1.75rem;
		height: 1.75rem;
		margin-top: -.875em;
		transition: transform .3s;
		transform-origin: center;

		&:before {
			content: "";
			@include pos-center();
			background: var(--accent-color);
			width: 100%;
			height: 2px;
		}

		&:after {
			content: "";
			@include pos-center();
			height: 100%;
			width: 2px;
			background: var(--accent-color);
		}
	}

	&--faq {
		border-radius: 0.5rem;

		.accordion {
			&-head {
				transition: color .2s ease;
				padding-right: 3rem;

				&:after {
					display: none;
				}

				@include hover {
					color: var(--accent-color);
				}

				&.active {
					.accordion-arrow {
						transform: rotate(45deg);
					}
				}
			}

			&-content {
				padding: 1rem;
				display: none;
				max-width: 43rem;

				p {
					margin-top: 0;
				}
			}
		}

		&.active {
			background: var(--v-2-background, #F7F8FA);
		}
	}

	&--brand {
		> .accordion {
			&-head {
				border-radius: 0.5rem 0.5rem 0rem 0rem;
				background: var(--ffsl-grey, #F7F8FA);
				border-bottom: 2px solid var(--ffl-grey, #CACDD2);
				transition: all .2s ease;

				&:after {
					display: none;
				}

				&.active {
					border-color: var(--accent-color);
				}

				@include hover {
					color: var(--accent-color);
				}

				.accordion-arrow {
					width: 1.2rem;
					height: 1.2rem;
					margin-top: -.6rem;
					will-change: transform;
				}

				&.active {
					.accordion-arrow {
						transform: rotate(45deg);
					}
				}
			}

			&-content {
				display: none;
			}


		}
	}

	&--decor {
		border-radius: 0;
		border-bottom: 1px solid #CACDD2;

		&.active {
			border-color: var(--accent-color);
		}

		.accordion {
			&-head {
				transition: all .2s ease;

				&:after {
					display: none;
				}

				@include hover {
					color: var(--accent-color);
				}

				.accordion-arrow-img {
					position: absolute;
					right: .75rem;
					top: 50%;
					transition: all .2s;
					margin-top: -.75rem;

					svg {
						width: 1.5rem;
						height: 1.5rem;
						fill: var(--gray-mid);
						transition: all .2s;
					}
				}

				&.active {
					.accordion-arrow-img {

						svg {
							fill: var(--accent-color);
							transform: rotate(180deg);
						}
					}
				}
			}

			&-content {
				display: none;
				padding-bottom: 1.5rem;
				padding-top: 1rem;
			}
		}
	}


}

.faq-block {
	.accordion {
		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}
}

.brand-grid {
	display: grid;
	grid-template-columns: 20rem  1.75rem;
	gap: 1rem;
	align-items: center;

	img {
		display: block;
	}
}

.brand-name {
	display: flex;
	align-items: center;
	gap: 1rem;
	font-weight: 500;
	line-height: 1;

	&__img {
		position: relative;
		width: 2.9375rem;
		height: 1.75rem;
	}
}

.decor-btns {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}

.accordion-flag-block {
	.texinfo-btns {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;

		a{
			@include hover{
				color: #fff;
			}
		}
	}

	.btn-line {
		padding: .5rem 1rem;
	}

	.accordion {
		margin-bottom: 1.5rem;
		border-radius: 0;

		&-head {
			position: relative;
			background: #F7F8FA;
			padding: 16px 65px 16px 24px;
			border-bottom: 2px solid var(--gray-mid);
			cursor: pointer;
			border-radius: 0.5rem 0.5rem 0rem 0rem;

			&:after {
				display: none;
			}

			&.active {
				border-color: var(--accent-color);

				.accordion-head__arrow {
					transform: translateY(-50%) rotate(0deg);
				}
			}

			&-name {
				font-size: var(--h4);
				font-weight: 500;
				flex: 1;
			}

			&-desc {
				margin-top: 4px;
				font-size: var(--small);
				line-height: 1.2;
			}

			&__arrow {
				@extend .cross;

				position: absolute;
				right: 36px;
				width: 16px;
				height: 16px;
				top: 50%;
				transform: translateY(-50%) rotate(45deg);
				transition: all .3s ease;

				&:before, &:after {
					background: var(--accent-color);
				}
			}

			&__content {
				display: flex;
				align-items: center;
				gap: 1rem;
				width: 100%;
				max-width: 300px;

				img {
					margin-bottom: 0;
				}

				&.flex {
					display: flex;
					align-items: center;
					gap: 18px;
				}
			}
		}

		&-body {
			display: none;
			font-size: 1rem;
			overflow: hidden;

			a {
				color: var(--accent-color);
			}
		}

		&--inner {
			border-bottom: 1px solid var(--gray-mid);

			&.active {
				 border-color: var(--accent-color);
			}

			&__collection {
				width: 47px;
			}

			.accordion {
				&-head {
					background: none;
					border: none;

					&-name {
						font-size: var(--small);
					}

					&__arrow {
						transform: translateY(-50%) rotate(90deg);
						width: 16px;
						height: 19px;

						&:before, &:after {
							display: none;
						}

						path {
							fill: var(--gray-mid);
						}
					}

					&__content {
						display: flex;
						align-items: center;
						gap: 18px;
					}

					&.active {

						.accordion-head__arrow {
							transform: translateY(-50%) rotate(270deg);

							path {
								fill: var(--accent-color);
							}
						}
					}
				}
			}

			&.with-flag {
				.accordion-head__content__left {
					display: flex;
					align-items: center;
					gap: 16px;
					flex: 1;

					.accordion-head-name {
						width: auto !important;
					}
				}
			}
		}


	}
}

@include tablet {

	.widget-margin {
		margin-bottom: 1.5rem;
	}

	.promo-grid {
		grid-template-columns: repeat(2, 1fr);
	}

	.widget-detail-row {
		display: block;

		&__img {
			display: none;
		}
	}

}

@include mobile {
	.widget-slider-itm {
		display: block;

		&__image {
			display: none;
		}
	}

	.template--fineapp {
		.header__logo {
			max-width: 100%;
			background: none;

			img {
				margin-right: 0;
			}
		}
	}

	.header-phone {
		display: none;
	}

	.widget-tile-list {
		grid-template-columns: repeat(2, 1fr);
	}

	.widget-contact-table {
		display: block;

		td {
			display: block;

			&:first-child {
				max-width: 100%;
				padding-bottom: .5rem;
			}
		}
	}

	.promo-grid {
		grid-template-columns: repeat(1, 1fr);
	}

	.brand-grid {
		width: 100%;
		grid-template-columns: 1fr 1.75rem;
		padding-right: 2rem;
	}

	.widget-faq-head {
		display: block;
	}

	.faq-search {
		margin-top: 1rem;
	}

}

