.user-search-checkbox {
	width: 176px;
	margin: 1rem 0;
}

.user-table-ava {
	width: 2rem;
	min-width: 2rem;
	height: 2rem;
	object-fit: cover;
	object-position: center;
	background: url("../../img/user-avatar-empty.svg") no-repeat center;
	background-size: contain;
	border-radius: 8px;
}

.user-ava {
	display: flex;

	&.empty {
		.user-ava__img .svg {
			display: block;
		}

		.ava-btn--replace, .ava-btn--remove {
			display: none;
		}

		.ava-btn--load {
			display: flex;
		}
	}

	&__img {
		width: 120px;
		height: 120px;
		border-radius: 8px;
		border: 2px solid var(--v-2-grey-mid, #B7BABE);
		background-color: var(--v-2-white, #FFF);
		margin-right: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		background-position: center;

		.svg {
			width: 2.5rem;
			height: 2.5rem;
			display: none;
		}
	}

	input {
		display: none;
	}

	&__btns {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 0.5rem;
	}

	.ava-btn--replace, .ava-btn--remove {
		display: flex;
	}

	.ava-btn--load {
		display: none;
	}
}


.ava-btn {
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	background: var(--v-2-grey-light, #F0F1F2);
	font-size: 0.9375rem;
	letter-spacing: 0.01875rem;
	font-weight: 500;
	color: var(--v-2-grey-dark, #4E5359);
	padding: 0 1rem;
	white-space: nowrap;
	cursor: pointer;
	transition: background-color .2s ease-in-out;

	&--replace {
		display: none;
	}

	&--remove {
		display: none;
	}

	@include hover {
		background: var(--laying-type-hover);
	}
}

.user-detail-grid {
	input {
		margin-bottom: 0;
	}

	&__r2 {
		grid-column-start: 2;
		grid-column-end: 4;
	}

	.ava-col {
		grid-row-start: 1;
		grid-row-end: 4;
	}

	button {
		grid-column-end: 4;
	}
}

.user-pass-grid {
	align-items: flex-start;
}

.user-pass-btns {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

@include tablet {
	.user-detail-grid {
		&__r2 {
			grid-column-end: 3;
			grid-column-start: 1;
		}

		.ava-col {
			grid-row-end: 1;
			grid-column-start: 1;
			grid-column-end: 3;
		}

		button {
			grid-column-end: 3;
		}
	}

	.user-pass-btns {
		grid-column-end: 3;
	}
}

@include mobile {
	.user-detail-grid {
		&__r2 {
			grid-column-end: 2;
		}

		.ava-col {
			grid-column-end: 2;
		}

		button {
			grid-column-end: 2;
		}
	}

	.user-pass-btns{
		grid-column-end: 2;
	}
}