.collection-tooltip {
	display: block;
	position: relative;
	top: 0;
	left: 0;

	&__txt {
		position: absolute;
		left: 60px;
		bottom: 2px;
		border-radius: 4px;
		padding: 4px;
		width: max-content;
		max-width: 370px;
		font-weight: 400;
		font-size: 10px;
		line-height: 1.2;
		color: var(--light-color);
		background: var(--extra-bg);
		opacity: 0;
		visibility: hidden;
		transform: translateY(5px);
		transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out,
		visibility 0.2s ease-in-out;
		z-index: 1;

		svg {
			position: absolute;
			top: 30%;
			left: -7%;
			width: 12px;
			height: 8px;
			transform: rotate(90deg);
			fill: var(--extra-bg);
		}
	}

	.btn__collection-tooltip {
		&:hover {
			cursor: pointer;
		}

		&:hover + .collection-tooltip__txt {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}

		&:focus + .collection-tooltip__txt {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}
}
