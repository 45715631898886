/* stylelint-disable */

@mixin bg-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

$browser-context: 16; // Default

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

@mixin image-set($pathToImage) {
  background-image: url("#{$pathToImage}.jpg");
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  background-image: image-set(
    /* stylelint-disable-next-line function-comma-newline-after */
      "#{$pathToImage}.avif"type("image/avif"),
    "#{$pathToImage}.webp"type("image/webp"),
    "#{$pathToImage}.jpg"type("image/jpg")
  );
}

@mixin image-set-jpeg($pathToImage) {
  background-image: url("#{$pathToImage}.jpeg");
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  background-image: image-set(
    /* stylelint-disable-next-line function-comma-newline-after */
      "#{$pathToImage}.avif"type("image/avif"),
    "#{$pathToImage}.webp"type("image/webp"),
    "#{$pathToImage}.jpeg"type("image/jpeg")
  );
}

@mixin footerToBottom {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

@mixin mr($value) {
  &:not(:last-child) {
    margin-right: $value;
  }
}

@mixin ml($value) {
  &:not(:last-child) {
    margin-left: $value;
  }
}

@mixin mb($value) {
  &:not(:last-child) {
    margin-bottom: $value;
  }
}

@mixin mt($value) {
  &:not(:last-child) {
    margin-top: $value;
  }
}

@mixin pseudo() {
  content: "";
  display: block;
}
