@import "range-input";
.right-panel {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
}

.vertical-button {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    padding: 10px 5px;
    font-size: 14px;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: var(--accent-hover-color);
    }
}
.facet-panel {
    position: fixed;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Выравниваем кнопки по правому краю */
    gap: 10px;
    padding: 10px;
	z-index: 9999;
	&__button, &__tooltip {
		display: flex;
		align-items: center;
		background-color: var(--accent-color);
		color: white;
		border: none;
		overflow: hidden; /* Скрываем текст, который выходит за пределы кнопки */
		transition: width 0.3s ease, background-color 0.3s ease;
		width: 3rem;
		height: 3rem;
		padding: 0.75rem;
		cursor: pointer;
		border-radius: 0.5rem;

		&:hover {
			width: 200px; /* Ширина кнопки при наведении */
			background-color: var(--accent-hover-color);
		}

		svg{
			width: 1.5em;
			height: 100%;
			fill: var(--light-color);
		}

		i {
			margin-right: 10px; /* Отступ между иконкой и текстом */
		}

		.button-text {
			white-space: nowrap; /* Текст не переносится на новую строку */
			opacity: 0; /* Текст скрыт по умолчанию */
			transition: opacity 0.3s ease;
		}

		&:hover .button-text {
			opacity: 1; /* Текст появляется при наведении */
		}
	}
	&__tooltip {
		background-color: var(--accent-color);
	}
}
.facet-panel-mobile {
	display: none;
    position: fixed;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    flex-direction: column;
    align-items: flex-end; /* Выравниваем кнопки по правому краю */
    gap: 10px;
    padding: 10px;
	z-index: 9999;
	&__button, &__tooltip {
		display: flex;
		align-items: center;
		background-color: var(--accent-color);
		color: white;
		border: none;
		overflow: hidden; /* Скрываем текст, который выходит за пределы кнопки */
		width: 3rem;
		height: 3rem;
		padding: 0.75rem;
		cursor: pointer;
		border-radius: 0.5rem;
		svg{
			width: 1.5em;
			height: 100%;
			fill: var(--light-color);
		}
		i {
			margin-right: 10px; /* Отступ между иконкой и текстом */
		}
	}
	&__tooltip {
		background-color: var(--accent-color);
	}
}
.light-panel{
	position: fixed;
    right: -20rem;
	transition: right 0.3s ease-in-out;
	width: 23rem;
    bottom: 5%;
    transform: translateY(-50%);
    display: flex;
    align-items: flex-end; /* Выравниваем кнопки по правому краю */
    gap: 10px;
    padding: 10px 10px 10px 0px;
	z-index: 9999;
	border: 2px solid #edeff2;
	border-radius: 0.5rem;
    background-color: var(--light-color);
	&__button{
		background-color: transparent;
		color: var(--accent-color);
		border: none;
		border-right: 1px solid #edeff2;
		width: 3rem;
		height: 3rem;
		padding: 0.75rem;
		cursor: pointer;
		svg{
			width: 1.5em;
			height: 100%;
			fill: var(--accent-color);
		}
	}
}
.light-panel.open{
	right: 0;
}

// .panel {
//     position: fixed;
//     right: 0;
//     top: 50%;
//     transform: translateY(-50%);
//     display: flex;
//     flex-direction: column;
//     align-items: flex-end; /* Выравниваем кнопки по правому краю */
//     gap: 10px;
//     padding: 10px;
//     background-color: #f0f0f0;
//     border: 1px solid #ccc;
//     border-radius: 5px 0 0 5px;
// }

// .icon-button {
//     display: flex;
//     align-items: center;
//     background-color: #007bff;
//     color: white;
//     border: none;
//     border-radius: 5px;
//     padding: 10px;
//     cursor: pointer;
//     overflow: hidden; /* Скрываем текст, который выходит за пределы кнопки */
//     transition: width 0.3s ease, background-color 0.3s ease;
//     width: 40px; /* Ширина кнопки с иконкой */
//     position: relative; /* Для позиционирования подпанели */

//     &:hover {
//         width: 200px; /* Ширина кнопки при наведении */
//         background-color: #0056b3;
//     }

//     i {
//         font-size: 20px;
//         margin-right: 10px; /* Отступ между иконкой и текстом */
//     }

//     .button-text {
//         white-space: nowrap; /* Текст не переносится на новую строку */
//         opacity: 0; /* Текст скрыт по умолчанию */
//         transition: opacity 0.3s ease;
//     }

//     &:hover .button-text {
//         opacity: 1; /* Текст появляется при наведении */
//     }
// }

// /* Стили для кнопки "Свет" и подпанели */
// .light-button {
//     &:hover .sub-panel {
//         display: flex; /* Показываем подпанель при наведении */
//     }

//     .sub-panel {
//         display: none; /* Скрываем подпанель по умолчанию */
//         flex-direction: column;
//         gap: 5px;
//         position: absolute;
//         right: 100%; /* Размещаем подпанель слева от кнопки */
//         top: 0;
//         background-color: #f0f0f0;
//         border: 1px solid #ccc;
//         border-radius: 5px;
//         padding: 5px;
//         box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1); /* Тень для подпанели */
//     }

//     .sub-button {
//         display: flex;
//         align-items: center;
//         background-color: #007bff;
//         color: white;
//         border: none;
//         border-radius: 5px;
//         padding: 8px;
//         cursor: pointer;
//         overflow: hidden;
//         width: 40px; /* Ширина кнопки с иконкой */
//         transition: width 0.3s ease, background-color 0.3s ease;

//         &:hover {
//             width: 150px; /* Ширина кнопки при наведении */
//             background-color: #0056b3;
//         }

//         i {
//             font-size: 16px;
//             margin-right: 8px;
//         }

//         .button-text {
//             white-space: nowrap;
//             opacity: 0;
//             transition: opacity 0.3s ease;
//         }

//         &:hover .button-text {
//             opacity: 1;
//         }
//     }
// }

.facet {
	&-block {
		flex: 1;
		padding-top: 1.5rem;

		del{
			text-decoration-color: var(--sale-color);
		}

		.facet-filters {
			&:before {
				content: "";
				@include fill();
				right: -10px;
				pointer-events: none;
				opacity: 0;
				transition: all .2s ease;
			}
		}

		&.load {
			.product-itm-skeleton {
				display: none;
			}
		}

		&:not(.load) {
			[data-widget="hits"] {
				padding-top: 2rem;
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				gap: 1rem;
			}

			.facet-filters {
				overflow: hidden;
				aspect-ratio: 1/1.75;

				&:before {
					opacity: 1;
					z-index: 2;
					background: #ddd;
				}

				&:after {
					content: "";
					width: 20rem;
					height: 100%;
					position: absolute;
					left: 100%;
					top: 0;
					background: linear-gradient(90deg, #ddd, #eee, #ddd);
					z-index: 3;
					animation: skeleton 1s ease infinite;
					transform: skewX(-15deg);
				}
			}
		}

		&.view-table:not(.load) {
			[data-widget="hits"] {
				position: relative;
				display: block;
				overflow: hidden;
				margin-top: 1rem;
				padding-top: 0;


				&:after {
					content: "";
					width: 20rem;
					height: 100%;
					position: absolute;
					left: 100%;
					top: 0;
					background: linear-gradient(90deg, #ddd, #eee, #ddd);
					z-index: 3;
					animation: skeleton 1s ease infinite;
					transform: skewX(-15deg);
				}
			}

			.product-itm-skeleton {
				aspect-ratio: auto;
				height: 7rem;
				margin-bottom: 2px;

				&:after {
					display: none;
				}
			}
		}
	}

	&-grid {
		display: grid;
		grid-template-columns: 16.4rem minmax(0, 1fr);
		gap: 1.6rem;
	}

	&-aside {

	}
}

.product-itm-skeleton {
	position: relative;
	aspect-ratio: 1/1.5;
	overflow: hidden;

	&:before {
		content: "";
		@include fill();
		pointer-events: none;
		transition: all .2s ease;
		opacity: 1;
		z-index: 2;
		background: #ddd;
	}

	&:after {
		content: "";
		width: 20rem;
		height: 100%;
		position: absolute;
		left: 100%;
		top: 0;
		background: linear-gradient(90deg, #ddd, #eee, #ddd);
		z-index: 3;
		animation: skeleton 1s ease infinite;
		transform: skewX(-15deg);
	}
}

@keyframes skeleton {
	0% {
		left: 100%;
	}
	100% {
		left: -100%;
		opacity: 0;
	}
}

.facet-filters {
	position: sticky;
	top: 6.75rem;
	max-height: calc(100dvh - 7.5rem);
	overflow-y: auto;
	overflow-x: hidden;
	scrollbar-gutter: stable;
	@include slyled-scrollbar();
	overscroll-behavior: contain;


	&-btn-block {
		padding: 1.5rem 0;
		margin: 0 1rem;
		border-top: 1px solid var(--V2-Background);

		.btn {
			width: 100%;

			&:not(:last-child) {
				margin-bottom: 1rem;
			}
		}
	}

	> .facet-accordion-header {
		padding-right: .5rem;
	}

	> .facet-body {
		padding-right: 0;
		margin-right: -.5rem;
	}
}

.facet-header {
}

.facet-body {
	display: none;

	[data-widget] {
		&.hide {
			display: none;
		}

		&:not(:first-child) {
			.ais-Panel-header {
				border-top: 1px solid var(--V2-Background);
			}
		}
	}

	> .ais-Panel {
		.ais-Panel-header {
			border-top: 1px solid var(--V2-Background);
		}
	}
}

.facet-filters-footer {
}

.facet-filters-footer-button-wrapper {
}

.facet-results {
	background: none;
	box-shadow: none;
	padding-right: 2.5rem;
	.chech_hidden{
		display: none;
	}
}

.ais-CurrentRefinements-list {
	padding-left: 0;

	.ais-CurrentRefinements-item {
		display: inline;
	}
}

.ais-CurrentRefinements {
	&-label {
		display: none;
	}

	&-category {
		border-radius: 0.25rem;
		background: var(--V2-Red, #F5333F);
		color: #fff;
		padding: .25rem .5rem;
		font-size: 0.8125rem;
		font-weight: 500;
		letter-spacing: 0.02438rem;
		margin-right: .5rem;
		margin-bottom: .5rem;
		white-space: nowrap;
		border: none;
		display: inline-block;
	}

	&-delete {
		background: none;
		border: none;
		color: #fff;
		padding: 0;
		cursor: pointer;
		transition: all .3s ease;
		will-change: transform;
		margin-left: .25rem;
		position: relative;

		&:after {
			content: "";
			@include fill(-5px);
		}

		@include hover {
			transform: scale(1.2);
		}
	}
}

.ais-Panel {
	padding: 0 1rem;

	&-header {
		font-size: var(--h5);
		font-weight: 500;
		color: var(--v-2-header-color);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: .75rem 0;
		cursor: pointer;

		@include hover {
			color: var(--accent-color);
		}

		&.active {
			.ais-Panel-collapseButton:before {
				transform: scaleY(-1);
				border-top-color: var(--accent-color);
			}
		}
	}


	&-collapseButton {
		position: relative;
		background: none;
		border: none;
		width: 1.5rem;
		height: 1.5rem;
		padding: 0;

		&:before {
			content: "";
			position: absolute;
			left: 5px;
			top: 8px;
			transform-origin: 20% 15%;
			border: 8px solid transparent;
			border-top: 10px solid var(--v-2-header-color);
			transition: all .2s;
		}

		svg {
			display: none;
		}
	}

	&-body {
		padding: 0 0 1rem;
		display: none;
	}
}


.facet-options {
}

.facet-option {
}

.hits-empty-state {
}

.hits-empty-results {
}

.facet-footer {
	display: flex;
    justify-content: space-between;
    align-items: center;
	.ais-Panel-body {
		display: block;
	}
}


.ais-Pagination-list {
	display: flex;
	padding: 0;
	gap: .25rem .5rem
}

.ais-Pagination-item--selected {
	.ais-Pagination-link {
		border-color: var(--accent-color);
	}
}

.ais-Pagination-link {
	padding: .5rem .75rem;
	border-radius: 0.25rem;
	display: block;
	letter-spacing: 0.02438rem;
	font-weight: 500;
	color: var(--gray-dark);
	font-size: $fs_small;
	border: 1px solid transparent;

	@include hover {
		background: #EDEFF2;
	}
}

.ais-RefinementList-list {
	padding-left: 0;
	margin: 0;
}

.ais-RefinementList-item {
	&:not(:last-child) {
		margin-bottom: .5rem;
	}

	&.hide {
		display: none;
	}
}

.ais-RefinementList {
	&-label {
		position: relative;
		display: flex;
		width: 100%;
		font-size: 0.8125rem;
		font-weight: 500;
		letter-spacing: 0.02438rem;
		cursor: pointer;
		transition: all .2s;

		@include hover {
			color: var(--accent-color);
		}

		&Text {
			flex: 1;
			display: flex;
			align-items: center;
			gap: .25rem;

			img {
				display: block;
				width: .875rem;
				height: .875rem;
				transform: translateY(-1px);
			}
		}

		.ais-RefinementList-checkbox {
			position: absolute;
			left: 0;
			bottom: 0;
			opacity: 0;


			~ .ais-RefinementList-labelText {
				display: flex;
				align-items: flex-start;

				&:before {
					content: "";
					display: block;
					width: 1rem;
					height: 1rem;
					margin-top: -1px;
					background: var(--gray-light);
					border-radius: 3px;
					margin-right: .5rem;
					transition: background .3s;
					flex-shrink: 0;
				}

				&:after {
					content: "";
					position: absolute;
					width: 1rem;
					height: 1rem;
					left: 0;
					top: 0;
					background: url("../img/svg/check.svg") no-repeat center;
					background-size: 70%;
					opacity: 0;
					transition: opacity .3s;
				}
			}

			&:checked {
				~ .ais-RefinementList-labelText {
					&:before {
						background-color: var(--v2-red);
					}

					&:after {
						opacity: 1;
					}
				}
			}
		}


	}

	&-count {
		color: var(--gray-mid);
		font-size: 0.75rem;
		font-weight: 500;
	}

	&-showMore {
		@extend .btn;
		@extend .btn-main;
		@extend .btn--small;
		margin-top: 1rem;

		&--disabled {
			display: none;
		}
	}
}

.ais-ClearRefinements-button {
	background: none;
	padding: 0;
	border: none;
	-webkit-appearance: none;
	outline: none;
	width: 100%;
}

.ais-Hits {
	overflow: hidden;
}

.ais-Hits-list {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	padding: 0;
	list-style: none;
	gap: 1.5rem;
	margin-top: .5rem;
}

.facet-accordion-header {
	padding: .75rem 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: .5rem;
	cursor: pointer;

	@include hover {
		.facet-accordion-header__title {
			color: var(--accent-color);
		}
	}

	&__title {
		font-weight: 500;
		font-family: var(--h4);
		color: var(--v-2-header-color);
		line-height: 100%;
		margin: 0;
		flex: 1;
		transform: translateY(2px);
		transition: color .2s;
	}

	&__ico {
		width: 1.5rem;
		height: 1.5rem;
		fill: var(--accent-color);
		flex-shrink: 0;
	}

	&__arrow {
		width: 1.5rem;
		height: 1.5rem;
		fill: var(--v-2-header-color);
		flex-shrink: 0;
		transform: scaleY(-1);
		transition: all .3s;
	}

	&.active {
		.facet-accordion-header__arrow {
			fill: var(--accent-color);
			transform: scaleY(1);
		}
	}
}

.facet-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.facet-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 1rem 1rem 2.5rem;
	border-radius: 0.5rem;
	background: var(--V2-White, #FFF);
	border: none;
	box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
}

[data-widget].hide {
	pointer-events: none;
	opacity: .2;

	.ais-Panel-body {
		display: none !important;
	}
}

[data-widget="searchbox"] {
	position: relative;
	flex: 1;
	margin-right: 1rem;
}

.ais-SearchBox-input {
	width: 100%;
	height: 3rem;
	padding: 1rem 1rem 1rem 2.5rem;
	border-radius: 0.5rem;
	background: var(--V2-White, #FFF);
	border: none;
	box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
	-webkit-appearance: none;
	outline: none;

	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
}

.ais-SearchBox-reset {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	background: none;
	border: none;
	width: 2.5rem;
}

.ais-SearchBox-loadingIndicator {
	position: absolute;
	right: 2rem;
	top: 0;
	height: 100%;
	background: none;
	border: none;
	width: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;

	&[hidden] {
		display: none;
	}
}

.ais-SearchBox-submit {
	position: absolute;
	left: 0;
	top: 0;
	width: 2.5rem;
	height: 100%;
	border: none;
	outline: none;
	background: url("../img/svg/main-search.svg") no-repeat center;

	svg {
		display: none;
	}
}

.ais-SortBy-select {
	width: 100%;
	height: 3rem;
	padding: 1rem 1rem 1rem 2.5rem;
	border-radius: 0.5rem;
	background: var(--V2-White, #FFF);
	border: none;
	box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
	-webkit-appearance: none;
}

.facet-catalog {
	margin-bottom: 1rem;
}

.ais-RefinementList .ais-SearchBox-form {
	position: relative;
	margin-bottom: 1.5rem;

	.ais-SearchBox-input {
		border: 1px solid var(--gray-light);
		height: 2.5rem;
	}
}

[data-widget="sort-by"] {
	width: 200px;

	.custom-select__single {
		border: none;
		background: #fff;
	}

	.custom-select__dropdown {
		border: none;

		&-list {
			padding: .5rem;
		}

		&-item {
			border-radius: .5rem;
			padding: 1rem;

			&:not(:last-child) {
				margin-bottom: .5rem;
			}
		}
	}
}

.hit-table-grid {
	background: #fff;
	box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
	border-radius: .5rem .5rem 0 0;
	font-size: .75rem;
	overflow: auto;
	--grid-template: minmax(2rem, 2rem) minmax(10rem, 1fr) repeat(2, minmax(5rem, 8rem)) minmax(6.5rem, 6.5rem) minmax(6.5rem, 6.5rem) repeat(2, minmax(4.5rem, 4.5rem)) minmax(7rem, 7rem) ;

	~ .facet-footer {
		background: #fff;
		overflow: hidden;
		border-radius: 0 0 .5rem .5rem;
		border-top: 1px solid var(--V2-Grey-Mid, #B7BABE);

		.ais-Panel-body {
			padding-bottom: 0;
		}
	}

	ol {
		margin: 0;
	}

	&-head {
		display: grid;
		grid-template-columns: var(--grid-template);
	}

	&__th {
		padding: var(--smallest);
		border-bottom: 1px solid var(--V2-Grey-Mid, #B7BABE);
		color: var(--V2-Grey-Dark, #4E5359);
		font-weight: 500;
		.dealer_price{
			border-top: var(--accent-color) solid 1px;
			padding-top: 0;
			margin-top: 0;
		}
	}

	&__td {
		position: relative;
		padding: var(--smallest);
		border-bottom: 1px solid var(--V2-Grey-Light, #F0F1F2);
		word-break: break-word;

		.dealer_price{
			border-top: var(--accent-color) solid 1px;
			padding-top: 5px;
			margin-top: 5px;
		}

		a {
			color: inherit;
			transition: color .2s ease;

			@include hover {
				color: var(--accent-color);
			}
		}

		&--action {
			display: flex;
			padding-right: 0;
		}

		.popup-preview {
			top: auto;
			bottom: 100%;
			margin-bottom: -10px;
		}

		@include hover {
			.popup-preview {
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
				pointer-events: auto;
			}
		}

	}

	.ais-Hits {
		overflow: visible;
	}

	.ais-Hits-list {
		display: block;
	}

	.ais-Hits-item {
		display: grid;
		grid-template-columns: var(--grid-template);

		&:last-child {
			.hit-table-grid__td {
				border-bottom: none;
			}
		}

		&:first-child {
			.popup-preview {
				bottom: auto;
				top: 100%;
				margin-top: -10px;
			}
		}
	}
}

.facet-view-type {
	display: flex;
	gap: .5rem;
	margin-left: .5rem;

	&__btn {
		background: #fff;
		border: none;
		width: 3rem;
		height: 3rem;
		padding: .75rem;
		cursor: pointer;
		border-radius: 0.5rem;

		&.active {
			pointer-events: none;

			svg {
				fill: var(--accent-color);
			}
		}

		svg {
			width: 100%;
			height: 100%;
			fill: var(--gray-mid);
		}

		@include hover {
			background: var(--gray-light);
		}
	}
}

.modal-facet-grid {
	column-count: 4;

	.col {
		break-inside: avoid;
		break-before: column;
	}

	> div {
		break-inside: avoid;
	}
}

#modal-facet-filter {
	.modal-close {
		display: none;
	}

	.modal-content {
		width: 79rem;
		background: var(--V2-Background);
		display: flex;
		flex-direction: column;
	}
}

.popup-menu {
	top: -30px;
	min-width: 200px;
	right: 100px;

	&.show {
		opacity: 1;
		visibility: visible;
	}
}

.modal-facet-filter {
	background: #fff;
	border-radius: 1rem;
	padding: 1.5rem;
	overflow: auto;
	@include slyled-scrollbar();
	overscroll-behavior: contain;
	min-height: 20rem;

	&__head {
		display: flex;
		align-items: center;
		margin-bottom: 1.5rem;
		font-size: var(--h4);
		font-weight: 500;
		color: var(--v-2-header-color);

		svg {
			margin-right: .5rem;
		}
	}

	&-btns {
		position: sticky;
		bottom: 0;
		display: flex;
		align-items: center;
		gap: 1rem;
		margin-top: 1.5rem;
		z-index: 2;

		.btn-line {
			background: #fff;

			@include hover {
				background: var(--accent-color);
			}
		}

		.btn-main, .btn-line {
			width: 14.5rem;
		}
	}

	&-search {
		position: relative;
		display: block;

		&-ico {
			content: "";
			position: absolute;
			left: .75rem;
			top: .75rem;
			width: 1.5rem;
			height: 1.5rem;
			fill: #B7BABE;
		}

		input {
			height: 3rem;
			background: var(--V2-Background);
			border: none;
			border-radius: .5rem;
			padding: 1rem 1rem 1rem 2.88rem;
			font-size: 0.875rem;
			outline: none;

			@include placeholder {
				color: var(--gray-mid);
			}
		}
	}

}

[data-widget="results-number-modal"] {
	display: inline;

	.ais-Stats {
		display: inline;
	}
}

.ais-HierarchicalMenu {
	> .ais-HierarchicalMenu-list {
		margin-top: .5rem;
		margin-bottom: 0;
		padding-bottom: 1rem;
	}
}

.ais-HierarchicalMenu-list {
	padding-left: 0;
	letter-spacing: 0.02438rem;
	font-weight: 500;
	font-size: 0.8125rem;

	.ais-HierarchicalMenu-list {
		padding-left: 1rem;
	}

	a {
		color: var(--dark-color);
		transition: color .25s;

		@include hover {
			color: var(--accent-color);
		}
	}

}

.ais-HierarchicalMenu-item {
	&--selected {
		> div > .ais-HierarchicalMenu-link {
			color: var(--accent-color);

			&:after {
				transform: rotate(90deg) translateY(5px) translateX(1px);
				border-left-color: var(--accent-color)
			}

			&:before {
				background-color: var(--accent-color);
				background-image: url("/skins/img/check.svg");
				background-size: 65%;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}

	.ais-HierarchicalMenu-list {
		padding-right: 0;
	}
}

.ais-HierarchicalMenu-count {
	color: var(--gray-mid);
	font-size: 0.75rem;
	font-weight: 500;
	margin-left: .25rem;
}

.ais-HierarchicalMenu-link {
	position: relative;
	padding: .25rem 0 .25rem 0;

	/*	&:before {
			content: "";
			width: 1rem;
			height: 1rem;
			display: inline-block;
			background: var(--gray-light);
			border-radius: .25rem;
			margin-right: .25rem;
			flex-shrink: 0;
			transform: translateY(-2px);
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 4px;
			border: 5px solid transparent;
			border-left: 5px solid var(--gray-mid);
			transform-origin: center 75%;
		}*/

	.ais-HierarchicalMenu-label {
		display: flex;
		gap: .25rem;

		&:before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 1rem;
			height: 1rem;
			background: var(--gray-dark);
			mask: url("/skins/img/svg/folder-parent.svg") no-repeat center;
			mask-size: 90%;
			transform: translateY(-3px);
			flex-shrink: 0;
		}

		flex: 1;
		line-height: 110%;
	}

	display: flex;
	justify-content: space-between;
	flex-shrink: 0;
}

.ais-HierarchicalMenu-list--child {
	.ais-HierarchicalMenu-label {
		&:before {
			mask: url("/skins/img/svg/folder.svg") no-repeat center;
			mask-size: 90%;
		}
	}
}

.hits-empty-state {
	position: relative;
	padding-left: 5.5rem;
	overflow: hidden;

	&-image {
		position: absolute;
		left: 0;
		top: 50%;
		transform: scale(0.5) translateY(-50%);
		transform-origin: left top;

		circle, path:last-child {
			fill: var(--accent-color);
		}
	}
}

.filter-btn-tablet {
	display: none;
}


.modal-facet-filter-search {
	margin-bottom: 1rem;

	input {
		width: 50%;
	}
}


@include tablet {
	.facet-panel{
		display: none;
	}
	.facet-panel-mobile {
		display: flex;
	}
	.facet-block {
		padding: 1.5rem 0;
	}
	.ais-Hits-list {
		margin-top: 0;
	}
	.facet-grid {
		display: block;
	}
	.facet-aside {
		display: none;
	}

	.facet-results {
		padding-right: 0;
	}
	.filter-btn-tablet {
		display: flex;
		margin-left: 1rem;
	}

	[data-widget="pagination"] {
		.ais-Panel {
			padding: 0;
		}
	}
}

@include main-tablet {
	.facet-panel{
		display: none;
	}
	.facet-panel-mobile {
		display: flex;
	}
	.ais-Hits-list {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	.facet-block:not(.load) {
		[data-widget="hits"] {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
	}

	.facet-header {
		flex-wrap: wrap;

		[data-widget="searchbox"] {
			width: 100%;
			flex: auto;
			margin-right: 0;
			margin-bottom: 1rem;
		}
	}

	.modal-facet-grid {
		column-count: 1;

		.ais-Panel {
			padding: 0;
		}

		.col {
			break-before: auto;
		}
	}

	.modal-facet-filter-btns {
		margin-top: 2rem;

		&:before {
			content: "";
			position: absolute;
			left: -1.5rem;
			bottom: -1.5rem;
			right: -1.5rem;
			top: -1rem;
			background: #fff;
			box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
		}

		button {
			position: relative;
		}
	}

	.modal-facet-filter-search {
		margin-bottom: 1rem;

		input {
			width: 100%;
		}
	}
}

@include mobile {
	.facet-panel{
		display: none;
	}
	.facet-panel-mobile {
		display: flex;
	}

	.ais-Hits-list {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 1rem;

		.catalog-itm__name {
			word-break: break-word;
		}
	}


	.facet-block:not(.load) {
		[data-widget="hits"] {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}

	.filter-btn-tablet {
		margin-left: 0;
		margin-top: 1rem;
	}

	.modal-facet-filter-btns {
		display: block;
		padding: 0 1.5rem;

		.btn {
			padding: 9px 15px;
			width: 100%;

			&:not(:first-child) {
				margin-top: .5rem;
			}
		}
	}
}