.collection-styling {
  margin-bottom: 24px;
  padding: 24px 0 24px 0;

  &__container {
    padding: 0 24px 0 16px;
  }

  &__items {
    display: grid;
    // grid-template-columns: repeat(9, 1fr);
    grid-template-columns: repeat(auto-fill, 100px);
    grid-template-rows: 100px 100px;
    row-gap: 32px;
    column-gap: 40px;
  }

  &__item {
    border: 2px solid var(--extra-svg-color);
    transition: all 0.3s ease-in-out;

    &:hover {
      border: 2px solid var(--accent-color);
    }
  }
}


@include tablet {
  .collection-styling {
    &__items {
      grid-template-rows: auto;
    }
  }
}


@include big-mobile {
  .collection-styling {

    &__container {
      padding: 0 16px;
    }

    &__items {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      width: 100%;
    }

    &__item {

    }
  }
}
