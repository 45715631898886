.brand-table {
  margin-bottom: 24px;
  padding-top: 24px;
  padding-bottom: 0;

  &__container {
    padding: 0;
  }
}

.t-brand {
  padding-top: 16px;
  padding-bottom: 16px;
  // max-width: 1014px;
  // margin-left: auto;
  // overflow-x: scroll; // скролл внешней оболчки для адаптации под мобильные устройства

  &.collapse {
    width: 1108px;
  }

  &__outer {
    width: 100%;
    // height: calc(100vh - 180px);
    overflow: scroll;
    max-height: 528px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;

    th {
      position: sticky;
      top: 0;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      text-align: left;
      padding: 17.5px 16px;
      color: #334466;
      background-color: var(--light-color);
      border-bottom: 1.2px solid #c3cad9;
    }

    td {
      padding: 13.5px 16px;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #202b40;
      border-bottom: 1.2px solid #edeff2;

      &:nth-child(4) {
        position: relative;

        &:hover .popup-preview {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
          pointer-events: auto;
        }
      }
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 72px;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 135px;
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 138px;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 354px;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 168px;
    }

    th:nth-child(6),
    td:nth-child(6) {
      width: 168px;
    }

    th:nth-child(7),
    td:nth-child(7) {
      width: 150px;
    }

    td:nth-last-child(1),
    th:nth-last-child(1) {
      width: 130px;
    }

    td:nth-last-child(1) {
      padding: 10px;
    }

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c3cad9;
      border-radius: 8px;
    }
  }

  // сама таблица
  &__inner {
    width: 100%;
    border-collapse: collapse;
  }

  &__row:nth-child(-n + 4) {
    .t-brand__cell:nth-child(4).t-brand__cell:hover .popup-preview {
      top: 0;

      @include mobile {
        top: 0px;
        left: -100px;
      }
    }
  }

  // первые 3 popup
  &__row:nth-child(-n + 4) .popup-preview {
    top: 0px;
  }

  // выбрать все строки начиная с 4-ой > 4 столбец наименование > всплывающее окно
  &__row:nth-child(n + 5) {
    .t-brand__cell:nth-child(4).t-brand__cell:hover .popup-preview {
      top: -156px;
      left: 10px;

      @include mobile {
        top: 0;
        left: -100px;
      }
    }
  }

  &__row {
    background-color: var(--light-color);

    &:hover {
      background-color: var(--t-select-row-color);

      // .t-brand__cell:first-child,
      // .t-brand__cell:nth-last-child(2) {
      //   background-color: var(--t-select-row-color);
      // }
    }
  }

  &-menu {
    display: flex;

    &__item {
      display: block;
      width: 24px;
      height: 26px;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  &__header {
    &--name {
      z-index: 20;
    }
  }

  &-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
