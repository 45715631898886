
.scroll-nav{
  &__list {
    display: flex;
    flex-direction: row;
    gap: 20px 0;
    white-space: nowrap;
    flex-wrap: nowrap;
    padding-inline-start: 16px;
  }

  &__item {
    display: inline-block;

    &::after {
      width: 100%;
      display: block;
      content: "";
      margin-top: 10px;
      height: 2px;
      color: var(--more-dark-color);
      background-color: transparent;
    }

    &--active {
      &::after {
        background-color: var(--accent-color);
      }
    }
  }
  &__link {
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--more-dark-color);
  }
}

.decor-nav {
  &-wrap {
    position: sticky;
    top: 90px;
    display: grid;
    align-items: center;
    margin-bottom: 24px;
    z-index: 100;
    grid-template-columns: 1fr 325px;

    &__left {
      margin-right: 16px;
      flex-grow: 1;
      border-radius: 8px;
      background-color: var(--light-color);
      box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
      overflow: auto;
    }

    &__right {
      position: relative;
      max-width: 325px;
      height: 100%;
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding: 10px 16px 10px 20px;
      border-radius: 8px;
      background-color: var(--light-color);
      box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    }
  }

  &__list {
    display: flex;
    gap: 20px 0;
    white-space: nowrap;
  }

  &__item {
    display: inline-block;

    &::after {
      width: 100%;
      display: block;
      content: "";
      margin-top: 10px;
      height: 2px;
      color: var(--more-dark-color);
      background-color: transparent;
    }

    &--active {
      &::after {
        background-color: var(--accent-color);
      }
    }
  }

  &__link {
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--more-dark-color);
  }

  &__popup {
    display: flex;
    align-items: center;
  }

  &__popup-menu {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@include big-mobile {
  .decor-nav-wrap {
    display: block;
    top: 0;
    margin-bottom: 20px;

    &__left {
      position: sticky;
      top: 100px;
      margin-right: 0;
      z-index: 2;
    }

    &__right {
      margin-top: 20px;
      height: auto;
      max-width: 100%;
    }
  }
}
