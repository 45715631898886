.tree {
  &-wrapper {
    width: 265px;
    align-self: flex-start;
    background-color: var(--light-color);
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 8px;
    padding: 16px 4px 4px 16px;
    overflow: hidden;
  }

  &-btns {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &__left {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      cursor: pointer;

      &:hover .btn__tree {
        color: var(--accent-color);
      }

      &:hover svg {
        fill: var(--accent-color);
      }

      svg {
        width: 16px;
        height: 16px;
        fill: var(--search-svg-color);
        transition: all 0.3s ease-in-out;
      }
    }

    &__right {
      @include flex-all-center;

      margin-left: 64px;
    }
  }
}

@include extra-tablet{
  .tree-wrapper{
    width: 100%;
  }
}
