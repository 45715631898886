.showroom_plan {
  width: 500px;
  margin: 10px auto;
  background: rgba(black, 0.5);
}
.showroom_plan{
  path, polygon { stroke: #333333; }
  .red { fill: red; opacity: 0.5;}
  .green { fill: green; opacity: 0.5;}
  .blue { fill: blue; opacity: 0.5;}
  .cyan { fill: cyan; opacity: 0.5;}
  .magenta { fill: magenta; opacity: 0.5;}
  .yellow { fill: yellow; opacity: 0.5;}
}
@include mobile {
  .showroom_plan {
    width: 100%;
    margin: 0px auto;
  }
}