.brand-collections {
	padding: 16px 0 24px 0;
	margin-bottom: 24px;

	&__container {
		padding: 0 16px 0 16px;
	}

	&__content {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 24px;

		> a {
			color: #fff;
		}
	}

	&__top {
		display: flex;
		justify-content: space-between;
	}

	&__right {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	&__group-inf {
		display: flex;
	}

	&__countries {
		position: absolute;
		top: 64px;
	}

	&__inf {
		display: flex;
		flex-direction: column;

		&-num {
			font-weight: 600;
			font-size: 32px;
			line-height: 40px;
			color: var(--light-color);

			&--sku {
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
			}
		}

		&-item {
			font-weight: 600;
			font-size: 10px;
			line-height: 12px;
			color: var(--light-color);
		}
	}

	&__sku {
		margin-left: 8px;
		padding-top: 10px;

		&::after {
			display: block;
			content: "";
			width: 100%;
			height: 2px;
			background-color: var(--light-color);
		}
	}

	&__item {
		display: block;
		position: relative;
		max-width: 410px;
		height: 242px;
		overflow: hidden;

		&:hover {
			cursor: pointer;

			.brand-collections__image {
				transform: scale(1.2);
			}
		}

		&::after {
			position: absolute;
			display: block;
			content: "";
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: rgba(0, 0, 0, 0.2);
		}

	}

	&__image {
		transition: all 1.2s;
		height: 100%;
		width: 100%;
	}

	&__descr {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: absolute;
		width: 100%;
		height: 242px;
		padding: 16px;
		top: 0;
		left: 0;
		z-index: 100;

		svg {
			width: 114px;
			height: 40px;
			fill: var(--light-color);
		}
	}

	&__types {
		max-width: 156px;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: var(--light-color);
	}

	&__text {
		max-width: 91px;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: var(--light-color);
	}

	&__bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;

		svg {
			width: 44px;
			height: 44px;
		}
	}

	&__logo-text {
		height: 40px;
		display: flex;
		align-items: center;
		font-style: italic;
		font-weight: 800;
		font-size: var(--h4);
	}
}

@include extra-tablet {
	.brand-collections__content {
		grid-template-columns: repeat(2, 1fr);
	}
}

@include big-mobile {
	.brand-collections {
		&__content {
			display: block;
		}

		&__item {
			max-width: 100%;

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}
}
