.catalog-spetc-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
  margin-bottom: 2.5rem;
}
.catalog-spetc-tab {
	display: flex;
	gap: .5rem;

	&__btn {
		background: #fff;
		border: none;
		height: 3rem;
		padding: .75rem;
		cursor: pointer;
		border-radius: 0.5rem;
    display: flex;
		gap: 4px;
		align-items: center;
    color: inherit;

		&.active {
			pointer-events: none;
      background: var(--accent-color);
      color: #fff;

			svg {
				fill: var(--light-color);
			}
		}

		svg {

      width: 24px;
			fill: var(--gray-mid);
		}

		@include hover {
			background: var(--gray-light);
		}
	}
}