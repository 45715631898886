.stock {
  &__top {
    display: flex;
    align-items: baseline;
    margin-bottom: 4px;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--dark-color);
  }

  &__dots {
    display: flex;
    align-items: center;
    margin-left: 4px;
  }

  &__dot {
    width: 20px;
    height: 8px;
    background-color: var(--light-gray-color);

    &--fill {
      background-color: var(--accent-color);
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
