.search {
  &__container {
    margin-bottom: 16px;
  }

  &__form {
    position: relative;
    max-width: 265px;
    width: 100%;
  }

  &__input {
    width: 100%;
    height: 48px;
    padding: 0 5px 0 46px;
    border: 2px solid transparent;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    vertical-align: middle;
    color: var(--input-color);
    outline: none;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    transition: all 0.3s ease-in-out;

    &:focus {
      border: 2px solid var(--accent-color);
    }

    &::placeholder {
      color: var(--light-gray-color);
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 8px;
    right: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 6px;
    transform: translateY(-50%);

    svg {
      width: 18px;
      height: 18px;
      fill: var(--search-svg-color);
    }
  }

  &__filter-matches {
    display: flex;
    align-items: center;
    height: 30px;
  }
}

@include extra-tablet{
  .search__form{
    max-width: 100%;
  }
}
