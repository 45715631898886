.t-cat {
  width: 100% !important;
  background-color: var(--light-color);

  th {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: var(--dark-color);
  }

  thead th.t-cat__header{
    border-bottom: 1px solid var(--gray-mid);
  }

  td {
    padding: 13.5px 26px 13.5px 16px !important;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: var(--dark-color);
    border-bottom: 1px solid var(--t-select-row-color);

    &:nth-child(4) {
      position: relative;

      &:hover .popup-preview {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
        pointer-events: auto;
      }
    }
  }

  &__row {
    &:hover {
      background-color: var(--line-color) !important;

      & td:nth-child(1) {
        background-color: var(--line-color) !important;
      }

      & td:nth-last-child(2) {
        background-color: var(--line-color) !important;
      }
    }
  }

  &__row:nth-child(-n + 4) .popup-preview {
    top: 0px;
  }

  th:nth-child(1) {
    position: sticky;
    left: 0;
    width: 160px !important;
    background-color: var(--light-color);
    border-right: 1px solid var(--gray-mid);
    z-index: 200;
  }

  td:nth-child(1) {
    position: sticky;
    left: 0;
    width: 160px !important;
    background-color: var(--light-color);
    border-right: 1px solid var(--gray-mid);
  }

  th:nth-last-child(1) {
    width: 0 !important;
    padding: 0 !important;
  }

  td:nth-last-child(1) {
    width: 0 !important;
    padding: 0 !important;
  }

  th:nth-last-child(2) {
    position: sticky !important;
    right: 0;
    width: 112px !important;
    min-width: 112px;
    background-color: var(--light-color);
    box-sizing: border-box;
    border-left: 1px solid var(--gray-mid);
  }

  td:nth-last-child(2) {
    position: sticky;
    width: 112px !important;
    min-width: 112px;
    right: 0;
    background-color: var(--light-color);
    border-left: 1px solid var(--gray-mid);
    padding: 6.5px 0 6.5px 0 !important;
    box-sizing: border-box;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 160px !important;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 135px !important;
  }

  td:nth-child(4),
  th:nth-child(4) {
    width: 230px !important;
  }

  td:nth-child(5),
  th:nth-child(5) {
    width: 98px !important;
  }

  td:nth-child(6),
  th:nth-child(6) {
    width: 98px !important;
  }

  td:nth-child(7),
  th:nth-child(7) {
    width: 98px !important;
  }

  td:nth-child(8),
  th:nth-child(8) {
    width: 98px !important;
  }

  &-menu {
    max-width: 112px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    &__item {
      display: block;
      width: 24px;
      height: 26px;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  &__link {
    color: var(--dark-color);
    transition: all 0.3s ease-in-out;

    &:hover {
      color: var(--accent-color);
    }
  }

  &__cell:nth-child(4) {
    &:hover .t-cat__link {
      color: var(--accent-color);
    }
  }
}

@include mobile{
  .t-cat td:nth-child(1){
    position: relative;
    box-shadow: none;
  }
  table.dataTable thead > tr > th:nth-child(1){
    position: relative !important;
    box-shadow: none;
  }
}
