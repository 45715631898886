.tabs {
  &__nav {
    padding: 0;
    margin-top: 0;
    margin-bottom: 24px;

    &-item {
      &--active {
        & .tabs__nav-btn {
          color: var(--more-dark-color);
        }

        &::after {
          display: block;
          content: "";
          width: 100%;
          height: 2px;
          background-color: red;
        }
      }
    }

    &-btn {
      padding: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--extra-bg);

      &:hover {
        cursor: pointer;
      }

      &:focus {
        color: var(--more-dark-color);
        background-color: var(--light-color);
      }
    }
  }
}


@include big-mobile {
  .tabs {
    &__nav {
      white-space: nowrap;
      overflow: auto;
      padding-bottom: 15px;
    }
  }
}
