.page__body.expanded {
  .decor__flex {
    width: calc(100% - 246px);
  }
}

.decor {
  background-color: var(--main-bg);

  &__flex {
    width: calc(100% - 104px);
    display: flex;
    flex-direction: column;
    padding-left: 16px;
  }

  &__container {
    padding: 0 16px 0 0;
    display: flex;
  }

  &__title {
    margin: 0;
    padding-bottom: 24px;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: var(--extra-dark-color);

    &--small {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    &--mod {
      padding-bottom: 42px;
    }

    &--indent {
      margin-left: 16px;
    }
    &--hide {
      visibility: hidden;
      font-size: 1px;
      margin: 0px;
      padding: 0px;
      line-height: 1px;
    }
  }
}


@include tablet {
  .page__body {
    .decor__flex {
      width: 100%;
    }
  }
}


@include big-mobile {
  .decor {

    section {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}
