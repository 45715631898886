.facet-user-color{
    .red{
        background-color: red;
    }
    .green{
        background-color: green;
    }
    .blue{
        background-color: blue;
    }
    .cyan{
        background-color: cyan;
    }
    .cyan{
        background-color: cyan;
    }
    .magenta{
        background-color: magenta;
    }
    .yellow{
        background-color: yellow;
    }
}
.facet-state{
    // .blink{
    //     animation: blink 2s infinite; // Анимация длится 2 секунды и повторяется бесконечно
    // }
    // [class^="blink-"] {
    //     animation: blink 2s infinite; // Анимация длится 2 секунды и повторяется бесконечно
    // }
    .blink-red{
        background-color: rgba(255, 0, 0, 0.4);
        animation: blink-red  2s infinite;
    }
    @keyframes blink-red {
        0% {
            background-color: rgba(255, 0, 0, 0);
        }
        50% {
            background-color: rgba(255, 0, 0, 0.4);; // Цвет фона, заданный в классе
        }
        100% {
            background-color: rgba(255, 0, 0, 0);
        }
    }
    .blink-green{
        background-color: rgba(0, 128, 0, 0.4);
        animation: blink-green  2s infinite;
    }
    @keyframes blink-green {
        0% {
            background-color: rgba(0, 128, 0, 0);
        }
        50% {
            background-color: rgba(0, 128, 0, 0.4); // Цвет фона, заданный в классе
        }
        100% {
            background-color: rgba(0, 128, 0, 0);
        }
    }
    .blink-blue{
        background-color: rgba(0, 0, 125, 0.4);
        animation: blink-blue  2s infinite;
    }
    @keyframes blink-blue {
        0% {
            background-color: rgba(0, 0, 125, 0);
        }
        50% {
            background-color: rgba(0, 0, 125, 0.4); // Цвет фона, заданный в классе
        }
        100% {
            background-color: rgba(0, 0, 125, 0);
        }
    }
    .blink-cyan{
        background-color: rgba(0, 255, 255, 0.4);
        animation: blink-cyan  2s infinite;
    }
    @keyframes blink-cyan {
        0% {
            background-color: rgba(0, 255, 255, 0);
        }
        50% {
            background-color: rgba(0, 255, 255, 0.4); // Цвет фона, заданный в классе
        }
        100% {
            background-color: rgba(0, 255, 255, 0);
        }
    }
    .blink-magenta{
        background-color: rgba(255, 0, 255, 0.4);
        animation: blink-magenta  2s infinite;
    }
    @keyframes blink-magenta {
        0% {
            background-color: rgba(255, 0, 255, 0);
        }
        50% {
            background-color: rgba(255, 0, 255, 0.4); // Цвет фона, заданный в классе
        }
        100% {
            background-color: rgba(255, 0, 255, 0);
        }
    }
    .blink-yellow{
        background-color: rgba(255, 255, 0, 0.4);
        animation: blink-yellow  2s infinite;
    }
    @keyframes blink-yellow {
        0% {
            background-color: rgba(255, 255, 0, 0);
        }
        50% {
            background-color: rgba(255, 255, 0, 0.4); // Цвет фона, заданный в классе
        }
        100% {
            background-color: rgba(255, 255, 0, 0);
        }
    }
}
