.tooltip-menu {
  position: absolute;
  top: 50px;

  &__txt {
    position: absolute;
    right: 0;
    top: calc(100% + 8px);
    border-radius: 4px;
    padding: 4px;
    width: max-content;
    max-width: 370px;
    box-shadow: 4px 8px 20px rgba(0, 13, 84, 0.2);
    font-weight: 400;
    font-size: 10px;
    line-height: 1.2;
    color: var(--dark-color);
    background: var(--light-color);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(5px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
      visibility 0.3s ease-in-out;

    &::after {
      content: "";
      position: absolute;
      top: -4px;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 8px 6px;
      border-color: transparent transparent var(--light-color) transparent;
      transform: translateX(-50%);
    }

    &--search {
      left: -18px;
    }

    &--cart {
      right: -38px;
    }

    &--notify {
      right: -25px;
    }
  }
}

.menu__item:hover .tooltip-menu__txt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out 2s, transform 0.3s ease-in-out 2s,
  visibility 0.3s ease-in-out 2s;
}

.menu__item:focus .tooltip-menu__txt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
