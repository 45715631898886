.page__body {

	&:before {
		content: "";
		@include fill();
		position: fixed;
		background: #000;
		opacity: 0;
		z-index: 199;
		transition: opacity .3s ease-in-out;
		pointer-events: none;
	}

	&.menu-open {
		&:before {
			opacity: 0.6;
			pointer-events: auto;
		}

		.sidebar-nav__title {
			opacity: 1;
		}
	}
}

.sidebar {
	position: sticky;
	top: 81px;
	width: 104px;
	padding: 16px 20px 0 24px;
	transition: all 0.3s ease-in-out;
	height: calc(100vh - 82px);
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: var(--main-bg);

	&::-webkit-scrollbar {
		height: 4px;
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #c3cad9;
		border-radius: 8px;
	}

	&-wrapper {
		//background-color: var(--light-color);
	}

	&__bottom {
		position: sticky;
		bottom: 0;
		padding-bottom: 22px;
		left: 0;
		margin-left: 0;
		display: flex;
		align-items: flex-end;
		margin-top: 40px;
		width: 205px;
		transform: translateX(-15px);
	}

	&__descr {
		position: relative;
		margin: 0 0 0 16px;
		font-weight: 400;
		font-size: 10px;
		line-height: 12px;
		color: var(--copy-descr-color);
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease-in-out;
		min-width: 190px;

		&:before {
			content: "";
			position: absolute;
			left: -10px;
			top: -10px;
			right: -10px;
			bottom: -22px;
			background: var(--main-bg);
			z-index: -1;
		}
	}

	&__link-app {
		svg {
			width: 8px;
			height: 54px;
			color: var(--extra-dark-color);
		}
	}

	&--collapse {
		.sidebar {
			width: 104px;
		}

		.sidebar:hover {
			width: 104px;
		}

		.sidebar-nav {
			width: 52px;

			&__title {
				opacity: 1;
			}
		}
	}
}

.sidebar .t-catalog__outer {
	width: 1238px !important;
}

.sidebar-nav {
	overflow: hidden;
	transition: all 0.3s ease-in-out;
	flex-shrink: 0;

	&__item:not(:last-child) {
		margin-bottom: 4px;
	}

	&__item:nth-child(5n):after {
		display: block;
		content: "";
		height: 1px;
		width: 100%;
		border-radius: 8px;
		background-color: var(--divider-color);
		margin-top: 16px;
	}

	&__item:nth-child(5n) {
		margin-bottom: 16px;
	}

	&__item:nth-child(6),
	&__item:nth-child(11) {
		margin-top: 16px;
	}

	&__link {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 12px 16px;
		border-radius: 8px;
		color: var(--extra-dark-color);
		transition: background-color 0.1s !important;

		svg {
			width: 24px;
			height: 24px;
			flex-shrink: 0;
			fill: var(--main-svg-color);
		}

		.svg {
			path {
				fill: var(--main-svg-color);
			}
		}

		&:hover {
			color: var(--light-color);
			background-color: var(--main-bg);

			svg {
				fill: var(--accent-color);
			}

			.svg {
				path {
					fill: var(--accent-color);
				}
			}

			.sidebar-nav__title {
				color: var(--accent-color);
			}
		}

		&--active {
			color: var(--light-color);
			background-color: var(--accent-color);

			svg {
				fill: var(--light-color);
			}

			&:hover {
				background-color: var(--accent-color);

				svg {
					fill: var(--light-color);
				}

				.sidebar-nav__title {
					color: var(--light-color);
				}
			}
		}
	}

	&__title {
		margin-left: 16px;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: 0.02em;
		opacity: 0;
		transition: opacity .3s ease-in-out;
		white-space: nowrap;
	}
}

.sidebar-wrapper {
	width: 104px;
	position: relative;
	z-index: 201;
	transition: all 0.3s ease-in-out;

	&.hover .sidebar {
		width: 266px;
		transition: all 0.3s ease-in-out;

		.sidebar-nav__title {
			opacity: 1;
		}
	}

	&.hover .sidebar__descr {
		opacity: 1;
		visibility: visible;
		transition: all 1.2s ease-in-out;
	}
}

.sidebar-wrapper.expanded {
	width: 266px;

	& .sidebar__descr {
		opacity: 1;
		visibility: visible;
		transition: all 1.2s ease-in-out;
	}
}

.sidebar.expanded {
	width: 266px;

	.sidebar-nav__title {
		opacity: 1;
	}
}

.expand-svg {
	&.replaced-svg {
		display: none;
	}
}

.collapse-svg {
	display: none;

	&.replaced-svg {
		display: block;
	}
}

@include tablet {
	.sidebar {
		width: 266px;
		top: 0;

		&-wrapper {
			position: fixed;
			width: 266px;
			left: -266px;
		}
	}
	.sidebar-wrapper {
		overflow: auto;
		height: 100%;

		&.menu-open {
			transform: translateX(100%);
		}
	}

	.sidebar-nav__link--expand {
		display: none;
	}
}
