.nice-form {
	input {
		width: 100%;
		border: 1px solid #C1C7CF;
		border-radius: 8px;
		height: 48px;
		padding: 0 12px;
		margin-bottom: 24px;
	}

	textarea {
		width: 100%;
		border: 1px solid #C1C7CF;
		border-radius: 8px;
		padding: 18px 12px;

		~ .input-placeholder {
			top: 22px;
		}

		&:focus, &:not(:placeholder-shown), &:-webkit-autofill {
			-webkit-box-shadow: 0 0 0px 1000px white inset;

			~ .input-placeholder {
				top: 10px;
				font-size: $fs_smallest;
			}
		}
	}

	input, textarea {
		outline: none;

		&:focus {
			border-color: #000;
		}
	}

	.input-error {
		top: -20px;
	}

}

.input-error {
	position: relative;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	color: var(--accent-color);
}

.input-col-2 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0 16px;
}

.input {
	position: relative;
	display: block;
	margin-bottom: 24px;

	&.no-margin {
		margin-bottom: 0;

		input {
			margin: 0;
		}
	}

	&.padding-center {
		input {
			padding-top: 0;
		}
	}

	input {
		width: 100%;
		height: 48px;
		border: 1px solid var(--gray-mid);
		border-radius: 8px;
		padding: 14px 12px 0;
		font-size: 16px;
		line-height: 18px;
		outline: none;
		font-family: $F_Title;
		font-weight: 400;
		color: var(--v-2-black, #171717);

		&:focus, &:not(:placeholder-shown), &:-webkit-autofill {
			-webkit-box-shadow: 0 0 0px 1000px white inset;

			~ .input-placeholder {
				transform: translateY(-17px);
				font-size: $fs_smallest;
			}
		}
	}


	&-placeholder {
		position: absolute;
		left: 12px;
		top: 50%;
		transform: translateY(-50%);
		color: var(--gray-mid);
		font-family: $F_Title;
		font-weight: 400;
		font-size: 14px;
		transition: all .2s ease-in-out;
	}

	&.error {
		input {
			border-color: var(--laying-type-color);
		}
	}

}

.input-password-ico {
	position: absolute;
	top: 0;
	height: 100%;
	right: 0;
	width: 30px;
	text-align: center;
	cursor: pointer;

	&__hide {
		opacity: 0;
	}

	&.show {
		.input-password-ico {
			&__hide {
				opacity: 1;
			}

			&__show {
				opacity: 0;
			}
		}
	}

	svg {
		width: 16px;
		height: 100%;
		@include pos-center();
		transition: all .2s ease-in-out;
	}

	@include hover {
		svg {
			fill: var(--accent-color);
		}
	}
}

.auth-modal-input-desc {
	font-family: $F_Title;
	font-weight: 500;
	font-size: 10px;
	line-height: 13px;
	color: var(--gray-dark);
	margin: 8px 0;

	a {
		color: var(--accent-color);

		@include hover {
			color: var(--accent-hover-color);
		}
	}
}


.js-select-wrap {
	height: 48px;

	select {
		display: none;
	}
}

.js-license-select {
	+ .custom-select {
		min-width: 485px;
	}
}

.custom-select {
	position: relative;
	max-width: 100%;
	width: 100%;
	display: inline-block;

	&.is-open {
		.custom-select__dropdown {
			opacity: 1;
			transform: translateY(0px);
			pointer-events: auto;
		}

		.custom-select__single:after {
			transform: rotate(-135deg);
			margin-top: 0px;
		}
	}

	&__single {
		background-color: #fff;
		border: 1px solid var(--gray-mid);
		padding: 0 25px 0 12px;
		display: flex;
		align-items: center;
		font-size: 1rem;
		line-height: 12px;
		color: var(--v-2-black, #171717);
		cursor: pointer;
		position: relative;
		height: 48px;
		border-radius: 8px;

		&:after {
			content: '';
			position: absolute;
			right: 11px;
			top: 50%;
			width: 8px;
			height: 8px;
			margin-top: -6px;
			box-sizing: border-box;
			border-right: 1px solid var(--gray-dark);
			border-bottom: 1px solid var(--gray-dark);
			transform: rotate(45deg);
		}
	}

	&__dropdown {
		position: absolute;
		top: 100%;
		margin-top: 6px;
		z-index: 10;
		background: #FFFFFF;
		border: 1px solid var(--accent-color);
		box-sizing: border-box;
		width: 100%;
		opacity: 0;
		pointer-events: none;
		transform: translateY(-5px);
		transition: all, .3s;
		border-radius: 4px;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}
	}

	&__dropdown-item {
		padding: 12px 8px;
		cursor: pointer;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.2;
		color: var(--text);

		&:hover {
			background: var(--accent-color);
			color: #fff;
		}

		&.is-active {
			cursor: auto;
			background: var(--accent-color);
			color: #fff;
		}

		&.placeholder-itm {
			display: none;
		}
	}
}

.check_box {
	position: relative;
	display: flex;
	gap: 5px;
	cursor: pointer;

	&__text {
		font-size: 12px;
		line-height: 15px;
		color: #202B40;

		a {
			text-decoration: underline;
			color: var(--accent-color) !important;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&:hover {
		.check_box__item {
			background-color: var(--gray-mid);
		}

		.js-checkbox:checked ~ .check_box__item {
			background: var(--accent-hover-color);
		}
	}

	&.disabled {
		pointer-events: none;
	}

	&__text {
		a {
			color: inherit;
		}
	}
}

input.js-checkbox {
	width: 20px;
	height: 10px;
	opacity: 0;
	position: absolute;
	top: 7px;
	left: 0;

	&.round {
		+ .check_box__item {
			border-radius: 50%;
		}
	}

	&:checked {
		+ .check_box__item {
			background: var(--accent-color);

			&:before {
				opacity: 1;
			}
		}
	}

	&:active {
		+ .check_box__item {
			outline: 4px solid rgba(212, 227, 255, 0.5);
		}
	}

	&[disabled] {
		~ span {
			pointer-events: none;
		}

		~ .check_box__item {
			opacity: 0.65;
		}
	}

}

.check_box__item {
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;
	background: var(--gray-mid);
	border-radius: 4px;
	transition: all .3s ease-in-out;
	outline: 4px solid transparent;
	flex-shrink: 0;


	&:before {
		content: '';
		@include fill(-1px);
		background: url('../img/check.svg') no-repeat center;
		background-size: 50%;
		opacity: 0;
	}
}

.radio_box {
	display: inline-flex;
	position: relative;
	cursor: pointer;
	transition: color .2s ease-in-out;

	@include hover {
		color: var(--accent-color);
	}

	input {
		position: absolute;
		left: 0;
		bottom: 0;
		opacity: 0;

		&:checked {
			~ .radio_box__item {
				background: var(--accent-color);

				&:before {
					transform: scale(1);
				}
			}
		}
	}

	&__item {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 1rem;
		height: 1rem;
		background: var(--gray-mid);
		border-radius: 50%;
		transition: all .3s ease-in-out;
		margin-right: .5rem;
		flex-shrink: 0;

		&:before {
			content: "";
			@include fill(4px);
			background: #fff;
			border-radius: 50%;
			transform: scale(0);
			transition: all .3s ease-in-out;
		}
	}

	&__desc {
		color: var(--v-2-grey-dark, #4E5359);
		font-size: 0.625rem;
		margin-top: 0.5rem;
	}
}

.input-datepicker {
	position: relative;

	&__hint {
		padding-left: 16px;
		color: var(--gray-mid);
		font-size: 13px;
		font-weight: 600;
	}

	&__clear {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 40px;
		text-align: center;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		display: none;

		&:hover {
			path {
				fill: var(--accent-hover-color);
			}
		}
	}

	&__ico {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 40px;
		text-align: center;
		align-items: center;
		justify-content: center;
		display: flex;
	}

	input {
		&:not(:placeholder-shown) {
			~ .input-inner-placeholder {
				top: 0;
				font-size: $fs_small;
			}

			~ .input-datepicker__clear {
				display: flex;
			}

			~ .input-datepicker__ico {
				display: none;
			}
		}
	}
}

.input-inner-placeholder {
	position: absolute;
	left: 8px;
	top: 50%;
	transform: translateY(-50%);
	background: #fff;
	padding: 0 5px;
	transition: all .2s ease-in-out;
}

.ui-datepicker {
	box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.15);
	border-radius: 4px;
	background: #fff;
	padding: 20px;
	display: none;
	border: 1px solid var(--gray-mid);
	margin-top: 10px;

	&-calendar {
		text-align: center;
		font-size: toRem(12);

		th {
			font-weight: 400;
		}

		a {
			position: relative;
			text-decoration: none;
			color: inherit;
			display: block;
			height: 40px;
			width: 40px;
			line-height: 40px;

			&:before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				width: 40px;
				height: 40px;
				margin-left: -20px;
				margin-top: -20px;
				border-radius: 50%;
				background: var(--accent-color);
				z-index: -1;
				opacity: 0;
			}

			&:hover, &.ui-state-active {
				color: #fff;

				&:before {
					opacity: 1;
				}
			}
		}
	}

	&-header {
		position: relative;
		padding: 0 40px;
	}

	&-prev, &-next {
		position: absolute;
		left: 0;
		top: 50%;
		margin-top: -6px;
		font-size: 0;
		width: 12px;
		height: 12px;
		border-bottom: 3px solid var(--text);
		border-left: 3px solid var(--text);
		transform: rotate(45deg);
		cursor: pointer;
		transition: border-color .2s ease-in-out;

		&:hover {
			border-color: var(--accent-hover-color);
		}
	}

	&-next {
		position: absolute;
		right: 0;
		left: auto;
		transform: rotate(225deg);
	}

	&-title {
		margin-bottom: 25px;

		select {
			background: transparent;
			border: none;
			font-weight: 600;
			font-size: 15px;
			color: var(--text);
			margin-right: 25px;
		}
	}
}

.js-datepicker {
	input {
		padding-right: 35px;
	}

	svg {
		position: absolute;
		right: 8px;
		top: 0;
		width: 24px;
		height: 24px;
		fill: var(--accent-color);
	}
}

.auth-modal {
	.input input {
		margin-bottom: 0;
	}
}