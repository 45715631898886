.collection {
  background-color: var(--main-bg);

  &__flex {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__container {
    padding: 0;
    display: flex;
  }

  &__title {
    margin: 0;
    padding-bottom: 24px;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: var(--extra-dark-color);

    &--small {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    &--indent {
      margin-left: 16px;
    }
  }
}


@include big-mobile{
  .collection{
    &__flex{
      section{
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }
}
