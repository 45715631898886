#tree {
  max-width: 265px;
  overflow: auto;
}

ul.fancytree-container {
  max-height: 640px;
  border: none;
  overflow: scroll;
  outline: none;
  font-family: inherit;
  color: #334466;

  &::-webkit-scrollbar {
    height: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar:vertical {
    height: 4px;
    width: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c3cad9;
    border-radius: 8px;
  }
}

span.fancytree-title {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  background: none !important;
  border: none !important;
  color: #334466;
}

.fancytree-ext-filter span.fancytree-title mark {
  color: var(--accent-color);
  background-color: transparent;
}

span.fancytree-node {
  border-radius: 4px;
  min-height: 24px;
  display: flex;
  align-items: center;

  @include hover {
    background: #EDEFF2;
  }
}

span.fancytree-icon {
  flex-shrink: 0;
  background: url("../img/svg/file-ico.svg") no-repeat center;
}

.ui-fancytree ul ul {
  padding-left: 32px;
}

span.fancytree-node.fancytree-folder > span.fancytree-icon {
  background-position: 0 0;
  background-image: url("../skin-custom-tree/tree-subfolder.svg");
}

span.fancytree-node.fancytree-folder.fancytree-expanded {
  > span.fancytree-icon {
    background-position: 0 0;
    background-image: url("../skin-custom-tree/tree-subfolder1.svg");
  }

  .fancytree-title {
    color: var(--accent-color);
  }
}

.fancytree-has-children.fancytree-ico-cf span.fancytree-icon:hover {
  background-position: 0 0;
}

.fancytree-ext-filter-dimm
span.fancytree-node.fancytree-match
span.fancytree-title {
  color: var(--accent-color);
  font-weight: 500;
}

.fancytree-ext-filter-dimm
span.fancytree-node.fancytree-submatch
span.fancytree-title {
  color: var(--accent-color);
}

span.fancytree-selected .fancytree-title {
  font-style: normal;
}

span.fancytree-checkbox {
  position: relative;
  background: #E3E7EB;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background .2s ease-in-out;
  flex-shrink: 0;

  &:before {
    content: "";
    @include pos-center();
    width: 12px;
    height: 12px;
    background: url("../img/svg/check.svg") no-repeat center;
    background-size: contain;
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }

  @include hover {
    background: #C7CED5;
  }
}

.fancytree-selected span.fancytree-checkbox {
  background: var(--accent-color);

  &:before {
    opacity: 1;
  }

  @include hover {
    background: #B30000;
  }
}

@include extra-tablet{
  #tree{
    max-width: 100%;
  }
}
