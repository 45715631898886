.graph-modal__close {
	background-image: none;
}

.popup {
	width: 100%;
	max-width: 375px;
	padding: 24px;
	border-radius: 8px;
	box-shadow: 0px 4px 8px rgba(38, 51, 77, 0.2);

	&__title {
		margin: 0;
		padding-bottom: 8px;
		font-weight: 400;
		font-size: 10px;
		line-height: 12px;
		color: var(--extra-dark-color);
	}

	&__prices {
		display: flex;
		align-items: center;

		&--bottom {
			padding-bottom: 16px;
		}
	}

	&__price {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		color: var(--dark-color);
	}

	&__item {
		&:not(:last-child) {
			margin-bottom: 24px;
		}

		&:nth-child(3) {
			margin-bottom: 32px;
		}

		&:not(:last-child)::after {
			display: block;
			content: "";
			width: 100%;
			height: 1px;
			background-color: var(--light-gray-color);
		}
	}

	&__choose {
		display: flex;
		align-items: center;
		padding-bottom: 24px;
	}

	&__laying-type {
		flex-shrink: 0;
	}

	&__image {
		position: relative;
		display: block;

		&:hover {
			cursor: pointer;
		}
	}

	&__right {
		display: flex;
		align-items: center;
		margin-left: 30px;
	}

	&__input {
		width: 100%;
		height: 40px;
		padding: 11px 12px;
		border-radius: 5px;
		border: 1px solid var(--input-placeholder-color);
		font-weight: 400;
		font-size: 18px;
		line-height: 26px;
		color: var(--dark-color);
		outline: none;
		transition: all 0.3s ease-in-out;

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			-webkit-text-fill-color: var(--dark-color);
			box-shadow: 0 0 0 30px var(--light-color) inset !important;
		}

		&::placeholder {
			color: var(--input-placeholder-color);
		}

		&:focus {
			border: 1px solid var(--accent-color);
		}
	}

	&__bottom {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.price {
	&__item {
		flex-grow: 1;
		gap: 0 20px;
	}
}

.prod-type {
	display: flex;
	align-items: center;

	&__item {
		&:not(:last-child) {
			margin-right: 16px;
		}

		[type="radio"] {
			display: none;
		}

		& {
			svg {
				width: 64px;
				height: 40px;
				border-radius: 4px;
				fill: var(--laying-type-hover);
				transition: all 0.2s ease-in-out;

				&:hover {
					fill: var(--accent-color);
					cursor: pointer;
				}
			}
		}

		&--active {
			svg {
				fill: var(--accent-color);
			}
		}
	}
}

.modal--no-meter {
	.square_text {
		display: none;
	}

	.popup__right {
		margin-left: 0;
		width: 100%;

		.popup__area {
			width: 100%;
		}
	}
}