.brand-main {
  padding: 24px 0 16px 0;
  margin-bottom: 24px;

  &__container {
    padding: 0 16px 0 16px;
  }

  &__content {
    display: flex;
  }

  &__image {
    @include flex-all-center;

    width: 190px;
    height: 187px;
    background-color: var(--main-bg);
    flex-shrink: 0;

    img{
      display: block;
      margin: auto;
      width: 80%;
    }
  }

  &__descr {
    max-width: 1024px;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__text {
    margin: 0;
  }

  &__link {
    color: var(--accent-color);

    &:hover {
      text-decoration: underline;
    }
  }
}

@include min-desktop {
  .brand-main__image{
    width: 140px;
    height: 140px;
  }
}


@include big-mobile{
  .brand-main__image {
    width: 90px;
    height: 90px;
  }
}
