.order-block {
	display: flex;
	flex: 1;
	align-items: flex-start;
	font-family: $F_Title;

	&__filter {
		position: sticky;
		top: 90px;
		width: 265px;
		flex-shrink: 0;
		margin-right: 24px;
	}
	&__aside{
		position: sticky;
		top: 90px;
		width: 265px;
		flex-shrink: 0;
		ul {
			padding-left: 0;
		}
	}

	&__table {
		flex: 1;
		padding-bottom: 20px;
		overflow: hidden;

		.dataTables_wrapper {
			display: flex;
			flex-direction: column;
			margin: 0;
			overflow: auto;
		}

		.dataTable {
			flex: 1;
		}
	}

	&__content {
		background: #FFFFFF;
		border-radius: 8px;
		padding: 16px;
		flex: 1;
		margin-bottom: 16px;
		max-width: 824px;
		flex-shrink: 0;
    	margin-right: 24px;

		h1, h5 {
			margin: 0;
		}

		.margin-normal {
			margin-bottom: 16px;
		}
	}
}

.js-link-url {
	display: none;
}

.order-table {
	th, td {
		&:nth-last-child(2) {
			border-left: none;
		}
	}
}

.order-block__table {
	.dataTables_paginate {
		position: sticky;
		left: 0;
	}
}

a.status-label {
	color: #fff;

	@include hover {
		color: #fff;
	}

	&--second {
		@include hover {
			background: var(--hover-color);
		}
	}
}

.status-label {
	display: inline-flex;
	align-items: center;
	min-height: 21px;
	padding: 2px 8px;
	border-radius: 4px;
	color: #fff;
	font-weight: 500;
	background: var(--gray-mid);
	white-space: nowrap;
	font-size: 10px;
	line-height: 1;
	font-family: $F_Title;
	transition: background .2s ease-in-out;

	&--red {
		background: var(--laying-type-color);
	}

	&--orange {
		background: #FF6633;
	}

	&--yellow {
		background: #FFCB33;
	}

	&--green {
		background: #29CC39;
	}

	&--second {
		background: var(--second-color);
		--hover-color: var(--second-hover-color)
	}
}

.sort-date {
	display: none;
}

.js-tr-link-table {
	tr {
		cursor: pointer;

		td {
			transition: all .2s ease-in-out;
		}

		@include hover {
			td {
				background: var(--bg-btn-action) !important;
			}
		}
	}
}

.order-logo {
	width: 133px;
	height: auto;
	object-fit: contain;
}

.order-action {
	display: flex;

	&-itm {
		cursor: pointer;

		@include hover {
			svg {
				fill: var(--accent-color);
			}
		}

		svg {
			width: 24px;
			height: 24px;
			fill: var(--gray-mid);
		}

		&:not(:last-child) {
			margin-right: 24px;
		}
	}
}

.order-hr {
	display: block;
	border: none;
	outline: none;
	border-bottom: 1px solid #B7BABE;
	margin: 24px 0;
}

.order-attr {
	&-val {

	}

	&-desc {
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: var(--gray-mid);
	}

	&-right {
		text-align: right;
	}
}

p.order-attr-desc {
	margin: 0 0 8px 0;
}

p.order-attr-val {
	margin: 0 0 8px 0;
}

.order-detail-table {
	font-size: $fs_smallest;
	width: 100%;
	color: var(--black);

	th {
		padding: 8px;
		text-align: left;
		font-weight: 500;
	}

	td {
		padding: 8px;
		border-top: 1px solid var(--gray-light);
	}

	&__img {
		min-width: 32px;
		max-width: 32px;
		height: 32px;
		object-fit: cover;
		object-position: center;
		vertical-align: middle;
		margin-right: 8px;
	}

	&__link {
		color: inherit;
		display: flex;
		align-items: center;
		min-width: 150px;

		@include hover {
			color: var(--accent-color);
		}
	}
}

.order-footer {
	font-size: $fs_smallest;
	color: var(--gray-mid);
	gap: 0.5rem;

	p {
		margin: 0 0 8px 0;

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		color: inherit;

		@include hover {
			color: var(--accent-color);
		}
	}
}

@include extra-tablet {
	.order-block {
		display: block;

		&__filter {
			position: relative;
			top: 0;
			margin-bottom: 25px;
		}
	}
}

@include mobile {
	.order-block__filter {
		width: 100%;
	}

	.order-attr-right {
		margin-top: 24px;
	}

	.order-attr:not(:last-child) {
		margin-bottom: 24px;
	}

	.order-block {
		&__content {
			padding: 8px;
		}
	}

	.order-table {
		td, th {
			padding: 8px !important;
			min-width: 80px;
		}
	}
}