
.catalog-itm {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;


	&-wrap {
		width: 25%;
		padding: 12px;
		box-sizing: border-box;
		margin-bottom: 16px;
	}

	&-label {
		position: absolute;
		left: 8px;
		z-index: 1;
		background: rgba(255, 255, 255, 0.5);
		padding: 3px 8px;
		max-width: 100%;
		border-radius: 0px;
		font-size: 10px;

		&-right {
			position: absolute;
			right: 8px;
			top: 8px;
			max-width: 45px;
			height: auto;
			z-index: 1;
		}

		&.fill {
			color: #fff;
			background: var(--accent-color);
			font-weight: 600;
			font-size: 12px;
			line-height: 1;
			padding: 5px 8px;
			letter-spacing: -0.04em;
		}

		&__name {
			display: inline-block;

			&-ico {
				position: absolute;
				width: 13px;
				top: 50%;
				transform: translateY(-50%);

				img, .svg {
					// max-width: 100%; - старая версия
					height: 14px;
					width: auto;
				}

				+ .catalog-itm-label__name {
					margin-left: 14px;
				}
			}
		}
	}

	&__top {
		position: relative;
		padding-bottom: 100%;
		color: inherit;
		display: block;
		text-decoration: none;
		background-color: var(--light-gray-color);

		&__link {
			text-decoration: none;
			color: inherit;
		}

		&__3d {
			position: absolute;
			right: 8px;
			top: 8px;

			.svg {
				width: 31px;
				height: auto;
			}

			&__placeholder {
				position: absolute;
				left: 100%;
				top: -10px;
				margin-left: 3px;
				z-index: 3;
				padding: 4px 8px;
				background: #000;
				font-size: 10px;
				line-height: 13px;
				color: #fff;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
				border-radius: 0px;
				white-space: nowrap;
				pointer-events: none;
				opacity: 0;
				transform: translateX(-10px);
				transition: all .2s;
			}

			&:hover {
				.catalog-itm__top__3d__placeholder {
					opacity: 1;
					transform: translateX(0px);
				}
			}
		}

		&__actions {
			position: absolute;
			left: 8px;
			bottom: 8px;

			&__btn {
				position: relative;
				width: 26px;
				height: 26px;
				background: #FFFFFF;
				border-radius: 0px;
				cursor: pointer;

				&:not(:last-child) {
					margin-bottom: 8px;
				}

				.svg {
					@include pos-center();
					height: 14px;
					width: auto;
				}


				&.active, &:hover {
					background: var(--accent-color);
					opacity: 1;

					rect, path {
						fill: #fff;
					}

					&.js-add-fav {
						rect, path {
							fill: var(--accent-color);
							stroke: #fff;
						}
					}
				}


			}
		}
	}

	&-slider-wrap {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		overflow: hidden;
	}

	&-slider {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border-radius: 0;
		overflow: hidden;

		.owl-stage-outer {
			width: 100%;
			height: 100%;
		}

		.owl-stage {
			height: 100%;
		}

		.b_slider_wrap {
			width: 100%;
			height: 100%;
		}

		.slider_list {
			height: 100%;
			display: flex;
			transition: all ease .3s;
		}

		&__itm {
			width: 100%;
			@extend .fill-img;
			height: 100%;
			padding-bottom: 0;

			img {
				@extend .fill-img;
			}
		}

		.owl-dots {
			position: absolute;
			right: 7px;
			bottom: 7px;
			display: flex;
		}

		.owl-dot {
			width: 6px;
			height: 6px;
			margin: 0 4px;
			background: #fff;
			display: inline-block;

			&.active {
				background: var(--accent-color);
				width: 6px;
			}
		}
	}

	&-rating {
		position: absolute;
		left: 8px;
		bottom: 8px;
		display: flex;

		&__num {
			font-size: 10px;
			color: #fff;
			font-weight: 500;
			text-decoration: underline;
			margin-top: -7px;
		}
	}

	&__bot {
		background: var(--gray-light);
		padding: 16px 8px 8px;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.btn-line {
			margin-top: 4px;
		}
	}

	&__name {
		font-size: 1rem;
		color: #000;
		margin-bottom: 8px;
		display: block;
		text-decoration: none;

		&:hover {
			color: var(--accent-color);
		}
	}

	&__cat {
		font-size: $fs_small;
		margin-bottom: 16px;
	}

	&__attr {
		font-size: $fs_small;
		margin-bottom: 4px;
	}

	&-prices {
		margin-top: 24px;
		margin-bottom: 15px;

		&__top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 8px;
		}


		&__text {
			font-size: 12px;
			line-height: 83%;
			letter-spacing: 0.02em;
			margin-right: 3px;
		}

		&__navs {
			display: flex;
		}

		&__nav {
			padding: 8px;
			background: #FFFFFF;
			border-radius: 0;
			font-weight: 500;
			font-size: 12px;
			line-height: 83%;
			letter-spacing: 0.02em;
			cursor: pointer;
			flex-shrink: 0;
			box-sizing: border-box;
			display: inline-flex;
			align-items: center;

			&:not(:last-child) {
				margin-right: 8px;
				padding: 6px 8px;
			}

			&.active, &:hover {
				background: var(--second-color);
				color: #fff;
			}

			&.active {
				cursor: auto;
			}
		}

		&__contents {
			display: inline-block;
			vertical-align: middle;
		}

		&__content {
			display: none;

			&.active {
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
			}

			.price {
				font-size: 20px;
				line-height: 28px;
				font-feature-settings: 'pnum' on, 'lnum' on;

				&.old {
					position: relative;
					display: inline-block;
					vertical-align: baseline;
					margin-right: 8px;
					font-size: 16px;
					line-height: 19px;
					margin-bottom: 2px;
					z-index: 1;

					&:before {
						content: '';
						position: absolute;
						top: 50%;
						left: 0;
						right: 0;
						height: 3px;
						margin-top: -1.5px;
						background: var(--accent-color);
						z-index: -1;
					}
				}

				&--dealer {
					margin-left: 1rem;
				}
			}

		}
	}
	&-buttons{
		display: flex;
		align-items: center;
		gap: 0.5rem;
		.btn__action{
				box-sizing: border-box;
				margin-top: 4px;
				color: var(--gray-dark);
				display: flex;
				align-items: center;
				justify-content: center;
				background: #FFFFFF;
				height: 40px;
				width: 40px;
				line-height: 40px;
				border: none;
				font-weight: 500;
				border-radius: 8px;
				svg{
					width: 1.5rem;
					height: 1.5rem;
					fill: var(--gray-dark);
					transition: all 0.2s;
				}
				&:hover {
					background: var(--second-color);
					color: #fff;
			
					svg{
						fill:#fff;
					}
				}

		}
	}
	.add-cart-btn {
		gap: 4px;

		.svg {
			width: 1.5rem;
			height: 1.5rem;
			fill: var(--gray-dark);
			transition: all .2s;
		}

		rect {
			stroke: var(--gray-dark);
		}

		path {
			fill: var(--gray-dark);
		}

		&:hover {
			.svg {
				fill: #fff;
			}

			rect {
				stroke: #fff;
			}

			path {
				fill: #fff;
			}
		}
	}

}
.itm {
	&-label{
		&-1{
			top: 8px;
		}
		&-2{
			top: 28px;
		}
		&-3{
			top: 48px;
		}
		&-4{
			top: 68px;
		}
		&-panno{

		}
		&-sale{
			background: var(--sale-color);
			color: var(--light-color);
			svg{
				color: var(--light-color);
				fill: var(--light-color);
			}
		}
		&-promotion{
			background: var(--promotion-color);
			svg{
				color: var(--light-color);
			}
		}
		&-markdown{
			background: var(--markdown-color);
			color: var(--light-color);
			svg{
				fill: var(--light-color);
			}
			
			
		}
	}
}

.add-cart-block {
	.add-cart-counter {
		display: none;
	}

	&.active {
		.add-cart-btn {
			display: none;
		}

		.add-cart-counter {
			display: flex;
		}
	}
}

.add-cart-counter {
	position: relative;
	display: flex;
	align-items: center;

	&__desc {
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.02em;
		text-align: center;
		color: var(--gray-dark);
		position: absolute;
		bottom: 100%;
		left: 0;
		width: 100%;
	}

	&__btn {
		position: relative;
		width: 40px;
		height: 40px;
		box-sizing: border-box;
		border-radius: 0px;
		flex-shrink: 0;
		cursor: pointer;
		background: #fff;

		&:hover {
			background: var(--second-color);

			&:before, &:after {
				background: #fff !important;
			}
		}

		&:before {
			content: '';
			@include pos-center();
			width: 10px;
			height: 3px;
			background: var(--gray-dark);
		}

		&.right {
			&:after {
				content: '';
				@include pos-center();
				height: 10px;
				width: 3px;
				background: var(--gray-dark);
			}
		}
	}

	&__val {
		flex: 1;
		width: calc(100% - 96px);
		box-sizing: border-box;
		height: 40px;
		border: 2px solid #CACDD2;
		border-radius: 0px;
		background: #fff;
		margin: 0 8px;
		text-align: center;
		padding: 0 8px;
		letter-spacing: 0.02em;
		font-weight: 500;
		font-size: 16px;
		color: #000;
	}
}

.add-cart-btn {
	box-sizing: border-box;
	margin: 0;
	color: var(--gray-dark);
	display: flex;
	align-items: center;
	justify-content: center;
	background: #FFFFFF;
	height: 40px;
	line-height: 40px;
	border: none;
	font-weight: 500;

	.svg {
		margin-right: 8px;
	}


	&:hover {
		background: var(--second-color);
		color: #fff;

		rect {
			stroke: #fff;
		}
	}

	&.btn {
		background: var(--second-color);
		color: #fff;
		gap: 6px;

		rect {
			stroke: #fff;
		}

		&:hover {
			background: #fff;
			color: var(--second-color);

			rect {
				stroke: var(--second-color);
			}

			path {
				fill: var(--second-color);
			}
		}
	}
}
