.auth-page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-modal {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.04);
  border-radius: 8px;
  padding: 30px 55px;
  width: 360px;
  font-family: $F_Title;

  .btn-main {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }

  .h4 {
    margin-top: 0;
    margin-bottom: 48px;
    text-align: center;
  }
}

.form-button-desc {
  color: var(--gray-mid);
  font-size: 10px;
  text-align: center;
}

.auth-modal-nav {
  display: flex;
  background: #F0F1F2;
  box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.04);
  border-radius: 8px;
  height: 64px;
  padding: 8px;
  margin: 0 0 24px 0;
  gap: 8px;

  li {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    color: #4E5359;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    font-family: $F_Title;
    cursor: pointer;
    border-radius: 8px;
    transition: all .2s ease-in-out;

    @include hover {
      background: #fff;
    }

    &.active {
      background: var(--accent-color);
      color: #fff;
      cursor: auto;
    }
  }
}

.auth-modal-content {
  display: none;

  &.active {
    display: block;
  }
}

.auth-modal-error-title {
  display: block;
  text-align: left;
  color: var(--accent-color);
  font-weight: 500;
}

.h4.auth-modal-error-title{
  margin-bottom: 24px;
  text-align: left;
}

.auth-modal-error-desc {
  color: var(--black);
  text-align: left;
  margin: 24px 0;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;

  a {
    color: var(--accent-color);

    @include hover {
      color: var(--accent-hover-color);
    }
  }
}

.send-code-block {
  margin: 40px 0 16px;

  &.show-btn {
    .send-code-block {
      &__time {
        display: none;
      }

      &__btn {
        display: block;
      }
    }
  }

  &__time {
    text-align: center;
    color: var(--black);

    span {
      color: var(--accent-color);
    }
  }

  &__btn {
    display: none;
  }
}

.reset-pass-link {
  text-align: center;
  display: block;
  color: var(--gray-dark);
  margin: 35px auto;
  transition: color .2s ease-in-out;

  @include hover {
    color: var(--accent-color);
  }
}

.input-error-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: var(--accent-color);
  margin-top: -20px;
  margin-bottom: 24px;
}
.auth-error{
    color: var(--error-color);
    font-size:  var(--smallest);
    margin-top: 5px;
}
