.styling-tooltip {
	position: relative;
	display: block;
	top: 0;
	left: 0;

	&__txt {
		position: absolute;
		display: block;
		left: -5%;
		bottom: calc(100% + 10px);
		border-radius: 4px;
		padding: 4px;
		width: max-content;
		max-width: 118px;
		font-weight: 400;
		font-size: 10px;
		line-height: 1.2;
		text-align: center;
		color: var(--light-color);
		background: var(--extra-bg);
		opacity: 0;
		visibility: hidden;
		transform: translateY(5px);
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
		visibility 0.3s ease-in-out;

		svg {
			position: absolute;
			top: 100%;
			margin-top: -1px;
			left: 50%;
			width: 12px;
			height: 8px;
			fill: var(--extra-bg);
			transform: translate(-50%);
		}
	}

	.btn__styling-tooltip {
		&:hover {
			cursor: pointer;
		}

		&:hover + .styling-tooltip__txt {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}

		&:focus + .styling-tooltip__txt {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}

		svg {
			width: 96px;
			height: 96px;
			fill: var(--extra-svg-color);
		}
	}
}

@include tablet {
	.styling-tooltip__txt {
		position: relative;
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
		max-width: 100%;
		width: 100%;
		text-align: center;
		left: 0;
		min-height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0;

		svg {
			display: none;
		}
	}
}
