.decor-info {
  margin-bottom: 24px;
  padding: 24px 0;

  &__container {
    padding: 0 16px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__link {
    @include flex-all-center;

    padding: 8px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: var(--dark-color);
    transition: all 0.3s ease;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: var(--main-svg-color);
      transition: all 0.3s ease;
    }

    &:hover {
      color: var(--accent-color);
      background-color: var(--main-bg);

      svg {
        fill: var(--accent-color);
      }
    }
  }
}
