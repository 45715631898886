.decor-card {
  margin-bottom: 24px;
  padding: 16px 0 16px 0;

  &__container {
    padding: 0 16px 0 16px;
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 46px;
  }

  &__middle {
    display: flex;
    align-items: flex-end;
    margin-bottom: 32px;
    gap: 20px 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__name {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin: 0;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--extra-dark-color);
  }

  &__product-vendor-code {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--dark-color);
  }

  &__fun {
    display: flex;
    height: 56px;

    &-item {
      &:not(:last-child) {
        margin-right: 4px;
      }

      &:first-child {
        margin-right: 6px;
      }
    }
  }

  &__type-wrap {
    max-width: 56px;
    flex-shrink: 0;
  }

  &__type {
    position: relative;
    height: 56px;
    margin-bottom: 4px;

    .ramka-svg {
      width: 56px;
      height: 56px;
      fill: #dfe3eb;
    }

    .zamkoviy-svg {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: var(--main-svg-color);
    }

    &-name {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: var(--extra-dark-color);
    }

    &--collection {
      background-color: var(--main-bg);
    }
  }

  &__drop {
    @include flex-all-center;

    width: 56px;
    height: 56px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: var(--extra-dark-color);
    background-color: var(--main-bg);
  }

  &__size {
    height: 56px;

    &-item {
      width: 40px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: var(--extra-dark-color);
      background-color: var(--main-bg);

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &--active {
        color: var(--light-color);
        background-color: var(--extra-bg);
      }
    }
  }

  &__visual {
    @include flex-all-center;

    svg {
      width: 40px;
      height: 40px;
      fill: #0d111a;
    }

    &-link {
      display: block;
      padding: 5px 20px 5px 5px;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: var(--extra-dark-color);
    }

    &-name {
      max-width: 98px;
      background-color: var(--main-bg);
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--dark-color);
  }

  &__img {
    margin-right: 24px;
  }

  &__stock {
    max-width: 198px;
    margin-right: 76px;
  }

  &__text {
    margin: 0;
  }

  &__packing {
    max-width: 158px;
    margin-right: 98px;
  }

  &__prices {
    display: flex;
    flex-direction: column;
  }

  &__price {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: var(--dark-color);

	  &--dealer{
		  color: var(--accent-color);
	  }
  }

  &__p-price {
    margin: 0;
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: var(--dark-color);
    font-family: $F_Title;

    span {
      font-weight: 500;
    }
  }

  &__p-price-text {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #4e5359;
    font-family: $F_Title;
  }
}

.all-decor {
  &__title {
    margin: 0;
    padding-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--extra-dark-color);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    line-height: 0;
    gap: 20px 0;
  }

  &__item {
    position: relative;
    flex-shrink: 0;
    flex-wrap: wrap;
    border: 2px solid transparent;
    transition: 0.2s;

    &:hover {
      border: 2px solid var(--accent-color);
      transition: 0.2s;

      .all-decor__popup {
        opacity: 1;
        transform: translateY(0px);
        pointer-events: auto;
      }
    }

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__popup {
    position: absolute;
    bottom: 100%;
    left: 0;
    margin-bottom: 13px;
    width: 136px;
    padding: 8px;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    opacity: 0;
    transform: translateY(13px);
    transition: all 0.2s ease-in-out;
    pointer-events: none;
    z-index: 100;
    background: #fff;

    &-img {
		 position: relative;
      width: 100%;
      padding-bottom: 100%;
      background-position: center;
      background-size: cover;
      margin-bottom: 8px;
    }

    &-name {
      font-size: 10px;
      line-height: 13px;
      letter-spacing: 0.02em;
      color: #4e5359;
    }
  }
}


@include big-desktop {
  .decor-card {
    &__stock {
      margin-right: 30px;
    }

    &__packing {
      margin-right: 30px;
    }
  }

  .decor-nav-wrap__right {
    flex-shrink: 0;
  }

}

@include min-desktop {
  .decor-card {
    &__visual {
      margin-left: 20px;
    }

    &__middle {

    }

    &__stock {
      width: 200px;
      flex-shrink: 0;
      margin-right: 0;
      align-self: center;
    }

    &__prices {
      width: 200px;
      flex-shrink: 0;
      margin-top: 20px;
    }

  }
}

@include extra-tablet {
  .decor-card {
    &__top {
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    &__name {
      width: calc(100% - 200px);
    }

    &__fun, &__visual {
      width: 200px;
      justify-content: flex-start;
    }

    &__fun {
      margin-bottom: 40px;
    }
  }
}

@include big-mobile {
  .decor-card {

    &__container{
      overflow: hidden;
    }

    &__top {
      display: block;
    }

    &__name {
      width: 100%;
      margin-bottom: 20px;
    }

    &__fun {
      display: inline-flex;
      width: auto;
      margin-right: 25px;
      margin-bottom: 0;
    }

    &__visual {
      display: inline-flex;
      width: auto;
      margin-left: 0;
    }
  }
}
