.spec-tooltip {
  display: block;
  position: relative;
  top: 0;
  left: 0;

  &__txt {
    position: absolute;
    left: -35%;
    bottom: calc(100% + 10px);
    border-radius: 4px;
    padding: 4px;
    width: max-content;
    max-width: 370px;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.2;
    color: var(--light-color);
    background: var(--extra-bg);
    opacity: 0;
    visibility: hidden;
    transform: translateY(5px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
      visibility 0.3s ease-in-out;

    svg {
      position: absolute;
      top: 18px;
      left: 20px;
      width: 12px;
      height: 8px;
      fill: var(--extra-bg);
    }

    .q-tooltip--center {
      top: 18px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .btn__spec-tooltip {
    &:hover {
      cursor: pointer;
    }

    &:hover + .spec-tooltip__txt {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    &:focus + .spec-tooltip__txt {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    svg {
      width: 16px;
      height: 16px;
      fill: var(--extra-svg-color);
      margin-left: 8px;
    }
  }
}
