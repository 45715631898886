.table {
	a {
		color: inherit;
	}
}

.page__body.expanded {
	.catalog-table-block {
		width: calc(100vw - 246px - 265px - 48px - 24px);
	}
}

.catalog-table-block {
	flex: 1;
	height: calc(100vh - 90px);
	padding: 16px 0;
	box-sizing: border-box;
	width: calc(100vw - 104px - 265px - 48px - 48px - 148px);
	padding-right: 24px;

	.dataTables_wrapper {
		overflow: auto;
		scrollbar-gutter: stable;

		&::-webkit-scrollbar {
			height: 4px;
			width: 4px;
		}

		&::-webkit-scrollbar:vertical {
			height: 4px;
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #c3cad9;
			border-radius: 8px;
		}
	}

	.dataTables_paginate {
		position: sticky;
		left: 0;
		bottom: 0;
		z-index: 1;

		&:after {
			content: "";
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			height: 5px;
			background: #fff;
		}
	}

	th {
		position: sticky !important;
		top: 0;
		background: #fff;
		z-index: 2;
	}

	td:first-child {
		background: #fff;
		z-index: 1;
	}
}

.dataTables_wrapper {
	align-self: flex-start !important;
	transition: all 0.3s ease-in-out;
	border-radius: 8px;
	padding: 0;
	overflow: hidden;
	margin: 16px 0;
}

.dataTables_length {
	display: none;
}

.dataTables_scrollHead {
	background: #fff;

	&.has-scroll {
		padding-right: 4px;
	}
}

.dataTables_scrollHeadInner {
	width: 100%;

	&:after {
		content: "";
		position: absolute;
		right: 0;
		top: 0;

	}

}


.dataTables_paginate {
	width: 100%;
	display: flex;
	background-color: var(--light-color);
}

table.dataTable {
	td, th {
		&:first-child {
			min-width: 110px;
			max-width: 110px;
			box-sizing: border-box;
		}
	}
}

table.dataTable thead > tr > th:nth-child(1),
table.dataTable thead > tr > td:nth-child(1) {
	position: sticky;
	left: 0;
	padding-right: 26px;
}

.catalog-table-block {
	table.dataTable thead > tr > th:nth-last-child(1),
	table.dataTable tbody > tr > td:nth-last-child(1) {
		&::after {
			display: none !important;
		}

		&::before {
			display: none !important;
		}
	}
}

.dataTables_scrollBody {
	background-color: var(--light-color);
	max-height: calc(100vh - 250px) !important;
	scrollbar-gutter: stable;

	&::-webkit-scrollbar {
		height: 4px;
		width: 4px;
	}

	&::-webkit-scrollbar:vertical {
		height: 4px;
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #c3cad9;
		border-radius: 8px;
	}
}

table.dataTable thead th,
table.dataTable thead td {
	padding-top: 16px;
	padding-bottom: 16px;
}

table.dataTable tbody th,
table.dataTable tbody td {
	padding: 0;
}

table.dataTable {
	margin: 0;
}

.paginate_button.current {
	border: 2px solid var(--accent-color) !important;
	background: var(--light-color) !important;
}

.paginate_button.current:hover {
	border: 2px solid transparent;
}

.dataTables_wrapper .dataTables_paginate {
	padding: 15px 16px;
	border-top: 1px solid var(--t-select-row-color) !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
	border-bottom: none;
	background: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	padding: 8.5px 12px;
	border: 2px solid transparent;
	border-radius: 4px;
	transition: all 0.3s ease-in-out;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
	background: var(--light-color) !important;
	border: 2px solid transparent;
	box-shadow: none;
	padding: 8.5px 12px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	border: 2px solid var(--main-bg);
	background: var(--main-bg);
	color: #000 !important;
}

table.dataTable thead th,
table.dataTable thead td {
	border-bottom: 1px solid var(--light-gray-color);
}

.paginate_button:not(:last-child) {
	margin-right: 8px;
}

.dataTables_empty {
	font-size: 12px;
}

.dataTables_filter {
	display: none;
}

.specnew {
	width: 9.55% !important;
}

.table-price-col {
	white-space: normal;

	span {
		white-space: nowrap;

		&:not(:last-child) {
			margin-right: 5px;
		}
	}
}

@include tablet {
	.catalog-table-block {
		padding-right: 0;
	}
}

@include extra-tablet {
	.catalog-table-block {
		width: 100%;
		height: auto;
	}
}
