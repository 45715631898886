@charset "UTF-8";
/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
:root {
  --font-family: "Inter", sans-serif;
  --content-width: 1440px;
  --content-fluid-width: 1840px;
  --container-offset: 40px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(
  		  var(--content-fluid-width) + (var(--container-offset) * 2)
  );
  --light-color: #fff;
  --dark-color: #202b40;
  --extra-dark-color: #334466;
  --more-dark-color: #0d111a;
  --accent-color: #e62e2e;
  --second-color: #e62e2e;
  --accent-hover-color: #C01722;
  --main-bg: #f7f8fa;
  --extra-bg: #7d8fb3;
  --bg-btn-action: #f7f8fa;
  --bg-pagination-color: #edeff2;
  --t-select-row-color: #edeff2;
  --user-border-color: #dfe3eb;
  --theme-checkbox-bg: #e3e7eb;
  --input-placeholder-color: #c3cad9;
  --input-color: #0d111a;
  --main-svg-color: #7d8fb3;
  --extra-svg-color: #dfe3eb;
  --action-svg-color: #62708c;
  --filter-svg-color: #62708c;
  --input-focus-color: #e62e2e;
  --copy-descr-color: #7d8fb3;
  --divider-color: #dfe3eb;
  --tree-arrow-color: #c3cad9;
  --search-svg-color: #c3cad9;
  --light-gray-color: #c3cad9;
  --laying-type-color: #f5333f;
  --v2-red: #f5333f;
  --V2-Background: #F7F8FA;
  --laying-type-hover: #cacdd2;
  --breadcrumb-color: #7d8fb3;
  --line-color: #edeff2;
  --gray-mid: #B7BABE;
  --gray-dark: #4E5359;
  --gray-light: #F0F1F2;
  --black: #171717;
  --text: #0F2744;
  --error-color: #e62e2e;
  --promotion-color: linear-gradient(16deg, #FFCF3C 5.42%, #FFD500 94.55%);
  --fire-color: #FFD500;
  --sale-color: #F5333F;
  --markdown-color: #FF6610;
  --h1: 2rem;
  --h2: 1.75rem;
  --h3: 1.5rem;
  --h4: 1.25rem;
  --h5: 1rem;
  --v-2-header-color: #346;
  --small: 0.875rem;
  --smallest: 0.75rem;
}

.template--fineapp {
  --accent-color: #00B1E6;
  --accent-hover-color: #008ECB;
  --second-color: #97BF0D;
  --second-hover-color: #63A000;
  --input-focus-color: #00B1E6;
}

@media (max-width: 658px) {
  :root {
    --container-offset: 20px;
  }
}
/*@include font-face("Inter", "../fonts/InterRegular", 400, normal);
@include font-face("Inter", "../fonts/InterMedium", 500, normal);
@include font-face("Inter", "../fonts/InterSemiBold", 600, normal);*/
@font-face {
  font-family: "Stapel";
  src: url("../fonts/../fonts/Stapel_Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Stapel";
  src: url("../fonts/../fonts/Stapel-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  font-family: "Stapel";
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ul {
  list-style-type: none;
}

.page {
  height: 100%;
  font-family: "Stapel";
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  height: 100%;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]:hover,
input[type=number]:focus {
  -moz-appearance: number-input;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.container-fluid {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-fluid-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.fixed-scroll {
  scroll-margin-top: 100px;
}

section {
  background-color: var(--light-color);
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 8px;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.g1 {
  gap: 1rem;
}

.g1_5 {
  gap: 1.5rem;
}

.grid-cs-2 {
  grid-column-start: 2;
}

.grid-ce-3 {
  grid-column-end: 4;
}

@media (max-width: 1024px) {
  .grid-t-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-t-1 {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 576px) {
  .grid-p-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-p-1 {
    grid-template-columns: 1fr;
  }
  .g-p-05 {
    gap: 0.5rem;
  }
}
.page__body.expanded .main-content, .page__body.expanded .main-content-col {
  width: calc(100% - 246px);
}

.svg-fill {
  display: none;
}

.fill-img, .catalog-itm-slider__itm img, .catalog-itm-slider__itm {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.show-svg-fill .svg-fill {
  display: inline-block;
}
.show-svg-fill .svg-standart {
  display: none;
}

.show-calc-price .js-calc-price-hide {
  display: none;
}
.show-calc-price .js-calc-price {
  display: inline-block;
}
.show-calc-price .js-calc-price.td-nowrap {
  display: table-cell;
}

.js-calc-price {
  display: none;
  color: var(--accent-color);
}

.relative {
  position: relative;
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
  margin-top: 0;
}

.h1 {
  font-weight: 500;
  font-size: var(--h1);
  line-height: 1.2;
  font-family: "Stapel";
}

.h2 {
  font-size: var(--h2);
  font-weight: 500;
}

.h3, .bonus-count__value {
  font-weight: 500;
  font-size: var(--h3);
  line-height: 1.2;
  font-family: "Stapel";
}

.h4, .cart-step-head {
  font-weight: 500;
  font-size: var(--h4);
  line-height: 1.2;
  font-family: "Stapel";
}

.h5 {
  font-weight: 500;
  font-size: var(--h5);
  line-height: 20px;
  font-family: "Stapel";
}

a.link:link {
  color: var(--accent-color);
  text-decoration: underline;
}

/* visited link */
a.link:visited {
  color: var(--accent-color);
  text-decoration: underline;
}

/* mouse over link */
a.link:hover {
  color: var(--accent-hover-color);
  text-decoration: none;
}

/* selected link */
a.link:active {
  color: var(--accent-hover-color);
  text-decoration: none;
}

.attention {
  color: var(--error-color);
}

.w100 {
  width: 100%;
}

.fs-smallest {
  font-size: 10px;
}

.va-m {
  align-items: center;
  vertical-align: middle;
}

.margin-title {
  margin-bottom: 24px;
}

.margin-small {
  margin-bottom: 0.5rem;
}

.margin-big {
  margin-bottom: 3rem;
}

.main-content {
  padding: 16px;
  width: calc(100% - 104px);
}

.widget {
  background: #fff;
  padding: 24px 16px;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 8px;
  overflow: hidden;
  color: var(--v-2-header-color);
}
.widget.visible {
  overflow: visible;
}
.widget-title {
  margin-bottom: 1.5rem;
  margin-top: 0;
  font-weight: 500;
}
.widget-subtitle {
  margin-bottom: 1.5rem;
  color: var(--v-2-header-color);
  font-weight: 500;
}
.widget--col-2 {
  grid-column: span 2;
}
.widget select {
  border: 0px;
  border-bottom: 1px dashed var(--v-2-header-color);
  font-size: var(--h2);
  font-weight: 500;
  color: var(--v-2-header-color);
}

.text-format h1 {
  margin-bottom: 2.5rem;
}
.text-format h4 {
  margin-bottom: 0;
}
.text-format h5 {
  margin-bottom: 0;
}
.text-format b {
  font-weight: 500;
}
.text-format ul {
  margin: 0;
  padding-left: 1.75rem;
  margin-bottom: 1rem;
}
.text-format ul li {
  position: relative;
}
.text-format ul li:before {
  content: "";
  position: absolute;
  left: -0.75rem;
  top: 0.33rem;
  width: 3px;
  height: 3px;
  background: var(--dark-color);
  transform: rotate(45deg);
}
.text-format ol {
  padding-left: 1.75rem;
  margin: 0 0 1rem;
}
.text-format a {
  color: var(--accent-color);
}
@media screen and (min-width: 900px) {
  .text-format a:hover {
    color: var(--accent-hover-color);
  }
}

.c-main {
  color: var(--accent-color);
}

.c-gray {
  color: var(--v-2-header-color, #346);
}

.owl-nav.disabled {
  display: none;
}

.owl-dots.disabled {
  display: none;
}

.owl-stage {
  display: flex;
  transition: transform 0.3s ease-in-out;
  backface-visibility: hidden;
}

.owl-dots {
  pointer-events: none;
}

.owl-dot {
  pointer-events: auto;
  width: 8px;
  height: 8px;
  background: none;
  padding: 0;
  margin: 0 4px;
  border: none;
  background: var(--laying-type-hover);
  transition: all 0.3s ease-in-out;
}
.owl-dot.active {
  width: 24px;
  background: var(--accent-color);
}

.square {
  display: inline-block;
  vertical-align: top;
  transform: translateY(-5px);
  font-size: 0.6em;
}

.modal-block {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1111;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.modal-block.active {
  opacity: 1;
  pointer-events: auto;
}

.modal {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  transform: translateY(15px);
}
.modal.active {
  opacity: 1;
  transform: translateY(0px);
}
.modal.active .modal-content {
  pointer-events: auto;
}
.modal-fade {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.8;
  cursor: pointer;
}
.modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  max-height: 90%;
  overflow: auto;
  /* W3C standard
    сейчас только для Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) #F0F1F2;
  /* для Chrome/Edge/Safari */
}
.modal-content::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.modal-content::-webkit-scrollbar-track {
  background: #F0F1F2;
  border-radius: 6px;
  cursor: pointer;
}
.modal-content::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border: 3px solid #F0F1F2;
  cursor: pointer;
  border-radius: 6px;
}
.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-hover-color);
}
.modal__title-desc {
  font-family: "Stapel";
  margin-bottom: 24px;
}

.modal-close {
  float: right;
  position: sticky;
  top: 0;
  transform: translateY(-15px);
}

#modal-copy-buffer .modal-content {
  width: 350px;
  text-align: center;
}
#modal-copy-buffer .modal-content .h1 {
  margin-bottom: 30px;
}

#modal-success .modal-content {
  width: 544px;
  text-align: center;
  overflow: visible;
}
#modal-success .btn-second {
  width: 177px;
}

#modal-bonus-output .modal-content {
  width: 330px;
  text-align: center;
  overflow: visible;
}
#modal-bonus-output .btn-second {
  width: 177px;
}

#modal-bonus-add .modal-content {
  width: 544px;
  padding: 40px 16px;
}
#modal-bonus-add .btn-main, #modal-bonus-add .ais-RefinementList-showMore, #modal-bonus-add .ais-RangeInput-submit, #modal-bonus-add .btn-second {
  margin: 30px auto 0;
  display: block;
}

.modal-stas-top {
  margin: -65% auto 15px;
  display: block;
}

.cross, .accordion-flag-block .accordion-head__arrow {
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.cross:before, .accordion-flag-block .accordion-head__arrow:before, .cross:after, .accordion-flag-block .accordion-head__arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background: black;
  transform: rotate(45deg);
  border-radius: 4px;
}
.cross:after, .accordion-flag-block .accordion-head__arrow:after {
  transform: rotate(-45deg);
}
@media screen and (min-width: 900px) {
  .cross:hover:before, .accordion-flag-block .accordion-head__arrow:hover:before, .cross:hover:after, .accordion-flag-block .accordion-head__arrow:hover:after {
    background: var(--gray-dark);
  }
}

.style-table {
  width: 100% !important;
  background-color: var(--light-color);
  font-family: "Stapel";
  border-spacing: 0;
}
.style-table th {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: var(--dark-color);
}
.style-table thead th.t-cat__header {
  border-bottom: 1px solid var(--gray-mid);
}
.style-table tr:first-child td:nth-child(4):hover .popup-preview {
  top: 0;
}
.style-table td {
  padding: 13.5px 26px 13.5px 16px !important;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--dark-color);
  border-bottom: 1px solid var(--t-select-row-color);
}
.style-table td:nth-child(4) {
  position: relative;
}
.style-table td:nth-child(4):hover .popup-preview {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  pointer-events: auto;
}
.style-table__row:hover {
  background-color: var(--line-color) !important;
}
.style-table__row:hover td:nth-child(1) {
  background-color: var(--line-color) !important;
}
.style-table__row:hover td:nth-last-child(2) {
  background-color: var(--line-color) !important;
}

.accordion {
  background: #fff;
  border-radius: 8px;
}
.accordion .check_box__item {
  margin-right: 5px;
}
.accordion-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--extra-dark-color);
  min-height: 52px;
  padding: 5px 16px;
  cursor: pointer;
}
.accordion-head.active:after {
  transform: rotate(180deg);
}
.accordion-head:after {
  content: "";
  border: 5px solid transparent;
  border-top: 7px solid var(--gray-dark);
  position: relative;
  top: 2px;
  transition: all 0.3s ease-in-out;
  transform-origin: center 25%;
}
@media screen and (min-width: 900px) {
  .accordion-head:hover {
    color: var(--gray-dark);
  }
  .accordion-head:hover .check_box__text {
    color: var(--gray-dark);
  }
}
.accordion-content {
  padding: 16px;
}
.accordion-content--link {
  padding: 0;
}
.accordion-content .check_box {
  align-items: center;
}
.accordion-content .check_box:not(:last-child) {
  margin-bottom: 16px;
}
.accordion-link-itm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  color: var(--black);
}
@media screen and (min-width: 900px) {
  .accordion-link-itm:hover {
    background: var(--gray-light);
  }
}
.accordion-link-itm .status-label {
  flex-shrink: 0;
}
.accordion-link-itm__text {
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  font-family: "Stapel";
}

.flex-sb {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.accordion-all-link {
  padding: 9px 12px 7px;
  margin: 16px;
  display: inline-block;
  color: #ABB8CA;
  border: 1px solid #ABB8CA;
  border-radius: 4px;
  font-family: "Stapel";
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
@media screen and (min-width: 900px) {
  .accordion-all-link:hover {
    background: #ABB8CA;
    color: #fff;
  }
}

.main-content-col {
  height: auto;
  display: flex;
  flex-direction: column;
}

.td-nowrap {
  white-space: nowrap;
}

.table-scroll {
  overflow: auto;
  /* W3C standard
    сейчас только для Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) #F0F1F2;
  /* для Chrome/Edge/Safari */
}
.table-scroll::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.table-scroll::-webkit-scrollbar-track {
  background: #F0F1F2;
  border-radius: 6px;
  cursor: pointer;
}
.table-scroll::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border: 3px solid #F0F1F2;
  cursor: pointer;
  border-radius: 6px;
}
.table-scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-hover-color);
}
.table-scroll .dataTables_wrapper {
  /* W3C standard
    сейчас только для Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) #F0F1F2;
  /* для Chrome/Edge/Safari */
}
.table-scroll .dataTables_wrapper::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.table-scroll .dataTables_wrapper::-webkit-scrollbar-track {
  background: #F0F1F2;
  border-radius: 6px;
  cursor: pointer;
}
.table-scroll .dataTables_wrapper::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border: 3px solid #F0F1F2;
  cursor: pointer;
  border-radius: 6px;
}
.table-scroll .dataTables_wrapper::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-hover-color);
}

.discount input {
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  width: 64px;
  height: 32px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid var(--v-2-grey-light, #F0F1F2);
  outline: none;
}

.counter {
  position: relative;
}
.counter input {
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  width: 64px;
  height: 32px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid var(--v-2-grey-light, #F0F1F2);
  outline: none;
}
.counter-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 50%;
  cursor: pointer;
}
.counter-btn:before {
  content: "";
  position: absolute;
  left: 4px;
  top: 50%;
  margin-top: -6px;
  border: 5px solid transparent;
  border-bottom: 5px solid var(--gray-mid);
  transform-origin: center 75%;
}
@media screen and (min-width: 900px) {
  .counter-btn:hover:before {
    border-bottom-color: var(--accent-color);
  }
}
.counter-btn.js-counter-down {
  top: 50%;
}
.counter-btn.js-counter-down:before {
  transform: rotate(180deg);
  margin-top: -10px;
}
.counter--text input {
  padding: 0;
  border: none;
}

.td-hide {
  display: none;
}

@media (max-width: 1024px) {
  .page__body.expanded .main-content {
    width: 100%;
  }
  .main-content {
    width: 100%;
  }
  .main-content-col {
    min-height: calc(100vh - 82px);
  }
  .widget--col-2 {
    grid-column: auto;
  }
}
@media (max-width: 576px) {
  .accordion-head {
    padding-left: 8px;
  }
  .accordion-content {
    padding: 8px;
  }
  .accordion-content--link {
    padding: 0;
  }
  .accordion-link-itm {
    padding-left: 8px;
    padding-right: 8px;
  }
  .accordion-all-link {
    margin: 8px;
  }
}
.btn, .ais-RefinementList-showMore {
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  transition: 0.2s all ease;
  cursor: pointer;
}
.btn-main, .ais-RefinementList-showMore, .ais-RangeInput-submit, .btn-second {
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  font-weight: 500;
  transition: 0.2s all ease;
  cursor: pointer;
  background: var(--accent-color);
  border: none;
  outline: none;
  padding: 15px 19px;
  color: #fff;
  font-size: 15px;
  line-height: 19px;
  font-family: "Stapel";
}
@media screen and (min-width: 900px) {
  .btn-main:hover, .ais-RefinementList-showMore:hover, .ais-RangeInput-submit:hover, .btn-second:hover {
    background: var(--accent-hover-color);
  }
}
.btn-main.disabled, .disabled.ais-RefinementList-showMore, .disabled.ais-RangeInput-submit, .disabled.btn-second {
  pointer-events: none;
  background: var(--gray-light);
  color: var(--gray-mid);
}
.btn-line {
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  font-weight: 500;
  transition: 0.2s all ease;
  background: none;
  cursor: pointer;
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
  outline: none;
  padding: 15px 19px;
  font-size: 15px;
  line-height: 19px;
  font-family: "Stapel";
}
@media screen and (min-width: 900px) {
  .btn-line:hover {
    background: var(--accent-hover-color);
    border-color: var(--accent-hover-color);
    color: #fff;
  }
}
.btn-line--gray {
  border-color: var(--gray-mid);
  color: var(--gray-mid);
}
@media screen and (min-width: 900px) {
  .btn-line--gray:hover {
    background: var(--gray-mid);
    border-color: var(--gray-mid);
  }
}
.btn-line.disabled {
  pointer-events: none;
  background: var(--gray-light);
  color: var(--gray-mid);
}
.btn-line-second {
  border: 1px solid var(--second-color);
  color: var(--second-color);
}
@media screen and (min-width: 900px) {
  .btn-line-second:hover {
    background: var(--second-hover-color);
    border-color: var(--second-hover-color);
  }
}
.btn-second {
  background: var(--gray-light);
  color: var(--gray-dark);
}
@media screen and (min-width: 900px) {
  .btn-second:hover {
    background: var(--gray-mid);
    color: #000;
  }
}
.btn--small, .ais-RefinementList-showMore, .ais-RangeInput-submit {
  padding: 10px 19px;
}
.btn__pagination {
  padding: 9.5px 4px;
  border: 2px solid transparent;
  color: var(--dark-color);
  min-width: 35px;
  display: inline-block;
  text-align: center;
}
.btn__pagination:not(:last-child) {
  margin-right: 8px;
}
.btn__pagination:hover {
  background-color: var(--bg-pagination-color);
}
.btn__pagination--active {
  border: 2px solid var(--accent-color);
}
.btn__pagination--active:hover {
  background-color: transparent;
}
.btn__show {
  border: 1px solid #dfe3eb;
  padding: 8.5px 16px;
}
.btn__show:hover {
  background-color: var(--bg-pagination-color);
  color: var(--dark-color);
}
.btn__up {
  border: 1px solid #dfe3eb;
  padding: 8.5px 16px;
  margin-left: 14px;
}
.btn__up:hover {
  background-color: var(--bg-pagination-color);
  color: var(--dark-color);
}
.btn__action {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 24px;
  height: 26px;
  transition: all 0.3s ease-in-out;
}
.btn__action:hover {
  background-color: var(--bg-btn-action);
  cursor: pointer;
}
.btn__action.active {
  background-color: var(--bg-btn-action);
}
.btn__action.active svg {
  fill: var(--accent-color);
}
.btn__action:hover svg {
  fill: var(--accent-color);
}
.btn__action svg {
  width: 16px;
  height: 16px;
  fill: var(--action-svg-color);
  transition: all 0.3s ease-in-out;
}
.btn__search {
  display: flex;
  align-items: center;
  justify-content: center;
  all: unset;
  width: 48px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.btn__search:hover svg {
  fill: var(--accent-color);
}
.btn__tree {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.2;
  color: var(--extra-dark-color);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.btn__tree--no-active {
  color: var(--light-gray-color);
}
.btn__tree--active {
  color: var(--dark-color);
}
.btn__all-catalog {
  display: flex;
  align-items: center;
}
.btn__all-catalog svg {
  margin-right: 4px;
}
.btn__reflect {
  padding: 25px 4px 0 4px;
}
.btn__reflect svg {
  width: 16px;
  height: 16px;
  fill: #0d111a;
  flex-shrink: 0;
}
.btn__cart-popup {
  width: 100%;
  padding: 12px 0;
  border: 1px solid var(--accent-color);
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--accent-color);
  background-color: var(--light-color);
  transition: all 0.2s ease;
}
.btn__cart-popup:hover {
  color: var(--light-color);
  background-color: var(--accent-color);
}
.btn__cart-popup:not(:last-child) {
  margin-bottom: 16px;
}
.btn__decor-popup {
  width: 48px;
  height: 48px;
  margin-left: 24px;
  background-color: var(--main-bg);
}
.btn__decor-popup svg {
  width: 22px;
  height: 22px;
  fill: var(--action-svg-color);
}
.btn__cart-add {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: var(--light-color);
  background-color: var(--accent-color);
  transition: all 0.3s ease-in-out;
}
.btn__cart-add:hover {
  cursor: pointer;
  background-color: #bc2626;
}
.btn__cart-add svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  fill: var(--light-color);
}
.btn__about-more {
  padding: 15px 24px;
  margin-top: auto;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: var(--light-color);
  background-color: var(--extra-bg);
  transition: all 0.2s ease-in-out;
}
.btn__about-more:hover {
  background-color: #677694;
}

.btn-link {
  color: var(--text);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  padding: 15px 16px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 900px) {
  .btn-link:hover {
    background: var(--text);
    color: #fff;
  }
}

.popup-menu {
  position: absolute;
  right: 78px;
  top: 52px;
  border-radius: 4px;
  max-width: 194px;
  width: 100%;
  padding: 8px 6px;
  box-shadow: 4px 8px 20px rgba(0, 13, 84, 0.2);
  font-family: var(--extra-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-transform: initial;
  color: var(--dark-color);
  background-color: var(--light-color);
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.1);
  border-radius: 8px;
  z-index: 3;
}
@media (max-width: 576px) {
  .popup-menu {
    top: 30px;
  }
}
.popup-menu__item {
  transition: all 0.3s ease-in-out;
}
.popup-menu__item:hover {
  border-radius: 8px;
  background-color: var(--bg-btn-action);
}
.popup-menu__item:not(:last-child) {
  margin-bottom: 8px;
}
@media (max-width: 576px) {
  .popup-menu {
    top: 34px;
    left: -178px;
  }
}
.popup-menu svg {
  width: 16px;
  height: 16px;
}
.popup-menu--active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.popup-menu__title {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.03em;
}
.popup-menu__title:not(:last-child) {
  margin-bottom: 8px;
}
.popup-menu__link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  color: #334466;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}
.popup-menu__link:hover, .popup-menu__link:focus {
  color: var(--accent-color);
}
.popup-menu__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 26px;
  padding: 4px 5px;
}

.popup-preview {
  position: absolute;
  top: -125px;
  left: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  pointer-events: none;
  z-index: 10;
}
.popup-preview__card {
  border-radius: 4px;
  padding: 8px;
  background-color: var(--light-color);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.popup-preview__title {
  margin: 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: var(--extra-dark-color);
}

.tooltip-def {
  position: relative;
}
.tooltip-def__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: var(--light-color);
}
.tooltip-def__btn:hover + .tooltip-def__txt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.tooltip-def__btn:focus + .tooltip-def__txt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.tooltip-def__btn svg {
  width: 16px;
  height: 16px;
  fill: var(--extra-svg-color);
}
.tooltip-def__txt {
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  border-radius: 4px;
  padding: 4px;
  width: max-content;
  max-width: 370px;
  box-shadow: 4px 8px 20px rgba(0, 13, 84, 0.2);
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  color: var(--dark-color);
  background: var(--light-color);
  opacity: 0;
  visibility: hidden;
  transform: translateY(5px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.tooltip-def__txt::after {
  content: "";
  position: absolute;
  top: -4px;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 8px 6px;
  border-color: transparent transparent var(--light-color) transparent;
  transform: translateX(-50%);
}

.page__body:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: #000;
  opacity: 0;
  z-index: 199;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}
.page__body.menu-open:before {
  opacity: 0.6;
  pointer-events: auto;
}
.page__body.menu-open .sidebar-nav__title {
  opacity: 1;
}

.sidebar {
  position: sticky;
  top: 81px;
  width: 104px;
  padding: 16px 20px 0 24px;
  transition: all 0.3s ease-in-out;
  height: calc(100vh - 82px);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--main-bg);
}
.sidebar::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.sidebar::-webkit-scrollbar-track {
  background: transparent;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #c3cad9;
  border-radius: 8px;
}
.sidebar__bottom {
  position: sticky;
  bottom: 0;
  padding-bottom: 22px;
  left: 0;
  margin-left: 0;
  display: flex;
  align-items: flex-end;
  margin-top: 40px;
  width: 205px;
  transform: translateX(-15px);
}
.sidebar__descr {
  position: relative;
  margin: 0 0 0 16px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--copy-descr-color);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  min-width: 190px;
}
.sidebar__descr:before {
  content: "";
  position: absolute;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -22px;
  background: var(--main-bg);
  z-index: -1;
}
.sidebar__link-app svg {
  width: 8px;
  height: 54px;
  color: var(--extra-dark-color);
}
.sidebar--collapse .sidebar {
  width: 104px;
}
.sidebar--collapse .sidebar:hover {
  width: 104px;
}
.sidebar--collapse .sidebar-nav {
  width: 52px;
}
.sidebar--collapse .sidebar-nav__title {
  opacity: 1;
}

.sidebar .t-catalog__outer {
  width: 1238px !important;
}

.sidebar-nav {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  flex-shrink: 0;
}
.sidebar-nav__item:not(:last-child) {
  margin-bottom: 4px;
}
.sidebar-nav__item:nth-child(5n):after {
  display: block;
  content: "";
  height: 1px;
  width: 100%;
  border-radius: 8px;
  background-color: var(--divider-color);
  margin-top: 16px;
}
.sidebar-nav__item:nth-child(5n) {
  margin-bottom: 16px;
}
.sidebar-nav__item:nth-child(6), .sidebar-nav__item:nth-child(11) {
  margin-top: 16px;
}
.sidebar-nav__link {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  color: var(--extra-dark-color);
  transition: background-color 0.1s !important;
}
.sidebar-nav__link svg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  fill: var(--main-svg-color);
}
.sidebar-nav__link .svg path {
  fill: var(--main-svg-color);
}
.sidebar-nav__link:hover {
  color: var(--light-color);
  background-color: var(--main-bg);
}
.sidebar-nav__link:hover svg {
  fill: var(--accent-color);
}
.sidebar-nav__link:hover .svg path {
  fill: var(--accent-color);
}
.sidebar-nav__link:hover .sidebar-nav__title {
  color: var(--accent-color);
}
.sidebar-nav__link--active {
  color: var(--light-color);
  background-color: var(--accent-color);
}
.sidebar-nav__link--active svg {
  fill: var(--light-color);
}
.sidebar-nav__link--active:hover {
  background-color: var(--accent-color);
}
.sidebar-nav__link--active:hover svg {
  fill: var(--light-color);
}
.sidebar-nav__link--active:hover .sidebar-nav__title {
  color: var(--light-color);
}
.sidebar-nav__title {
  margin-left: 16px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;
}

.sidebar-wrapper {
  width: 104px;
  position: relative;
  z-index: 201;
  transition: all 0.3s ease-in-out;
}
.sidebar-wrapper.hover .sidebar {
  width: 246px;
  transition: all 0.3s ease-in-out;
}
.sidebar-wrapper.hover .sidebar .sidebar-nav__title {
  opacity: 1;
}
.sidebar-wrapper.hover .sidebar__descr {
  opacity: 1;
  visibility: visible;
  transition: all 1.2s ease-in-out;
}

.sidebar-wrapper.expanded {
  width: 246px;
}
.sidebar-wrapper.expanded .sidebar__descr {
  opacity: 1;
  visibility: visible;
  transition: all 1.2s ease-in-out;
}

.sidebar.expanded {
  width: 246px;
}
.sidebar.expanded .sidebar-nav__title {
  opacity: 1;
}

.expand-svg.replaced-svg {
  display: none;
}

.collapse-svg {
  display: none;
}
.collapse-svg.replaced-svg {
  display: block;
}

@media (max-width: 1024px) {
  .sidebar {
    width: 246px;
    top: 0;
  }
  .sidebar-wrapper {
    position: fixed;
    width: 246px;
    left: -246px;
  }
  .sidebar-wrapper {
    overflow: auto;
    height: 100%;
  }
  .sidebar-wrapper.menu-open {
    transform: translateX(100%);
  }
  .sidebar-nav__link--expand {
    display: none;
  }
}
.search__container {
  margin-bottom: 16px;
}
.search__form {
  position: relative;
  max-width: 265px;
  width: 100%;
}
.search__input {
  width: 100%;
  height: 48px;
  padding: 0 5px 0 46px;
  border: 2px solid transparent;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  vertical-align: middle;
  color: var(--input-color);
  outline: none;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  transition: all 0.3s ease-in-out;
}
.search__input:focus {
  border: 2px solid var(--accent-color);
}
.search__input::placeholder {
  color: var(--light-gray-color);
}
.search__icon {
  position: absolute;
  top: 50%;
  left: 8px;
  right: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 6px;
  transform: translateY(-50%);
}
.search__icon svg {
  width: 18px;
  height: 18px;
  fill: var(--search-svg-color);
}
.search__filter-matches {
  display: flex;
  align-items: center;
  height: 30px;
}

@media (max-width: 800px) {
  .search__form {
    max-width: 100%;
  }
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
}
.pagination__item:not(:last-child) {
  margin-right: 10px;
}

.breadcrumb-lk {
  padding: 24px 0;
}
.breadcrumb-lk__list {
  display: flex;
}
.breadcrumb-lk__item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.breadcrumb-lk__item:not(:last-child) {
  margin-right: 8px;
}
.breadcrumb-lk__item:not(:last-child)::after {
  display: block;
  margin-left: 8px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 5px;
  border-color: transparent transparent transparent #c3cad9;
}
.breadcrumb-lk__item--active {
  color: var(--extra-dark-color);
}
.breadcrumb-lk__item:last-child {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
}
.breadcrumb-lk__link {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: var(--breadcrumb-color);
  transition: all 0.3s ease-in-out;
}
.breadcrumb-lk__link:hover {
  color: var(--accent-color);
}

.tabs__nav {
  padding: 0;
  margin-top: 0;
  margin-bottom: 24px;
}
.tabs__nav-item--active .tabs__nav-btn {
  color: var(--more-dark-color);
}
.tabs__nav-item--active::after {
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  background-color: red;
}
.tabs__nav-btn {
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--extra-bg);
}
.tabs__nav-btn:hover {
  cursor: pointer;
}
.tabs__nav-btn:focus {
  color: var(--more-dark-color);
  background-color: var(--light-color);
}

@media (max-width: 658px) {
  .tabs__nav {
    white-space: nowrap;
    overflow: auto;
    padding-bottom: 15px;
  }
}
.switch-item {
  display: none;
}
.switch-item.active {
  display: block;
}

.stock__top {
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
}
.stock__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--dark-color);
}
.stock__dots {
  display: flex;
  align-items: center;
  margin-left: 4px;
}
.stock__dot {
  width: 20px;
  height: 8px;
  background-color: var(--light-gray-color);
}
.stock__dot--fill {
  background-color: var(--accent-color);
}
.stock__dot:not(:last-child) {
  margin-right: 8px;
}

.collection-tooltip {
  display: block;
  position: relative;
  top: 0;
  left: 0;
}
.collection-tooltip__txt {
  position: absolute;
  left: 60px;
  bottom: 2px;
  border-radius: 4px;
  padding: 4px;
  width: max-content;
  max-width: 370px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  color: var(--light-color);
  background: var(--extra-bg);
  opacity: 0;
  visibility: hidden;
  transform: translateY(5px);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
  z-index: 1;
}
.collection-tooltip__txt svg {
  position: absolute;
  top: 30%;
  left: -7%;
  width: 12px;
  height: 8px;
  transform: rotate(90deg);
  fill: var(--extra-bg);
}
.collection-tooltip .btn__collection-tooltip:hover {
  cursor: pointer;
}
.collection-tooltip .btn__collection-tooltip:hover + .collection-tooltip__txt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.collection-tooltip .btn__collection-tooltip:focus + .collection-tooltip__txt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.nice-form input {
  width: 100%;
  border: 1px solid #C1C7CF;
  border-radius: 8px;
  height: 48px;
  padding: 0 12px;
  margin-bottom: 24px;
}
.nice-form textarea {
  width: 100%;
  border: 1px solid #C1C7CF;
  border-radius: 8px;
  padding: 18px 12px;
}
.nice-form textarea ~ .input-placeholder {
  top: 22px;
}
.nice-form textarea:focus, .nice-form textarea:not(:placeholder-shown), .nice-form textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.nice-form textarea:focus ~ .input-placeholder, .nice-form textarea:not(:placeholder-shown) ~ .input-placeholder, .nice-form textarea:-webkit-autofill ~ .input-placeholder {
  top: 10px;
  font-size: 10px;
}
.nice-form input, .nice-form textarea {
  outline: none;
}
.nice-form input:focus, .nice-form textarea:focus {
  border-color: #000;
}
.nice-form .input-error {
  top: -20px;
}

.input-error {
  position: relative;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: var(--accent-color);
}

.input-col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 16px;
}

.input {
  position: relative;
  display: block;
  margin-bottom: 24px;
}
.input.no-margin {
  margin-bottom: 0;
}
.input.no-margin input {
  margin: 0;
}
.input.padding-center input {
  padding-top: 0;
}
.input input {
  width: 100%;
  height: 48px;
  border: 1px solid var(--gray-mid);
  border-radius: 8px;
  padding: 14px 12px 0;
  font-size: 16px;
  line-height: 18px;
  outline: none;
  font-family: "Stapel";
  font-weight: 400;
  color: var(--v-2-black, #171717);
}
.input input:focus, .input input:not(:placeholder-shown), .input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.input input:focus ~ .input-placeholder, .input input:not(:placeholder-shown) ~ .input-placeholder, .input input:-webkit-autofill ~ .input-placeholder {
  transform: translateY(-17px);
  font-size: 10px;
}
.input-placeholder {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--gray-mid);
  font-family: "Stapel";
  font-weight: 400;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}
.input.error input {
  border-color: var(--laying-type-color);
}

.input-password-ico {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  width: 30px;
  text-align: center;
  cursor: pointer;
}
.input-password-ico__hide {
  opacity: 0;
}
.input-password-ico.show .input-password-ico__hide {
  opacity: 1;
}
.input-password-ico.show .input-password-ico__show {
  opacity: 0;
}
.input-password-ico svg {
  width: 16px;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 900px) {
  .input-password-ico:hover svg {
    fill: var(--accent-color);
  }
}

.auth-modal-input-desc {
  font-family: "Stapel";
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: var(--gray-dark);
  margin: 8px 0;
}
.auth-modal-input-desc a {
  color: var(--accent-color);
}
@media screen and (min-width: 900px) {
  .auth-modal-input-desc a:hover {
    color: var(--accent-hover-color);
  }
}

.js-select-wrap {
  height: 48px;
}
.js-select-wrap select {
  display: none;
}

.js-license-select + .custom-select {
  min-width: 485px;
}

.custom-select {
  position: relative;
  max-width: 100%;
  width: 100%;
  display: inline-block;
}
.custom-select.is-open .custom-select__dropdown {
  opacity: 1;
  transform: translateY(0px);
  pointer-events: auto;
}
.custom-select.is-open .custom-select__single:after {
  transform: rotate(-135deg);
  margin-top: 0px;
}
.custom-select__single {
  background-color: #fff;
  border: 1px solid var(--gray-mid);
  padding: 0 25px 0 12px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 12px;
  color: var(--v-2-black, #171717);
  cursor: pointer;
  position: relative;
  height: 48px;
  border-radius: 8px;
}
.custom-select__single:after {
  content: "";
  position: absolute;
  right: 11px;
  top: 50%;
  width: 8px;
  height: 8px;
  margin-top: -6px;
  box-sizing: border-box;
  border-right: 1px solid var(--gray-dark);
  border-bottom: 1px solid var(--gray-dark);
  transform: rotate(45deg);
}
.custom-select__dropdown {
  position: absolute;
  top: 100%;
  margin-top: 6px;
  z-index: 10;
  background: #FFFFFF;
  border: 1px solid var(--accent-color);
  box-sizing: border-box;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-5px);
  transition: all, 0.3s;
  border-radius: 4px;
}
.custom-select__dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.custom-select__dropdown-item {
  padding: 12px 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: var(--text);
}
.custom-select__dropdown-item:hover {
  background: var(--accent-color);
  color: #fff;
}
.custom-select__dropdown-item.is-active {
  cursor: auto;
  background: var(--accent-color);
  color: #fff;
}
.custom-select__dropdown-item.placeholder-itm {
  display: none;
}

.check_box {
  position: relative;
  display: flex;
  gap: 5px;
  cursor: pointer;
}
.check_box__text {
  font-size: 12px;
  line-height: 15px;
  color: #202B40;
}
.check_box__text a {
  text-decoration: underline;
  color: var(--accent-color) !important;
}
.check_box__text a:hover {
  text-decoration: none;
}
.check_box:hover .check_box__item {
  background-color: var(--gray-mid);
}
.check_box:hover .js-checkbox:checked ~ .check_box__item {
  background: var(--accent-hover-color);
}
.check_box.disabled {
  pointer-events: none;
}
.check_box__text a {
  color: inherit;
}

input.js-checkbox {
  width: 20px;
  height: 10px;
  opacity: 0;
  position: absolute;
  top: 7px;
  left: 0;
}
input.js-checkbox.round + .check_box__item {
  border-radius: 50%;
}
input.js-checkbox:checked + .check_box__item {
  background: var(--accent-color);
}
input.js-checkbox:checked + .check_box__item:before {
  opacity: 1;
}
input.js-checkbox:active + .check_box__item {
  outline: 4px solid rgba(212, 227, 255, 0.5);
}
input.js-checkbox[disabled] ~ span {
  pointer-events: none;
}
input.js-checkbox[disabled] ~ .check_box__item {
  opacity: 0.65;
}

.check_box__item {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: var(--gray-mid);
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  outline: 4px solid transparent;
  flex-shrink: 0;
}
.check_box__item:before {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  background: url("../img/check.svg") no-repeat center;
  background-size: 50%;
  opacity: 0;
}

.radio_box {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}
@media screen and (min-width: 900px) {
  .radio_box:hover {
    color: var(--accent-color);
  }
}
.radio_box input {
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
}
.radio_box input:checked ~ .radio_box__item {
  background: var(--accent-color);
}
.radio_box input:checked ~ .radio_box__item:before {
  transform: scale(1);
}
.radio_box__item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  background: var(--gray-mid);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  margin-right: 0.5rem;
  flex-shrink: 0;
}
.radio_box__item:before {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  right: 4px;
  bottom: 4px;
  background: #fff;
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}
.radio_box__desc {
  color: var(--v-2-grey-dark, #4E5359);
  font-size: 0.625rem;
  margin-top: 0.5rem;
}

.input-datepicker {
  position: relative;
}
.input-datepicker__hint {
  padding-left: 16px;
  color: var(--gray-mid);
  font-size: 13px;
  font-weight: 600;
}
.input-datepicker__clear {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
}
.input-datepicker__clear:hover path {
  fill: var(--accent-hover-color);
}
.input-datepicker__ico {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}
.input-datepicker input:not(:placeholder-shown) ~ .input-inner-placeholder {
  top: 0;
  font-size: var(--small);
}
.input-datepicker input:not(:placeholder-shown) ~ .input-datepicker__clear {
  display: flex;
}
.input-datepicker input:not(:placeholder-shown) ~ .input-datepicker__ico {
  display: none;
}

.input-inner-placeholder {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  padding: 0 5px;
  transition: all 0.2s ease-in-out;
}

.ui-datepicker {
  box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.15);
  border-radius: 4px;
  background: #fff;
  padding: 20px;
  display: none;
  border: 1px solid var(--gray-mid);
  margin-top: 10px;
}
.ui-datepicker-calendar {
  text-align: center;
  font-size: 0.75rem;
}
.ui-datepicker-calendar th {
  font-weight: 400;
}
.ui-datepicker-calendar a {
  position: relative;
  text-decoration: none;
  color: inherit;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
}
.ui-datepicker-calendar a:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  border-radius: 50%;
  background: var(--accent-color);
  z-index: -1;
  opacity: 0;
}
.ui-datepicker-calendar a:hover, .ui-datepicker-calendar a.ui-state-active {
  color: #fff;
}
.ui-datepicker-calendar a:hover:before, .ui-datepicker-calendar a.ui-state-active:before {
  opacity: 1;
}
.ui-datepicker-header {
  position: relative;
  padding: 0 40px;
}
.ui-datepicker-prev, .ui-datepicker-next {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -6px;
  font-size: 0;
  width: 12px;
  height: 12px;
  border-bottom: 3px solid var(--text);
  border-left: 3px solid var(--text);
  transform: rotate(45deg);
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
}
.ui-datepicker-prev:hover, .ui-datepicker-next:hover {
  border-color: var(--accent-hover-color);
}
.ui-datepicker-next {
  position: absolute;
  right: 0;
  left: auto;
  transform: rotate(225deg);
}
.ui-datepicker-title {
  margin-bottom: 25px;
}
.ui-datepicker-title select {
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: 15px;
  color: var(--text);
  margin-right: 25px;
}

.js-datepicker input {
  padding-right: 35px;
}
.js-datepicker svg {
  position: absolute;
  right: 8px;
  top: 0;
  width: 24px;
  height: 24px;
  fill: var(--accent-color);
}

.auth-modal .input input {
  margin-bottom: 0;
}

.header {
  position: sticky;
  top: 0;
  z-index: 201;
}
.header-gamburger {
  display: none;
}
.header__container {
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 2px solid #edeff2;
  background-color: var(--light-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__logo {
  display: flex;
  align-items: center;
}
.header__logo__fineapp {
  width: 9.25rem;
  margin-right: 4.7rem;
}
.header-main {
  flex: 1;
}
.header-phone {
  display: inline-block;
  color: var(--v-2-grey-dark);
  font-weight: 500;
  font-family: "Stapel";
  transition: color 0.2s;
  line-height: 100%;
  margin-bottom: -0.2rem;
}
@media screen and (min-width: 900px) {
  .header-phone:hover {
    color: var(--accent-color);
  }
}

.menu__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu__item {
  position: relative;
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}
.menu__item:not(:first-child):not(:last-child):hover {
  background-color: var(--main-bg);
}
.menu__item:not(:first-child):not(:last-child):hover .menu__item__ico {
  fill: var(--accent-color);
}
.menu__item:first-child:hover {
  background-color: var(--main-bg);
}
.menu__item--user {
  border: 2px solid var(--user-border-color);
  padding: 0;
  flex-shrink: 0;
}
.menu__item--switch {
  padding: 12px 0;
}
.menu__item--switch:hover {
  background: none;
}
.menu__item:not(:nth-last-child(-n+2)) {
  margin-right: 16px;
}
.menu__item:nth-last-child(1) {
  margin-left: 24px;
}
.menu__item svg {
  width: 24px;
  height: 24px;
  fill: var(--main-svg-color);
  transition: all 0.3s ease-in-out;
}
.menu__item--active {
  width: 100%;
  background-color: var(--main-bg);
}
.menu__item--active:nth-child(n+2):nth-child(-n+4)::before {
  position: absolute;
  top: -4px;
  left: 44px;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--accent-color);
  transform: translateX(-24px);
}

.avatar-menu {
  position: absolute;
  right: 40px;
  top: 100%;
  background: #fff;
  z-index: 2;
  margin-top: 8px;
  box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.1);
  border-radius: 8px;
  width: 230px;
  padding: 16px;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  color: var(--extra-dark-color);
  transition: all 0.2s ease-in-out;
  transform: translateY(-8px);
  opacity: 0;
  pointer-events: none;
}
.avatar-menu:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  height: 10px;
}
.avatar-menu.active {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}
.avatar-menu-head {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--user-border-color);
}
.avatar-menu-head__name {
  margin-bottom: 6px;
}
.avatar-menu-head__login {
  font-size: 10px;
  color: var(--extra-bg);
}
.avatar-menu-list {
  padding: 0;
  margin: 0;
}
.avatar-menu-list:not(:last-child) {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--user-border-color);
}
.avatar-menu-list li:not(:last-child) {
  margin-bottom: 8px;
}
.avatar-menu-list a {
  display: flex;
  gap: 4px;
  align-items: center;
  min-height: 34px;
  color: inherit;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}
.avatar-menu-list a .svg {
  width: 24px;
  object-fit: none;
}
.avatar-menu-list a .svg.svg-user-ico {
  height: 16px;
}
@media screen and (min-width: 900px) {
  .avatar-menu-list a:hover {
    background: var(--main-bg);
    color: var(--accent-color);
  }
}
.avatar-menu-list a.active {
  background: var(--accent-color);
  color: #fff;
}
.avatar-menu-list a.active path {
  fill: #fff;
}

.cart-info {
  display: flex;
}
.cart-info__descr {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}
.cart-info__count {
  font-weight: 600;
}
.cart-info__sum {
  font-weight: 600;
}
.cart-info .menu__item__close {
  display: none;
}

@media screen and (min-width: 900px) {
  .cart-popup-hover:hover .cart-popup {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
}

.user__icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user__image {
  border-radius: 8px;
}

.switch-theme {
  position: relative;
  display: block;
  width: 48px;
  height: 24px;
}
.switch-theme__input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-theme__input:checked + .switch-theme__slider {
  background-color: var(--accent-color);
}
@media screen and (min-width: 900px) {
  .switch-theme__input:checked + .switch-theme__slider:hover {
    background-color: var(--accent-hover-color);
  }
}
.switch-theme__input:checked + .switch-theme__slider::before {
  transform: translateX(24px);
}
.switch-theme__slider {
  position: absolute;
  border-radius: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--theme-checkbox-bg);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.switch-theme__slider:hover {
  background-color: #c7ced5;
}
.switch-theme__slider::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 2px;
  background-color: var(--light-color);
  box-shadow: 0px 2px 4px rgba(29, 31, 33, 0.2);
  transition: all 0.3s ease-in-out;
}

.top-search__wrapper {
  max-width: 100%;
  padding: 0;
  flex-shrink: 0;
}
.top-search__wrapper--wider {
  width: 360px;
}
.top-search__container {
  display: flex;
  width: 48px;
  height: 48px;
  max-width: 100%;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: color 0.5s ease-in-out;
}
.top-search__container--expanded {
  width: 100%;
  background-color: var(--main-bg);
}
.top-search__container--expanded .top-search__form {
  width: 100%;
  padding: 4px;
}
.top-search__form {
  width: 0px;
  align-self: center;
}
.top-search__input {
  all: unset;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--input-color);
  transition: all 0.3s ease-in-out;
}
.top-search__input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--input-placeholder-color);
}

.gamburger {
  display: inline-block;
  position: relative;
  height: 20px;
  width: 20px;
  transition: all 0.4s ease-in-out;
  margin-top: -2px;
}
.gamburger:after, .gamburger:before {
  content: "";
  left: 0;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 66%;
  background-color: var(--accent-color);
  transition: all 0.5s;
}
.gamburger:before {
  top: 30%;
}
.gamburger.active:after {
  transform: rotate(45deg);
  top: 57%;
}
.gamburger.active:before {
  transform: rotate(-45deg);
  top: 53%;
}

.header__logo__small {
  display: none;
}

.header-wallet-itm {
  display: flex;
  align-items: center;
  width: auto;
  color: var(--extra-bg);
}
.header-wallet-itm span {
  display: inline-block;
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
}
@media screen and (min-width: 900px) {
  .header-wallet-itm:hover {
    color: var(--accent-color);
  }
  .header-wallet-itm:hover svg {
    fill: var(--accent-color);
  }
}

.cart-popup {
  position: absolute;
  top: 100%;
  margin-top: 32px;
  right: 0;
  width: 375px;
  padding: 0.5rem 1.5rem 1.5rem;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 8px 24px 0px rgba(21, 21, 22, 0.1);
  max-height: 726px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: all 0.3s ease-in-out;
}
.cart-popup .table-scroll {
  margin-right: -1.5rem;
  padding-right: 1.5rem;
}
.cart-popup:before {
  content: "";
  position: absolute;
  bottom: 100%;
  height: 35px;
  left: 0;
  width: 100%;
  z-index: 1;
}
.cart-popup .order-detail-table th {
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid var(--gray-light);
}
.cart-popup .order-detail-table tbody tr:first-child td {
  border-top: none;
}
.cart-popup-price {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
}
.cart-popup-price .js-calc-price {
  color: var(--laying-type-color);
}
.cart-popup-price__itm__desc {
  font-size: 10px;
  margin-bottom: 0.44rem;
}
.cart-popup__footer {
  border-top: 1px solid var(--gray-mid);
  padding-top: 1rem;
  margin-top: 0.5rem;
}
.cart-popup__footer .h4, .cart-popup__footer .cart-step-head {
  margin-bottom: 0.5rem;
}
.cart-popup__footer .btn-main, .cart-popup__footer .ais-RefinementList-showMore, .cart-popup__footer .ais-RangeInput-submit, .cart-popup__footer .btn-second, .cart-popup__footer .btn-line {
  width: 100%;
  margin-top: 0.5rem;
}

@media (max-width: 1024px) {
  .header-gamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: var(--gray-light);
    border-radius: 8px;
    margin-right: 8px;
    transition: all 0.3s ease-in-out;
    flex-shrink: 0;
  }
  .header-gamburger.menu-open {
    background: var(--accent-color);
  }
  .header-gamburger.menu-open .gamburger:before, .header-gamburger.menu-open .gamburger:after {
    background: #fff;
  }
  .header__logo {
    flex: 1;
  }
  .header__logo__fineapp {
    margin-right: 1rem;
  }
  .header__container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .header-phone {
    display: none;
  }
  .top-search__wrapper {
    position: inherit;
  }
  .top-search__container--expanded {
    width: 48px;
  }
  .top-search__container--expanded .top-search__form {
    opacity: 1;
    pointer-events: auto;
  }
  .top-search__form {
    position: absolute;
    top: 100%;
    left: 1.5rem;
    right: 1.5rem;
    margin-top: 1rem;
    background: #fff;
    padding: 1rem !important;
    width: auto !important;
    border: 1px solid var(--gray-light);
    border-radius: 0.5rem;
    box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.15);
    opacity: 0;
    pointer-events: none;
  }
  .top-search__wrapper--wider {
    width: auto;
  }
}
@media (max-width: 768px) {
  .cart-info__descr {
    display: none;
  }
  .cart-info .menu__item__close {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  .cart-info .menu__item__close .cross:before, .cart-info .menu__item__close .accordion-flag-block .accordion-head__arrow:before, .accordion-flag-block .cart-info .menu__item__close .accordion-head__arrow:before, .cart-info .menu__item__close .cross:after, .cart-info .menu__item__close .accordion-flag-block .accordion-head__arrow:after, .accordion-flag-block .cart-info .menu__item__close .accordion-head__arrow:after {
    background: var(--accent-color);
  }
  .cart-info.active .menu__item__ico {
    opacity: 0;
  }
  .cart-info.active .menu__item__close {
    opacity: 1;
  }
  .cart-popup {
    position: fixed;
    top: 82px;
    right: 0;
    margin-top: 0;
    transform: translateX(100%);
  }
  .cart-popup.active {
    transform: translateX(0);
    pointer-events: auto;
    opacity: 1;
  }
}
@media (max-width: 658px) {
  .menu__item:not(:nth-last-child(-n+2)) {
    margin-right: 4px;
  }
  .menu__item:nth-last-child(1) {
    margin-left: 8px;
  }
  .header__logo {
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    flex-shrink: 0;
    background: var(--gray-light);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header__logo__small {
    display: block;
    width: 26px;
  }
  .header__logo__full {
    display: none;
  }
  .menu__item--switch {
    display: none;
  }
  .cart-popup {
    position: fixed;
    top: 82px;
    width: 100%;
    height: calc(100% - 82px);
    max-height: calc(100% - 82px);
    margin-top: 0;
    transform: translateX(100%);
    opacity: 1;
  }
  .cart-popup__close {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    padding: 1rem;
  }
  .cart-popup__close .cross:before, .cart-popup__close .accordion-flag-block .accordion-head__arrow:before, .accordion-flag-block .cart-popup__close .accordion-head__arrow:before, .cart-popup__close .cross:after, .cart-popup__close .accordion-flag-block .accordion-head__arrow:after, .accordion-flag-block .cart-popup__close .accordion-head__arrow:after {
    background: var(--v-2-header-color);
  }
  .cart-popup.active {
    transform: translateX(0);
    pointer-events: auto;
  }
}
@media (max-width: 390px) {
  .header__logo {
    display: none;
  }
}
.tooltip-menu {
  position: absolute;
  top: 50px;
}
.tooltip-menu__txt {
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  border-radius: 4px;
  padding: 4px;
  width: max-content;
  max-width: 370px;
  box-shadow: 4px 8px 20px rgba(0, 13, 84, 0.2);
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  color: var(--dark-color);
  background: var(--light-color);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(5px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.tooltip-menu__txt::after {
  content: "";
  position: absolute;
  top: -4px;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 8px 6px;
  border-color: transparent transparent var(--light-color) transparent;
  transform: translateX(-50%);
}
.tooltip-menu__txt--search {
  left: -18px;
}
.tooltip-menu__txt--cart {
  right: -38px;
}
.tooltip-menu__txt--notify {
  right: -25px;
}

.menu__item:hover .tooltip-menu__txt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out 2s, transform 0.3s ease-in-out 2s, visibility 0.3s ease-in-out 2s;
}

.menu__item:focus .tooltip-menu__txt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.catalog {
  background-color: #f7f8fa;
  flex: 1;
  display: flex;
}
.catalog__content {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex: 1;
}
.catalog__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.catalog__title {
  margin: 16px 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--extra-dark-color);
}
.catalog__flex {
  display: flex;
  flex-direction: column;
}
.catalog .dataTables_wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
}

@media (max-width: 1024px) {
  .catalog__content {
    padding: 0 24px;
  }
}
@media (max-width: 800px) {
  .catalog__content {
    display: block;
    width: 100vw;
  }
}
.tree-wrapper {
  width: 265px;
  align-self: flex-start;
  background-color: var(--light-color);
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 8px;
  padding: 16px 4px 4px 16px;
  overflow: hidden;
}
.tree-btns {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.tree-btns__left {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
}
.tree-btns__left:hover .btn__tree {
  color: var(--accent-color);
}
.tree-btns__left:hover svg {
  fill: var(--accent-color);
}
.tree-btns__left svg {
  width: 16px;
  height: 16px;
  fill: var(--search-svg-color);
  transition: all 0.3s ease-in-out;
}
.tree-btns__right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 64px;
}

@media (max-width: 800px) {
  .tree-wrapper {
    width: 100%;
  }
}
#tree {
  max-width: 265px;
  overflow: auto;
}

ul.fancytree-container {
  max-height: 640px;
  border: none;
  overflow: scroll;
  outline: none;
  font-family: inherit;
  color: #334466;
}
ul.fancytree-container::-webkit-scrollbar {
  height: 4px;
  cursor: pointer;
}
ul.fancytree-container::-webkit-scrollbar:vertical {
  height: 4px;
  width: 4px;
  cursor: pointer;
}
ul.fancytree-container::-webkit-scrollbar-track {
  background: transparent;
}
ul.fancytree-container::-webkit-scrollbar-thumb {
  background-color: #c3cad9;
  border-radius: 8px;
}

span.fancytree-title {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  background: none !important;
  border: none !important;
  color: #334466;
}

.fancytree-ext-filter span.fancytree-title mark {
  color: var(--accent-color);
  background-color: transparent;
}

span.fancytree-node {
  border-radius: 4px;
  min-height: 24px;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 900px) {
  span.fancytree-node:hover {
    background: #EDEFF2;
  }
}

span.fancytree-icon {
  flex-shrink: 0;
  background: url("../img/svg/file-ico.svg") no-repeat center;
}

.ui-fancytree ul ul {
  padding-left: 32px;
}

span.fancytree-node.fancytree-folder > span.fancytree-icon {
  background-position: 0 0;
  background-image: url("../skin-custom-tree/tree-subfolder.svg");
}

span.fancytree-node.fancytree-folder.fancytree-expanded > span.fancytree-icon {
  background-position: 0 0;
  background-image: url("../skin-custom-tree/tree-subfolder1.svg");
}
span.fancytree-node.fancytree-folder.fancytree-expanded .fancytree-title {
  color: var(--accent-color);
}

.fancytree-has-children.fancytree-ico-cf span.fancytree-icon:hover {
  background-position: 0 0;
}

.fancytree-ext-filter-dimm span.fancytree-node.fancytree-match span.fancytree-title {
  color: var(--accent-color);
  font-weight: 500;
}

.fancytree-ext-filter-dimm span.fancytree-node.fancytree-submatch span.fancytree-title {
  color: var(--accent-color);
}

span.fancytree-selected .fancytree-title {
  font-style: normal;
}

span.fancytree-checkbox {
  position: relative;
  background: #E3E7EB;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  flex-shrink: 0;
}
span.fancytree-checkbox:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 12px;
  height: 12px;
  background: url("../img/svg/check.svg") no-repeat center;
  background-size: contain;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
@media screen and (min-width: 900px) {
  span.fancytree-checkbox:hover {
    background: #C7CED5;
  }
}

.fancytree-selected span.fancytree-checkbox {
  background: var(--accent-color);
}
.fancytree-selected span.fancytree-checkbox:before {
  opacity: 1;
}
@media screen and (min-width: 900px) {
  .fancytree-selected span.fancytree-checkbox:hover {
    background: #B30000;
  }
}

@media (max-width: 800px) {
  #tree {
    max-width: 100%;
  }
}
.t-cat {
  width: 100% !important;
  background-color: var(--light-color);
}
.t-cat th {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: var(--dark-color);
}
.t-cat thead th.t-cat__header {
  border-bottom: 1px solid var(--gray-mid);
}
.t-cat td {
  padding: 13.5px 26px 13.5px 16px !important;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--dark-color);
  border-bottom: 1px solid var(--t-select-row-color);
}
.t-cat td:nth-child(4) {
  position: relative;
}
.t-cat td:nth-child(4):hover .popup-preview {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  pointer-events: auto;
}
.t-cat__row:hover {
  background-color: var(--line-color) !important;
}
.t-cat__row:hover td:nth-child(1) {
  background-color: var(--line-color) !important;
}
.t-cat__row:hover td:nth-last-child(2) {
  background-color: var(--line-color) !important;
}
.t-cat__row:nth-child(-n+4) .popup-preview {
  top: 0px;
}
.t-cat th:nth-child(1) {
  position: sticky;
  left: 0;
  width: 160px !important;
  background-color: var(--light-color);
  border-right: 1px solid var(--gray-mid);
  z-index: 200;
}
.t-cat td:nth-child(1) {
  position: sticky;
  left: 0;
  width: 160px !important;
  background-color: var(--light-color);
  border-right: 1px solid var(--gray-mid);
}
.t-cat th:nth-last-child(1) {
  width: 0 !important;
  padding: 0 !important;
}
.t-cat td:nth-last-child(1) {
  width: 0 !important;
  padding: 0 !important;
}
.t-cat th:nth-last-child(2) {
  position: sticky !important;
  right: 0;
  width: 112px !important;
  min-width: 112px;
  background-color: var(--light-color);
  box-sizing: border-box;
  border-left: 1px solid var(--gray-mid);
}
.t-cat td:nth-last-child(2) {
  position: sticky;
  width: 112px !important;
  min-width: 112px;
  right: 0;
  background-color: var(--light-color);
  border-left: 1px solid var(--gray-mid);
  padding: 6.5px 0 6.5px 0 !important;
  box-sizing: border-box;
}
.t-cat td:nth-child(2),
.t-cat th:nth-child(2) {
  width: 160px !important;
}
.t-cat td:nth-child(3),
.t-cat th:nth-child(3) {
  width: 135px !important;
}
.t-cat td:nth-child(4),
.t-cat th:nth-child(4) {
  width: 230px !important;
}
.t-cat td:nth-child(5),
.t-cat th:nth-child(5) {
  width: 98px !important;
}
.t-cat td:nth-child(6),
.t-cat th:nth-child(6) {
  width: 98px !important;
}
.t-cat td:nth-child(7),
.t-cat th:nth-child(7) {
  width: 98px !important;
}
.t-cat td:nth-child(8),
.t-cat th:nth-child(8) {
  width: 98px !important;
}
.t-cat-menu {
  max-width: 112px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.t-cat-menu__item {
  display: block;
  width: 24px;
  height: 26px;
}
.t-cat-menu__item:not(:last-child) {
  margin-right: 8px;
}
.t-cat__link {
  color: var(--dark-color);
  transition: all 0.3s ease-in-out;
}
.t-cat__link:hover {
  color: var(--accent-color);
}
.t-cat__cell:nth-child(4):hover .t-cat__link {
  color: var(--accent-color);
}

@media (max-width: 576px) {
  .t-cat td:nth-child(1) {
    position: relative;
    box-shadow: none;
  }
  table.dataTable thead > tr > th:nth-child(1) {
    position: relative !important;
    box-shadow: none;
  }
}
.graph-modal__close {
  background-image: none;
}

.popup {
  width: 100%;
  max-width: 375px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(38, 51, 77, 0.2);
}
.popup__title {
  margin: 0;
  padding-bottom: 8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--extra-dark-color);
}
.popup__prices {
  display: flex;
  align-items: center;
}
.popup__prices--bottom {
  padding-bottom: 16px;
}
.popup__price {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--dark-color);
}
.popup__item:not(:last-child) {
  margin-bottom: 24px;
}
.popup__item:nth-child(3) {
  margin-bottom: 32px;
}
.popup__item:not(:last-child)::after {
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--light-gray-color);
}
.popup__choose {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
}
.popup__laying-type {
  flex-shrink: 0;
}
.popup__image {
  position: relative;
  display: block;
}
.popup__image:hover {
  cursor: pointer;
}
.popup__right {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.popup__input {
  width: 100%;
  height: 40px;
  padding: 11px 12px;
  border-radius: 5px;
  border: 1px solid var(--input-placeholder-color);
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: var(--dark-color);
  outline: none;
  transition: all 0.3s ease-in-out;
}
.popup__input:-webkit-autofill, .popup__input:-webkit-autofill:hover, .popup__input:-webkit-autofill:focus, .popup__input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--dark-color);
  box-shadow: 0 0 0 30px var(--light-color) inset !important;
}
.popup__input::placeholder {
  color: var(--input-placeholder-color);
}
.popup__input:focus {
  border: 1px solid var(--accent-color);
}
.popup__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price__item {
  flex-grow: 1;
  gap: 0 20px;
}

.prod-type {
  display: flex;
  align-items: center;
}
.prod-type__item:not(:last-child) {
  margin-right: 16px;
}
.prod-type__item [type=radio] {
  display: none;
}
.prod-type__item svg {
  width: 64px;
  height: 40px;
  border-radius: 4px;
  fill: var(--laying-type-hover);
  transition: all 0.2s ease-in-out;
}
.prod-type__item svg:hover {
  fill: var(--accent-color);
  cursor: pointer;
}
.prod-type__item--active svg {
  fill: var(--accent-color);
}

.modal--no-meter .square_text {
  display: none;
}
.modal--no-meter .popup__right {
  margin-left: 0;
  width: 100%;
}
.modal--no-meter .popup__right .popup__area {
  width: 100%;
}

.table a {
  color: inherit;
}

.page__body.expanded .catalog-table-block {
  width: calc(100vw - 246px - 265px - 48px - 24px);
}

.catalog-table-block {
  flex: 1;
  height: calc(100vh - 90px);
  padding: 16px 0;
  box-sizing: border-box;
  width: calc(100vw - 104px - 265px - 48px - 48px - 148px);
  padding-right: 24px;
}
.catalog-table-block .dataTables_wrapper {
  overflow: auto;
  scrollbar-gutter: stable;
}
.catalog-table-block .dataTables_wrapper::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.catalog-table-block .dataTables_wrapper::-webkit-scrollbar:vertical {
  height: 4px;
  width: 4px;
}
.catalog-table-block .dataTables_wrapper::-webkit-scrollbar-track {
  background: transparent;
}
.catalog-table-block .dataTables_wrapper::-webkit-scrollbar-thumb {
  background-color: #c3cad9;
  border-radius: 8px;
}
.catalog-table-block .dataTables_paginate {
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.catalog-table-block .dataTables_paginate:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 5px;
  background: #fff;
}
.catalog-table-block th {
  position: sticky !important;
  top: 0;
  background: #fff;
  z-index: 2;
}
.catalog-table-block td:first-child {
  background: #fff;
  z-index: 1;
}

.dataTables_wrapper {
  align-self: flex-start !important;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
  margin: 16px 0;
}

.dataTables_length {
  display: none;
}

.dataTables_scrollHead {
  background: #fff;
}
.dataTables_scrollHead.has-scroll {
  padding-right: 4px;
}

.dataTables_scrollHeadInner {
  width: 100%;
}
.dataTables_scrollHeadInner:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

.dataTables_paginate {
  width: 100%;
  display: flex;
  background-color: var(--light-color);
}

table.dataTable td:first-child, table.dataTable th:first-child {
  min-width: 110px;
  max-width: 110px;
  box-sizing: border-box;
}

table.dataTable thead > tr > th:nth-child(1),
table.dataTable thead > tr > td:nth-child(1) {
  position: sticky;
  left: 0;
  padding-right: 26px;
}

.catalog-table-block table.dataTable thead > tr > th:nth-last-child(1)::after,
.catalog-table-block table.dataTable tbody > tr > td:nth-last-child(1)::after {
  display: none !important;
}
.catalog-table-block table.dataTable thead > tr > th:nth-last-child(1)::before,
.catalog-table-block table.dataTable tbody > tr > td:nth-last-child(1)::before {
  display: none !important;
}

.dataTables_scrollBody {
  background-color: var(--light-color);
  max-height: calc(100vh - 250px) !important;
  scrollbar-gutter: stable;
}
.dataTables_scrollBody::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.dataTables_scrollBody::-webkit-scrollbar:vertical {
  height: 4px;
  width: 4px;
}
.dataTables_scrollBody::-webkit-scrollbar-track {
  background: transparent;
}
.dataTables_scrollBody::-webkit-scrollbar-thumb {
  background-color: #c3cad9;
  border-radius: 8px;
}

table.dataTable thead th,
table.dataTable thead td {
  padding-top: 16px;
  padding-bottom: 16px;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 0;
}

table.dataTable {
  margin: 0;
}

.paginate_button.current {
  border: 2px solid var(--accent-color) !important;
  background: var(--light-color) !important;
}

.paginate_button.current:hover {
  border: 2px solid transparent;
}

.dataTables_wrapper .dataTables_paginate {
  padding: 15px 16px;
  border-top: 1px solid var(--t-select-row-color) !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: none;
  background: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 8.5px 12px;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: var(--light-color) !important;
  border: 2px solid transparent;
  box-shadow: none;
  padding: 8.5px 12px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: 2px solid var(--main-bg);
  background: var(--main-bg);
  color: #000 !important;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 1px solid var(--light-gray-color);
}

.paginate_button:not(:last-child) {
  margin-right: 8px;
}

.dataTables_empty {
  font-size: 12px;
}

.dataTables_filter {
  display: none;
}

.specnew {
  width: 9.55% !important;
}

.table-price-col {
  white-space: normal;
}
.table-price-col span {
  white-space: nowrap;
}
.table-price-col span:not(:last-child) {
  margin-right: 5px;
}

@media (max-width: 1024px) {
  .catalog-table-block {
    padding-right: 0;
  }
}
@media (max-width: 800px) {
  .catalog-table-block {
    width: 100%;
    height: auto;
  }
}
.catalog-itm {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.catalog-itm-wrap {
  width: 25%;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.catalog-itm-label {
  position: absolute;
  left: 8px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  padding: 3px 8px;
  max-width: 100%;
  border-radius: 0px;
  font-size: 10px;
}
.catalog-itm-label-right {
  position: absolute;
  right: 8px;
  top: 8px;
  max-width: 45px;
  height: auto;
  z-index: 1;
}
.catalog-itm-label.fill {
  color: #fff;
  background: var(--accent-color);
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  padding: 5px 8px;
  letter-spacing: -0.04em;
}
.catalog-itm-label__name {
  display: inline-block;
}
.catalog-itm-label__name-ico {
  position: absolute;
  width: 13px;
  top: 50%;
  transform: translateY(-50%);
}
.catalog-itm-label__name-ico img, .catalog-itm-label__name-ico .svg {
  height: 14px;
  width: auto;
}
.catalog-itm-label__name-ico + .catalog-itm-label__name {
  margin-left: 14px;
}
.catalog-itm__top {
  position: relative;
  padding-bottom: 100%;
  color: inherit;
  display: block;
  text-decoration: none;
  background-color: var(--light-gray-color);
}
.catalog-itm__top__link {
  text-decoration: none;
  color: inherit;
}
.catalog-itm__top__3d {
  position: absolute;
  right: 8px;
  top: 8px;
}
.catalog-itm__top__3d .svg {
  width: 31px;
  height: auto;
}
.catalog-itm__top__3d__placeholder {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-left: 3px;
  z-index: 3;
  padding: 4px 8px;
  background: #000;
  font-size: 10px;
  line-height: 13px;
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.2s;
}
.catalog-itm__top__3d:hover .catalog-itm__top__3d__placeholder {
  opacity: 1;
  transform: translateX(0px);
}
.catalog-itm__top__actions {
  position: absolute;
  left: 8px;
  bottom: 8px;
}
.catalog-itm__top__actions__btn {
  position: relative;
  width: 26px;
  height: 26px;
  background: #FFFFFF;
  border-radius: 0px;
  cursor: pointer;
}
.catalog-itm__top__actions__btn:not(:last-child) {
  margin-bottom: 8px;
}
.catalog-itm__top__actions__btn .svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 14px;
  width: auto;
}
.catalog-itm__top__actions__btn.active, .catalog-itm__top__actions__btn:hover {
  background: var(--accent-color);
  opacity: 1;
}
.catalog-itm__top__actions__btn.active rect, .catalog-itm__top__actions__btn.active path, .catalog-itm__top__actions__btn:hover rect, .catalog-itm__top__actions__btn:hover path {
  fill: #fff;
}
.catalog-itm__top__actions__btn.active.js-add-fav rect, .catalog-itm__top__actions__btn.active.js-add-fav path, .catalog-itm__top__actions__btn:hover.js-add-fav rect, .catalog-itm__top__actions__btn:hover.js-add-fav path {
  fill: var(--accent-color);
  stroke: #fff;
}
.catalog-itm-slider-wrap {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
}
.catalog-itm-slider {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  overflow: hidden;
}
.catalog-itm-slider .owl-stage-outer {
  width: 100%;
  height: 100%;
}
.catalog-itm-slider .owl-stage {
  height: 100%;
}
.catalog-itm-slider .b_slider_wrap {
  width: 100%;
  height: 100%;
}
.catalog-itm-slider .slider_list {
  height: 100%;
  display: flex;
  transition: all ease 0.3s;
}
.catalog-itm-slider__itm {
  width: 100%;
  height: 100%;
  padding-bottom: 0;
}
.catalog-itm-slider .owl-dots {
  position: absolute;
  right: 7px;
  bottom: 7px;
  display: flex;
}
.catalog-itm-slider .owl-dot {
  width: 6px;
  height: 6px;
  margin: 0 4px;
  background: #fff;
  display: inline-block;
}
.catalog-itm-slider .owl-dot.active {
  background: var(--accent-color);
  width: 6px;
}
.catalog-itm-rating {
  position: absolute;
  left: 8px;
  bottom: 8px;
  display: flex;
}
.catalog-itm-rating__num {
  font-size: 10px;
  color: #fff;
  font-weight: 500;
  text-decoration: underline;
  margin-top: -7px;
}
.catalog-itm__bot {
  background: var(--gray-light);
  padding: 16px 8px 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.catalog-itm__bot .btn-line {
  margin-top: 4px;
}
.catalog-itm__name {
  font-size: 1rem;
  color: #000;
  margin-bottom: 8px;
  display: block;
  text-decoration: none;
}
.catalog-itm__name:hover {
  color: var(--accent-color);
}
.catalog-itm__cat {
  font-size: var(--small);
  margin-bottom: 16px;
}
.catalog-itm__attr {
  font-size: var(--small);
  margin-bottom: 4px;
}
.catalog-itm-prices {
  margin-top: 24px;
  margin-bottom: 15px;
}
.catalog-itm-prices__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.catalog-itm-prices__text {
  font-size: 12px;
  line-height: 83%;
  letter-spacing: 0.02em;
  margin-right: 3px;
}
.catalog-itm-prices__navs {
  display: flex;
}
.catalog-itm-prices__nav {
  padding: 8px;
  background: #FFFFFF;
  border-radius: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 83%;
  letter-spacing: 0.02em;
  cursor: pointer;
  flex-shrink: 0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
}
.catalog-itm-prices__nav:not(:last-child) {
  margin-right: 8px;
  padding: 6px 8px;
}
.catalog-itm-prices__nav.active, .catalog-itm-prices__nav:hover {
  background: var(--second-color);
  color: #fff;
}
.catalog-itm-prices__nav.active {
  cursor: auto;
}
.catalog-itm-prices__contents {
  display: inline-block;
  vertical-align: middle;
}
.catalog-itm-prices__content {
  display: none;
}
.catalog-itm-prices__content.active {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.catalog-itm-prices__content .price {
  font-size: 20px;
  line-height: 28px;
  font-feature-settings: "pnum" on, "lnum" on;
}
.catalog-itm-prices__content .price.old {
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  margin-right: 8px;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 2px;
  z-index: 1;
}
.catalog-itm-prices__content .price.old:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 3px;
  margin-top: -1.5px;
  background: var(--accent-color);
  z-index: -1;
}
.catalog-itm-prices__content .price--dealer {
  margin-left: 1rem;
}
.catalog-itm-buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.catalog-itm-buttons .btn__action {
  box-sizing: border-box;
  margin-top: 4px;
  color: var(--gray-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border: none;
  font-weight: 500;
  border-radius: 8px;
}
.catalog-itm-buttons .btn__action svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--gray-dark);
  transition: all 0.2s;
}
.catalog-itm-buttons .btn__action:hover {
  background: var(--second-color);
  color: #fff;
}
.catalog-itm-buttons .btn__action:hover svg {
  fill: #fff;
}
.catalog-itm .add-cart-btn {
  gap: 4px;
}
.catalog-itm .add-cart-btn .svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--gray-dark);
  transition: all 0.2s;
}
.catalog-itm .add-cart-btn rect {
  stroke: var(--gray-dark);
}
.catalog-itm .add-cart-btn path {
  fill: var(--gray-dark);
}
.catalog-itm .add-cart-btn:hover .svg {
  fill: #fff;
}
.catalog-itm .add-cart-btn:hover rect {
  stroke: #fff;
}
.catalog-itm .add-cart-btn:hover path {
  fill: #fff;
}

.itm-label-1 {
  top: 8px;
}
.itm-label-2 {
  top: 28px;
}
.itm-label-3 {
  top: 48px;
}
.itm-label-4 {
  top: 68px;
}
.itm-label-sale {
  background: var(--sale-color);
  color: var(--light-color);
}
.itm-label-sale svg {
  color: var(--light-color);
  fill: var(--light-color);
}
.itm-label-promotion {
  background: var(--promotion-color);
}
.itm-label-promotion svg {
  color: var(--light-color);
}
.itm-label-markdown {
  background: var(--markdown-color);
  color: var(--light-color);
}
.itm-label-markdown svg {
  fill: var(--light-color);
}

.add-cart-block .add-cart-counter {
  display: none;
}
.add-cart-block.active .add-cart-btn {
  display: none;
}
.add-cart-block.active .add-cart-counter {
  display: flex;
}

.add-cart-counter {
  position: relative;
  display: flex;
  align-items: center;
}
.add-cart-counter__desc {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--gray-dark);
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
}
.add-cart-counter__btn {
  position: relative;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 0px;
  flex-shrink: 0;
  cursor: pointer;
  background: #fff;
}
.add-cart-counter__btn:hover {
  background: var(--second-color);
}
.add-cart-counter__btn:hover:before, .add-cart-counter__btn:hover:after {
  background: #fff !important;
}
.add-cart-counter__btn:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 3px;
  background: var(--gray-dark);
}
.add-cart-counter__btn.right:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 10px;
  width: 3px;
  background: var(--gray-dark);
}
.add-cart-counter__val {
  flex: 1;
  width: calc(100% - 96px);
  box-sizing: border-box;
  height: 40px;
  border: 2px solid #CACDD2;
  border-radius: 0px;
  background: #fff;
  margin: 0 8px;
  text-align: center;
  padding: 0 8px;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.add-cart-btn {
  box-sizing: border-box;
  margin: 0;
  color: var(--gray-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  height: 40px;
  line-height: 40px;
  border: none;
  font-weight: 500;
}
.add-cart-btn .svg {
  margin-right: 8px;
}
.add-cart-btn:hover {
  background: var(--second-color);
  color: #fff;
}
.add-cart-btn:hover rect {
  stroke: #fff;
}
.add-cart-btn.btn, .add-cart-btn.ais-RefinementList-showMore {
  background: var(--second-color);
  color: #fff;
  gap: 6px;
}
.add-cart-btn.btn rect, .add-cart-btn.ais-RefinementList-showMore rect {
  stroke: #fff;
}
.add-cart-btn.btn:hover, .add-cart-btn.ais-RefinementList-showMore:hover {
  background: #fff;
  color: var(--second-color);
}
.add-cart-btn.btn:hover rect, .add-cart-btn.ais-RefinementList-showMore:hover rect {
  stroke: var(--second-color);
}
.add-cart-btn.btn:hover path, .add-cart-btn.ais-RefinementList-showMore:hover path {
  fill: var(--second-color);
}

.catalog-spetc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
}

.catalog-spetc-tab {
  display: flex;
  gap: 0.5rem;
}
.catalog-spetc-tab__btn {
  background: #fff;
  border: none;
  height: 3rem;
  padding: 0.75rem;
  cursor: pointer;
  border-radius: 0.5rem;
  display: flex;
  gap: 4px;
  align-items: center;
  color: inherit;
}
.catalog-spetc-tab__btn.active {
  pointer-events: none;
  background: var(--accent-color);
  color: #fff;
}
.catalog-spetc-tab__btn.active svg {
  fill: var(--light-color);
}
.catalog-spetc-tab__btn svg {
  width: 24px;
  fill: var(--gray-mid);
}
@media screen and (min-width: 900px) {
  .catalog-spetc-tab__btn:hover {
    background: var(--gray-light);
  }
}

.page__body.expanded .decor__flex {
  width: calc(100% - 246px);
}

.decor {
  background-color: var(--main-bg);
}
.decor__flex {
  width: calc(100% - 104px);
  display: flex;
  flex-direction: column;
  padding-left: 16px;
}
.decor__container {
  padding: 0 16px 0 0;
  display: flex;
}
.decor__title {
  margin: 0;
  padding-bottom: 24px;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: var(--extra-dark-color);
}
.decor__title--small {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.decor__title--mod {
  padding-bottom: 42px;
}
.decor__title--indent {
  margin-left: 16px;
}

@media (max-width: 1024px) {
  .page__body .decor__flex {
    width: 100%;
  }
}
@media (max-width: 658px) {
  .decor section {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.decor-card {
  margin-bottom: 24px;
  padding: 16px 0 16px 0;
}
.decor-card__container {
  padding: 0 16px 0 16px;
}
.decor-card__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 46px;
}
.decor-card__middle {
  display: flex;
  align-items: flex-end;
  margin-bottom: 32px;
  gap: 20px 0;
  flex-wrap: wrap;
  justify-content: space-between;
}
.decor-card__name {
  display: flex;
  flex-direction: column;
}
.decor-card__title {
  margin: 0;
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--extra-dark-color);
}
.decor-card__product-vendor-code {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--dark-color);
}
.decor-card__fun {
  display: flex;
  height: 56px;
}
.decor-card__fun-item:not(:last-child) {
  margin-right: 4px;
}
.decor-card__fun-item:first-child {
  margin-right: 6px;
}
.decor-card__type-wrap {
  max-width: 56px;
  flex-shrink: 0;
}
.decor-card__type {
  position: relative;
  height: 56px;
  margin-bottom: 4px;
}
.decor-card__type .ramka-svg {
  width: 56px;
  height: 56px;
  fill: #dfe3eb;
}
.decor-card__type .zamkoviy-svg {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: var(--main-svg-color);
}
.decor-card__type-name {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: var(--extra-dark-color);
}
.decor-card__type--collection {
  background-color: var(--main-bg);
}
.decor-card__drop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--extra-dark-color);
  background-color: var(--main-bg);
}
.decor-card__size {
  height: 56px;
}
.decor-card__size-item {
  width: 40px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: var(--extra-dark-color);
  background-color: var(--main-bg);
}
.decor-card__size-item:not(:last-child) {
  margin-bottom: 4px;
}
.decor-card__size-item--active {
  color: var(--light-color);
  background-color: var(--extra-bg);
}
.decor-card__visual {
  display: flex;
  align-items: center;
  justify-content: center;
}
.decor-card__visual svg {
  width: 40px;
  height: 40px;
  fill: #0d111a;
}
.decor-card__visual-link {
  display: block;
  padding: 5px 20px 5px 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--extra-dark-color);
}
.decor-card__visual-name {
  max-width: 98px;
  background-color: var(--main-bg);
}
.decor-card__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--dark-color);
}
.decor-card__img {
  margin-right: 24px;
}
.decor-card__stock {
  max-width: 198px;
  margin-right: 76px;
}
.decor-card__text {
  margin: 0;
}
.decor-card__packing {
  max-width: 158px;
  margin-right: 98px;
}
.decor-card__prices {
  display: flex;
  flex-direction: column;
}
.decor-card__price {
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: var(--dark-color);
}
.decor-card__price--dealer {
  color: var(--accent-color);
}
.decor-card__p-price {
  margin: 0;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: var(--dark-color);
  font-family: "Stapel";
}
.decor-card__p-price span {
  font-weight: 500;
}
.decor-card__p-price-text {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4e5359;
  font-family: "Stapel";
}

.all-decor__title {
  margin: 0;
  padding-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--extra-dark-color);
}
.all-decor__list {
  display: flex;
  flex-wrap: wrap;
  line-height: 0;
  gap: 20px 0;
}
.all-decor__item {
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  border: 2px solid transparent;
  transition: 0.2s;
}
.all-decor__item:hover {
  border: 2px solid var(--accent-color);
  transition: 0.2s;
}
.all-decor__item:hover .all-decor__popup {
  opacity: 1;
  transform: translateY(0px);
  pointer-events: auto;
}
.all-decor__item:not(:last-child) {
  margin-right: 24px;
}
.all-decor__popup {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: 13px;
  width: 136px;
  padding: 8px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
  transform: translateY(13px);
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  z-index: 100;
  background: #fff;
}
.all-decor__popup-img {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-position: center;
  background-size: cover;
  margin-bottom: 8px;
}
.all-decor__popup-name {
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: #4e5359;
}

@media (max-width: 1440px) {
  .decor-card__stock {
    margin-right: 30px;
  }
  .decor-card__packing {
    margin-right: 30px;
  }
  .decor-nav-wrap__right {
    flex-shrink: 0;
  }
}
@media (max-width: 1200px) {
  .decor-card__visual {
    margin-left: 20px;
  }
  .decor-card__stock {
    width: 200px;
    flex-shrink: 0;
    margin-right: 0;
    align-self: center;
  }
  .decor-card__prices {
    width: 200px;
    flex-shrink: 0;
    margin-top: 20px;
  }
}
@media (max-width: 800px) {
  .decor-card__top {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .decor-card__name {
    width: calc(100% - 200px);
  }
  .decor-card__fun, .decor-card__visual {
    width: 200px;
    justify-content: flex-start;
  }
  .decor-card__fun {
    margin-bottom: 40px;
  }
}
@media (max-width: 658px) {
  .decor-card__container {
    overflow: hidden;
  }
  .decor-card__top {
    display: block;
  }
  .decor-card__name {
    width: 100%;
    margin-bottom: 20px;
  }
  .decor-card__fun {
    display: inline-flex;
    width: auto;
    margin-right: 25px;
    margin-bottom: 0;
  }
  .decor-card__visual {
    display: inline-flex;
    width: auto;
    margin-left: 0;
  }
}
.decor-nav-wrap {
  position: sticky;
  top: 90px;
  display: grid;
  align-items: center;
  margin-bottom: 24px;
  z-index: 100;
  grid-template-columns: 1fr 325px;
}
.decor-nav-wrap__left {
  margin-right: 16px;
  padding: 24px 16px;
  flex-grow: 1;
  border-radius: 8px;
  background-color: var(--light-color);
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  overflow: auto;
}
.decor-nav-wrap__right {
  position: relative;
  max-width: 325px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 16px 16px 16px 20px;
  border-radius: 8px;
  background-color: var(--light-color);
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
}
.decor-nav__list {
  display: flex;
  gap: 20px 0;
  white-space: nowrap;
}
.decor-nav__item {
  display: inline-block;
}
.decor-nav__item::after {
  width: 100%;
  display: block;
  content: "";
  margin-top: 10px;
  height: 2px;
  color: var(--more-dark-color);
  background-color: transparent;
}
.decor-nav__item--active::after {
  background-color: var(--accent-color);
}
.decor-nav__link {
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--more-dark-color);
}
.decor-nav__popup {
  display: flex;
  align-items: center;
}
.decor-nav__popup-menu {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 658px) {
  .decor-nav-wrap {
    display: block;
    top: 0;
    margin-bottom: 20px;
  }
  .decor-nav-wrap__left {
    position: sticky;
    top: 100px;
    margin-right: 0;
    z-index: 2;
  }
  .decor-nav-wrap__right {
    margin-top: 20px;
    height: auto;
    max-width: 100%;
  }
}
.decor-spec {
  margin-bottom: 24px;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  background-color: var(--light-color);
}
.decor-spec__container {
  padding: 0 16px;
}
.decor-spec__content {
  display: flex;
  overflow: hidden;
}
.decor-spec__left {
  max-width: 810px;
  width: 50%;
  margin-right: 48px;
  display: flex;
  flex-direction: column;
}
.decor-spec__right {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  overflow-y: scroll;
  overflow: overlay;
}
.decor-spec__right::-webkit-scrollbar {
  width: 8px;
}
.decor-spec__right::-webkit-scrollbar-track {
  background: transparent;
}
.decor-spec__right::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--light-color);
  background: rgba(255, 255, 255, 0.8);
}
.decor-spec__image {
  flex: 50%;
  max-width: 50%;
  padding: 10px;
}
.decor-spec__image:not(:last-child) {
  margin-bottom: 5px;
}
.decor-spec__image_full {
  flex: 100%;
  max-width: 100%;
  padding: 10px;
}
.decor-spec__cols {
  max-width: 790px;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.decor-spec__cols:not(:last-child) {
  margin-bottom: 64px;
}
.decor-spec__col {
  flex-grow: 1;
}
.decor-spec__col:not(:last-child) {
  margin-right: 62px;
}
.decor-spec__line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--line-color);
}
.decor-spec-main__prop {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-color);
}
.decor-spec-main__value {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--dark-color);
}
.decor-spec-extra__line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--line-color);
}
.decor-spec-extra__prop {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--dark-color);
}
.decor-spec-extra__value {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--dark-color);
}
.decor-spec__tooltip {
  margin-left: 10px;
}

@media (max-width: 1300px) {
  .decor-spec__cols {
    display: block;
  }
  .decor-spec__col:not(:last-child) {
    margin-right: 0;
  }
}
@media (max-width: 1200px) {
  .decor-spec__image {
    flex: 100%;
    max-width: 100%;
  }
}
@media (max-width: 658px) {
  .decor-spec__content {
    display: block;
  }
  .decor-spec__left {
    width: 100%;
  }
  .decor-spec__right {
    width: 100%;
    max-height: 350px;
  }
  .decor-spec__image {
    flex: 100%;
    max-width: 100%;
  }
}
.decor-info {
  margin-bottom: 24px;
  padding: 24px 0;
}
.decor-info__container {
  padding: 0 16px;
}
.decor-info__list {
  display: flex;
  flex-wrap: wrap;
}
.decor-info__item:not(:last-child) {
  margin-right: 16px;
}
.decor-info__link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--dark-color);
  transition: all 0.3s ease;
}
.decor-info__link svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  fill: var(--main-svg-color);
  transition: all 0.3s ease;
}
.decor-info__link:hover {
  color: var(--accent-color);
  background-color: var(--main-bg);
}
.decor-info__link:hover svg {
  fill: var(--accent-color);
}

.decor-collection {
  margin-bottom: 24px;
  padding: 24px 0 40px 0;
}
.decor-collection__container {
  padding: 0 20px 0 24px;
}
.decor-collection__wrapper {
  display: flex;
}
.decor-collection__top {
  display: flex;
  flex-direction: column;
}
.decor-collection__logo {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  line-height: 0;
}
.decor-collection__flag {
  margin-right: 8px;
}
.decor-collection__brand {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-color);
}
.decor-collection__row {
  margin-bottom: 70px;
  display: flex;
}
.decor-collection__title {
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--extra-dark-color);
}
.decor-collection__subtitle {
  margin: 0;
  padding-bottom: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-color);
}
.decor-collection__inf {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.decor-collection__inf-wrap {
  display: flex;
  margin-left: 170px;
  flex-grow: 1;
}
.decor-collection__inf-wrap:not(:last-child) {
  margin-right: 24px;
}
.decor-collection__inf::after {
  width: 100%;
  display: block;
  flex-shrink: 0;
  content: "";
  height: 4px;
  background-color: var(--divider-color);
}
.decor-collection__inf-item {
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
}
.decor-collection__inf:not(:last-child) {
  margin-right: 24px;
}
.decor-collection__funs {
  margin-right: 245px;
  flex-shrink: 0;
}
.decor-collection__fun {
  display: flex;
  flex-direction: column;
}
.decor-collection__fun:not(:last-child) {
  margin-bottom: 16px;
}
.decor-collection__fun-wrap {
  display: flex;
}
.decor-collection__type {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--main-bg);
}
.decor-collection__type-wrap {
  display: flex;
  flex-direction: column;
}
.decor-collection__type-name {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: var(--extra-dark-color);
}
.decor-collection__type svg {
  width: 32px;
  height: 32px;
  fill: var(--extra-bg);
}
.decor-collection__type-item:not(:last-child) {
  margin-right: 4px;
}
.decor-collection__drop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border: 2px solid var(--main-bg);
}
.decor-collection__size {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.decor-collection__size-item:not(:last-child) {
  margin-bottom: 4px;
}
.decor-collection__size-num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 26px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  background-color: var(--main-bg);
}
.decor-collection__size-num--active {
  color: var(--light-color);
  background-color: var(--main-svg-color) !important;
}
.decor-collection__adv {
  max-width: 198px;
  width: 100%;
  display: flex;
  align-items: center;
}
.decor-collection__adv-text {
  margin: 0;
  padding-left: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--dark-color);
}
.decor-collection__adv-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 92px;
  row-gap: 32px;
}
.decor-collection__svgs {
  position: relative;
  width: 56px;
  height: 56px;
  flex-shrink: 0;
}
.decor-collection__svgs .adv-ramka {
  position: absolute;
  width: 56px;
  height: 56px;
  fill: var(--main-svg-color);
}
.decor-collection__svgs .adv-craft {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  fill: var(--main-svg-color);
  transform: translate(-50%, -50%);
}
.decor-collection__number {
  display: block;
  margin-right: 4px;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--extra-dark-color);
}
.decor-collection__cur {
  display: block;
  margin-right: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--extra-dark-color);
}
.decor-collection__price {
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--extra-dark-color);
}
.decor-collection__text {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4e5359;
}

@media (max-width: 1300px) {
  .decor-collection__wrapper {
    display: block;
  }
  .decor-collection__inf-wrap {
    margin-left: 30px;
  }
  .decor-collection__subtitle {
    width: 100%;
  }
  .decor-collection__prices {
    align-self: flex-end;
  }
  .decor-collection__price {
    font-size: 22px;
    line-height: 1.2;
  }
  .decor-collection__number {
    font-size: 22px;
  }
  .decor-collection__cur {
    font-size: 14px;
  }
  .decor-collection__text {
    font-size: 12px;
    line-height: 1.2;
  }
  .decor-collection__funs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    margin-right: 0;
  }
  .decor-collection__fun:not(:last-child) {
    margin-right: 40px;
  }
}
@media (max-width: 658px) {
  .decor-collection__row {
    display: block;
    margin-bottom: 40px;
  }
  .decor-collection__inf-wrap {
    margin-left: 0;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .decor-collection__funs {
    display: block;
  }
  .decor-collection__adv-wrap {
    display: block;
  }
  .decor-collection__adv:not(:last-child) {
    margin-bottom: 30px;
  }
}
.decor-brand {
  margin-bottom: 24px;
  padding: 24px 0 16px 0;
}
.decor-brand__container {
  padding: 0 24px 0 16px;
}
.decor-brand__image {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 194px;
  width: 100%;
  height: 194px;
  border-radius: 8px;
  background-color: var(--main-bg);
}
.decor-brand__content {
  display: flex;
}
.decor-brand__descr {
  max-width: 1022px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 24px;
}
.decor-brand__text {
  margin: 0;
  margin-bottom: 16px;
}
.decor-brand__link {
  margin-bottom: 52px;
  color: var(--accent-color);
}

@media (max-width: 658px) {
  .decor-brand__image {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
  }
  .decor-brand__image img {
    width: 80%;
    margin: auto;
    display: block;
  }
}
.decor-default {
  margin-bottom: 24px;
  padding: 24px 0 16px 0;
}
.decor-default__container {
  padding: 0 16px;
}

.decor-table {
  margin-bottom: 24px;
  padding: 24px 0 16px 0;
}
.decor-table__container {
  padding: 0;
}

.t-decor {
  padding-top: 16px;
  padding-bottom: 16px;
}
.t-decor.collapse {
  width: 1108px;
}
.t-decor__outer {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
}
.t-decor__outer .dataTables_wrapper {
  margin-bottom: 0;
}
.t-decor__outer .dataTables_paginate {
  padding: 0;
  padding-top: 1rem;
}
.t-decor__outer th {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  padding: 17.5px 16px;
  color: #334466;
  background-color: var(--light-color);
  border-bottom: 1.2px solid #c3cad9;
}
.t-decor__outer td {
  padding: 13.5px 16px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #202b40;
  border-bottom: 1.2px solid #edeff2;
}
.t-decor__outer td:nth-child(4) {
  position: relative;
}
.t-decor__outer td:nth-child(4):hover .popup-preview {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  pointer-events: auto;
}
.t-decor__outer th:nth-child(1),
.t-decor__outer td:nth-child(1) {
  width: 72px;
}
.t-decor__outer th:nth-child(2),
.t-decor__outer td:nth-child(2) {
  width: 135px;
}
.t-decor__outer th:nth-child(3),
.t-decor__outer td:nth-child(3) {
  width: 138px;
}
.t-decor__outer th:nth-child(4),
.t-decor__outer td:nth-child(4) {
  width: 354px;
}
.t-decor__outer th:nth-child(5),
.t-decor__outer td:nth-child(5) {
  width: 168px;
}
.t-decor__outer th:nth-child(6),
.t-decor__outer td:nth-child(6) {
  width: 168px;
}
.t-decor__outer th:nth-child(7),
.t-decor__outer td:nth-child(7) {
  width: 150px;
}
.t-decor__outer td:nth-last-child(1),
.t-decor__outer th:nth-last-child(1) {
  width: 130px;
}
.t-decor__outer td:nth-last-child(1) {
  padding: 10px;
}
.t-decor__inner {
  width: 100%;
  border-collapse: collapse;
}
.t-decor__row:nth-child(-n+4) .t-decor__cell:nth-child(4).t-decor__cell:hover .popup-preview {
  top: 0;
}
@media (max-width: 576px) {
  .t-decor__row:nth-child(-n+4) .t-decor__cell:nth-child(4).t-decor__cell:hover .popup-preview {
    top: 0px;
    left: -100px;
  }
}
.t-decor__row:nth-child(-n+4) .popup-preview {
  top: 0px;
}
.t-decor__row:nth-child(n+5) .t-decor__cell:nth-child(4).t-decor__cell:hover .popup-preview {
  top: -156px;
  left: 10px;
}
@media (max-width: 576px) {
  .t-decor__row:nth-child(n+5) .t-decor__cell:nth-child(4).t-decor__cell:hover .popup-preview {
    top: 0;
    left: -100px;
  }
}
.t-decor__row {
  background-color: var(--light-color);
}
.t-decor__row:hover {
  background-color: var(--t-select-row-color);
}
.t-decor-menu {
  display: flex;
}
.t-decor-menu__item {
  display: block;
  width: 24px;
  height: 26px;
}
.t-decor-menu__item:not(:last-child) {
  margin-right: 8px;
}
.t-decor__header--name {
  z-index: 20;
}
.t-decor-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 658px) {
  .t-decor__outer {
    overflow: auto;
  }
}
.spec-tooltip {
  display: block;
  position: relative;
  top: 0;
  left: 0;
}
.spec-tooltip__txt {
  position: absolute;
  left: -35%;
  bottom: calc(100% + 10px);
  border-radius: 4px;
  padding: 4px;
  width: max-content;
  max-width: 370px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  color: var(--light-color);
  background: var(--extra-bg);
  opacity: 0;
  visibility: hidden;
  transform: translateY(5px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.spec-tooltip__txt svg {
  position: absolute;
  top: 18px;
  left: 20px;
  width: 12px;
  height: 8px;
  fill: var(--extra-bg);
}
.spec-tooltip__txt .q-tooltip--center {
  top: 18px;
  left: 50%;
  transform: translateX(-50%);
}
.spec-tooltip .btn__spec-tooltip:hover {
  cursor: pointer;
}
.spec-tooltip .btn__spec-tooltip:hover + .spec-tooltip__txt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.spec-tooltip .btn__spec-tooltip:focus + .spec-tooltip__txt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.spec-tooltip .btn__spec-tooltip svg {
  width: 16px;
  height: 16px;
  fill: var(--extra-svg-color);
  margin-left: 8px;
}

.popup-decor {
  position: absolute;
  left: 92px;
  top: 76px;
  border-radius: 4px;
  width: 208px;
  max-width: 456px;
  padding: 8px;
  box-shadow: 4px 8px 20px rgba(0, 13, 84, 0.2);
  font-family: var(--extra-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-transform: initial;
  color: var(--dark-color);
  background-color: var(--light-color);
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.1);
  border-radius: 8px;
  z-index: 3;
}
@media (max-width: 576px) {
  .popup-decor {
    top: 30px;
  }
}
.popup-decor__item {
  padding: 4px 8px;
}
.popup-decor__item:hover {
  border-radius: 8px;
  background-color: var(--bg-btn-action);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 576px) {
  .popup-decor {
    top: 34px;
    left: -178px;
  }
}
.popup-decor svg {
  width: 16px;
  height: 16px;
}
.popup-decor--active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.popup-decor__title {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.03em;
}
.popup-decor__title:not(:last-child) {
  margin-bottom: 8px;
}
.popup-decor__link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  color: #334466;
}
.popup-decor__link:hover, .popup-decor__link:focus {
  color: var(--accent-color);
}
.popup-decor__icon {
  width: 24px;
  height: 26px;
  padding: 4px 5px;
  margin-right: 4px;
}

@media (max-width: 658px) {
  .popup-decor {
    left: auto;
    right: 0;
    top: 100%;
    margin-top: 10px;
  }
}
.collection {
  background-color: var(--main-bg);
}
.collection__flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}
.collection__container {
  padding: 0;
  display: flex;
}
.collection__title {
  margin: 0;
  padding-bottom: 24px;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: var(--extra-dark-color);
}
.collection__title--small {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.collection__title--indent {
  margin-left: 16px;
}

@media (max-width: 658px) {
  .collection__flex section {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.collection-main {
  margin-bottom: 24px;
  padding: 24px 0 40px 0;
}
.collection-main__container {
  padding: 0 24px 0 16px;
}
.collection-main__wrapper {
  display: flex;
}
.collection-main__top {
  display: flex;
  flex-direction: column;
}
.collection-main__logo {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  line-height: 0;
}
.collection-main__flag {
  margin-right: 8px;
}
.collection-main__brand {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-color);
}
.collection-main__row {
  margin-bottom: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.collection-main__title {
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--extra-dark-color);
}
.collection-main__subtitle {
  margin: 0;
  padding-bottom: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-color);
}
.collection-main__inf {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.collection-main__inf-wrap {
  display: flex;
  margin-left: 170px;
  flex-grow: 1;
}
.collection-main__inf-wrap:not(:last-child) {
  margin-right: 24px;
}
.collection-main__inf::after {
  width: 100%;
  display: block;
  flex-shrink: 0;
  content: "";
  height: 4px;
  background-color: var(--divider-color);
}
.collection-main__inf-item {
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
}
.collection-main__inf:not(:last-child) {
  margin-right: 24px;
}
.collection-main__funs {
  margin-right: 245px;
  flex-shrink: 0;
}
.collection-main__fun {
  display: flex;
  flex-direction: column;
}
.collection-main__fun:not(:last-child) {
  margin-bottom: 16px;
}
.collection-main__fun-wrap {
  display: flex;
  margin-bottom: 4px;
}
.collection-main__type {
  position: relative;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.collection-main__type-wrap {
  display: flex;
  flex-direction: column;
}
.collection-main__type-name {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: var(--extra-dark-color);
}
.collection-main__type .collection-ramka {
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  fill: var(--extra-svg-color);
}
.collection-main__type .collection-craft {
  width: 32px;
  height: 32px;
  fill: var(--extra-bg);
}
.collection-main__type-item:not(:last-child) {
  margin-right: 4px;
}
.collection-main__drop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: #fff;
  font-size: var(--smallest);
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: var(--text);
  border: 2px solid #F7F8FA;
}
.collection-main__size {
  align-self: flex-end;
}
.collection-main__size-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 26px;
  background-color: var(--main-bg);
}
.collection-main__size-item:not(:last-child) {
  margin-bottom: 4px;
}
.collection-main__size-item--active {
  color: var(--light-color);
  background-color: var(--main-svg-color) !important;
}
.collection-main__adv {
  max-width: 198px;
  width: 100%;
  display: flex;
  align-items: center;
}
.collection-main__adv-text {
  margin: 0;
  padding-left: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--dark-color);
}
.collection-main__adv-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 92px;
  row-gap: 32px;
}
.collection-main__svgs {
  position: relative;
  width: 56px;
  height: 56px;
  flex-shrink: 0;
}
.collection-main__svgs .adv-ramka {
  position: absolute;
  width: 56px;
  height: 56px;
  fill: var(--extra-svg-color);
}
.collection-main__svgs .adv-craft {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  fill: var(--main-svg-color);
  transform: translate(-50%, -50%);
}
.collection-main__number {
  display: block;
  margin-right: 4px;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--extra-dark-color);
}
.collection-main__cur {
  display: block;
  margin-right: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--extra-dark-color);
}
.collection-main__price {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--extra-dark-color);
}
.collection-main__text {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4e5359;
}

@media (max-width: 1440px) {
  .collection-main__funs {
    margin-right: 60px;
  }
  .collection-main__title {
    width: 100%;
    margin-bottom: 15px;
  }
  .collection-main__inf-wrap {
    margin-left: 0;
  }
}
@media (max-width: 1300px) {
  .collection-main__adv-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 658px) {
  .collection-main__inf-wrap {
    margin-bottom: 25px;
  }
  .collection-main__row {
    margin-bottom: 50px;
  }
  .collection-main__wrapper {
    display: block;
  }
  .collection-main__funs {
    margin-right: 0;
    margin-bottom: 50px;
  }
  .collection-main__adv-wrap {
    column-gap: 30px;
  }
}
.collection-decors {
  margin-bottom: 24px;
  padding: 24px 0 74px 0;
}
.collection-decors__container {
  padding: 0 24px 0 16px;
}
.collection-decors-type {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 42px;
  column-gap: 40px;
}
.collection-decors-type__name {
  margin-top: 4px;
}

@media (max-width: 1024px) {
  .collection-decors-type {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 658px) {
  .collection-decors-type {
    display: block;
  }
  .collection-decors-type__item img {
    width: 100%;
  }
  .collection-decors-type__item:not(:last-child) {
    margin-bottom: 20px;
  }
}
.collection-styling {
  margin-bottom: 24px;
  padding: 24px 0 24px 0;
}
.collection-styling__container {
  padding: 0 24px 0 16px;
}
.collection-styling__items {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-template-rows: 100px 100px;
  row-gap: 32px;
  column-gap: 40px;
}
.collection-styling__item {
  border: 2px solid var(--extra-svg-color);
  transition: all 0.3s ease-in-out;
}
.collection-styling__item:hover {
  border: 2px solid var(--accent-color);
}

@media (max-width: 1024px) {
  .collection-styling__items {
    grid-template-rows: auto;
  }
}
@media (max-width: 658px) {
  .collection-styling__container {
    padding: 0 16px;
  }
  .collection-styling__items {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
  }
}
.collection-table {
  margin-bottom: 24px;
  padding: 24px 0 16px 0;
}
.collection-table__container {
  padding: 0;
}

.t-collection {
  padding-top: 16px;
  padding-bottom: 16px;
}
.t-collection.collapse {
  width: 1108px;
}
.t-collection__outer {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  overflow: auto;
}
.t-collection__outer th {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  padding: 17.5px 16px;
  color: #334466;
  background-color: var(--light-color);
  border-bottom: 1.2px solid #c3cad9;
}
.t-collection__outer td {
  padding: 13.5px 16px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #202b40;
  border-bottom: 1.2px solid #edeff2;
}
.t-collection__outer td:nth-child(4) {
  position: relative;
}
.t-collection__outer td:nth-child(4):hover .popup-preview {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  pointer-events: auto;
}
.t-collection__outer th:nth-child(1),
.t-collection__outer td:nth-child(1) {
  width: 72px;
}
.t-collection__outer th:nth-child(2),
.t-collection__outer td:nth-child(2) {
  width: 135px;
}
.t-collection__outer th:nth-child(3),
.t-collection__outer td:nth-child(3) {
  width: 138px;
}
.t-collection__outer th:nth-child(4),
.t-collection__outer td:nth-child(4) {
  width: 354px;
}
.t-collection__outer th:nth-child(5),
.t-collection__outer td:nth-child(5) {
  width: 168px;
}
.t-collection__outer th:nth-child(6),
.t-collection__outer td:nth-child(6) {
  width: 168px;
}
.t-collection__outer th:nth-child(7),
.t-collection__outer td:nth-child(7) {
  width: 150px;
}
.t-collection__outer td:nth-last-child(1),
.t-collection__outer th:nth-last-child(1) {
  width: 130px;
}
.t-collection__outer td:nth-last-child(1) {
  padding: 10px;
}
.t-collection__inner {
  width: 100%;
  border-collapse: collapse;
}
.t-collection__row:nth-child(-n+4) .t-collection__cell:nth-child(4).t-collection__cell:hover .popup-preview {
  top: 0;
}
@media (max-width: 576px) {
  .t-collection__row:nth-child(-n+4) .t-collection__cell:nth-child(4).t-collection__cell:hover .popup-preview {
    top: 0px;
    left: -100px;
  }
}
.t-collection__row:nth-child(-n+4) .popup-preview {
  top: 0px;
}
.t-collection__row:nth-child(n+5) .t-collection__cell:nth-child(4).t-collection__cell:hover .popup-preview {
  top: -156px;
  left: 10px;
}
@media (max-width: 576px) {
  .t-collection__row:nth-child(n+5) .t-collection__cell:nth-child(4).t-collection__cell:hover .popup-preview {
    top: 0;
    left: -100px;
  }
}
.t-collection__row {
  background-color: var(--light-color);
}
.t-collection__row:hover {
  background-color: var(--t-select-row-color);
}
.t-collection-menu {
  display: flex;
}
.t-collection-menu__item {
  display: block;
  width: 24px;
  height: 26px;
}
.t-collection-menu__item:not(:last-child) {
  margin-right: 8px;
}
.t-collection__header--name {
  z-index: 20;
}
.t-collection-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styling-tooltip {
  position: relative;
  display: block;
  top: 0;
  left: 0;
}
.styling-tooltip__txt {
  position: absolute;
  display: block;
  left: -5%;
  bottom: calc(100% + 10px);
  border-radius: 4px;
  padding: 4px;
  width: max-content;
  max-width: 118px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  color: var(--light-color);
  background: var(--extra-bg);
  opacity: 0;
  visibility: hidden;
  transform: translateY(5px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.styling-tooltip__txt svg {
  position: absolute;
  top: 100%;
  margin-top: -1px;
  left: 50%;
  width: 12px;
  height: 8px;
  fill: var(--extra-bg);
  transform: translate(-50%);
}
.styling-tooltip .btn__styling-tooltip:hover {
  cursor: pointer;
}
.styling-tooltip .btn__styling-tooltip:hover + .styling-tooltip__txt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.styling-tooltip .btn__styling-tooltip:focus + .styling-tooltip__txt {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.styling-tooltip .btn__styling-tooltip svg {
  width: 96px;
  height: 96px;
  fill: var(--extra-svg-color);
}

@media (max-width: 1024px) {
  .styling-tooltip__txt {
    position: relative;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    max-width: 100%;
    width: 100%;
    text-align: center;
    left: 0;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
  }
  .styling-tooltip__txt svg {
    display: none;
  }
}
.brand {
  background-color: var(--main-bg);
}
.brand__flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}
.brand__container {
  padding: 0;
  display: flex;
}
.brand__title {
  margin: 0;
  padding-bottom: 16px;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: var(--extra-dark-color);
}
.brand__title--small {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.brand__title--indent {
  margin-left: 16px;
}

.t-catalog__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  flex-wrap: wrap;
  gap: 20px 0;
}

@media (max-width: 1024px) {
  .brand__container {
    padding-right: 0;
  }
  .brand__flex {
    padding-right: 16px;
  }
}
@media (max-width: 658px) {
  .brand__flex section {
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
  }
}
.brand-main {
  padding: 24px 0 16px 0;
  margin-bottom: 24px;
}
.brand-main__container {
  padding: 0 16px 0 16px;
}
.brand-main__content {
  display: flex;
}
.brand-main__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 187px;
  background-color: var(--main-bg);
  flex-shrink: 0;
}
.brand-main__image img {
  display: block;
  margin: auto;
  width: 80%;
}
.brand-main__descr {
  max-width: 1024px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.brand-main__text {
  margin: 0;
}
.brand-main__link {
  color: var(--accent-color);
}
.brand-main__link:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .brand-main__image {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 658px) {
  .brand-main__image {
    width: 90px;
    height: 90px;
  }
}
.brand-statistics {
  padding: 16px 0 16px 0;
  margin-bottom: 24px;
}
.brand-statistics__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 0 24px;
  flex-wrap: wrap;
  gap: 15px 0;
}
.brand-statistics__left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.brand-statistics__item {
  display: flex;
  align-items: center;
}
.brand-statistics__item:not(:last-child) {
  margin-right: 24px;
}
.brand-statistics__num {
  display: block;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: var(--accent-color);
}
.brand-statistics__text {
  display: block;
  margin-left: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-color);
}
.brand-statistics__right {
  display: flex;
  align-items: center;
}
.brand-statistics__image:not(:last-child) {
  margin-right: 8px;
}

.brand-collections {
  padding: 16px 0 24px 0;
  margin-bottom: 24px;
}
.brand-collections__container {
  padding: 0 16px 0 16px;
}
.brand-collections__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
.brand-collections__content > a {
  color: #fff;
}
.brand-collections__top {
  display: flex;
  justify-content: space-between;
}
.brand-collections__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.brand-collections__group-inf {
  display: flex;
}
.brand-collections__countries {
  position: absolute;
  top: 64px;
}
.brand-collections__inf {
  display: flex;
  flex-direction: column;
}
.brand-collections__inf-num {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--light-color);
}
.brand-collections__inf-num--sku {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.brand-collections__inf-item {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: var(--light-color);
}
.brand-collections__sku {
  margin-left: 8px;
  padding-top: 10px;
}
.brand-collections__sku::after {
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--light-color);
}
.brand-collections__item {
  display: block;
  position: relative;
  max-width: 410px;
  height: 242px;
  overflow: hidden;
}
.brand-collections__item:hover {
  cursor: pointer;
}
.brand-collections__item:hover .brand-collections__image {
  transform: scale(1.2);
}
.brand-collections__item::after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.brand-collections__image {
  transition: all 1.2s;
  height: 100%;
  width: 100%;
}
.brand-collections__descr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 242px;
  padding: 16px;
  top: 0;
  left: 0;
  z-index: 100;
}
.brand-collections__descr svg {
  width: 114px;
  height: 40px;
  fill: var(--light-color);
}
.brand-collections__types {
  max-width: 156px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--light-color);
}
.brand-collections__text {
  max-width: 91px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--light-color);
}
.brand-collections__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brand-collections__bottom svg {
  width: 44px;
  height: 44px;
}
.brand-collections__logo-text {
  height: 40px;
  display: flex;
  align-items: center;
  font-style: italic;
  font-weight: 800;
  font-size: var(--h4);
}

@media (max-width: 800px) {
  .brand-collections__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 658px) {
  .brand-collections__content {
    display: block;
  }
  .brand-collections__item {
    max-width: 100%;
  }
  .brand-collections__item:not(:last-child) {
    margin-bottom: 20px;
  }
}
.brand-table {
  margin-bottom: 24px;
  padding-top: 24px;
  padding-bottom: 0;
}
.brand-table__container {
  padding: 0;
}

.t-brand {
  padding-top: 16px;
  padding-bottom: 16px;
}
.t-brand.collapse {
  width: 1108px;
}
.t-brand__outer {
  width: 100%;
  overflow: scroll;
  max-height: 528px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
}
.t-brand__outer th {
  position: sticky;
  top: 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  padding: 17.5px 16px;
  color: #334466;
  background-color: var(--light-color);
  border-bottom: 1.2px solid #c3cad9;
}
.t-brand__outer td {
  padding: 13.5px 16px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #202b40;
  border-bottom: 1.2px solid #edeff2;
}
.t-brand__outer td:nth-child(4) {
  position: relative;
}
.t-brand__outer td:nth-child(4):hover .popup-preview {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  pointer-events: auto;
}
.t-brand__outer th:nth-child(1),
.t-brand__outer td:nth-child(1) {
  width: 72px;
}
.t-brand__outer th:nth-child(2),
.t-brand__outer td:nth-child(2) {
  width: 135px;
}
.t-brand__outer th:nth-child(3),
.t-brand__outer td:nth-child(3) {
  width: 138px;
}
.t-brand__outer th:nth-child(4),
.t-brand__outer td:nth-child(4) {
  width: 354px;
}
.t-brand__outer th:nth-child(5),
.t-brand__outer td:nth-child(5) {
  width: 168px;
}
.t-brand__outer th:nth-child(6),
.t-brand__outer td:nth-child(6) {
  width: 168px;
}
.t-brand__outer th:nth-child(7),
.t-brand__outer td:nth-child(7) {
  width: 150px;
}
.t-brand__outer td:nth-last-child(1),
.t-brand__outer th:nth-last-child(1) {
  width: 130px;
}
.t-brand__outer td:nth-last-child(1) {
  padding: 10px;
}
.t-brand__outer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.t-brand__outer::-webkit-scrollbar-track {
  background: transparent;
}
.t-brand__outer::-webkit-scrollbar-thumb {
  background-color: #c3cad9;
  border-radius: 8px;
}
.t-brand__inner {
  width: 100%;
  border-collapse: collapse;
}
.t-brand__row:nth-child(-n+4) .t-brand__cell:nth-child(4).t-brand__cell:hover .popup-preview {
  top: 0;
}
@media (max-width: 576px) {
  .t-brand__row:nth-child(-n+4) .t-brand__cell:nth-child(4).t-brand__cell:hover .popup-preview {
    top: 0px;
    left: -100px;
  }
}
.t-brand__row:nth-child(-n+4) .popup-preview {
  top: 0px;
}
.t-brand__row:nth-child(n+5) .t-brand__cell:nth-child(4).t-brand__cell:hover .popup-preview {
  top: -156px;
  left: 10px;
}
@media (max-width: 576px) {
  .t-brand__row:nth-child(n+5) .t-brand__cell:nth-child(4).t-brand__cell:hover .popup-preview {
    top: 0;
    left: -100px;
  }
}
.t-brand__row {
  background-color: var(--light-color);
}
.t-brand__row:hover {
  background-color: var(--t-select-row-color);
}
.t-brand-menu {
  display: flex;
}
.t-brand-menu__item {
  display: block;
  width: 24px;
  height: 26px;
}
.t-brand-menu__item:not(:last-child) {
  margin-right: 8px;
}
.t-brand__header--name {
  z-index: 20;
}
.t-brand-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stand-card {
  margin-bottom: 24px;
  padding: 16px 0 16px 0;
}
.stand-card__container {
  padding: 0 16px 0 16px;
}
.stand-card__wrapper {
  display: flex;
}
.stand-card__top {
  display: flex;
  flex-direction: column;
}
.stand-card__middle {
  display: flex;
  margin-bottom: 32px;
  gap: 20px 0;
  flex-wrap: wrap;
  justify-content: space-between;
}
.stand-card__name {
  display: flex;
  flex-direction: column;
}
.stand-card__row {
  margin-bottom: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.stand-card__logo {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  line-height: 0;
}
.stand-card__flag {
  margin-right: 8px;
}
.stand-card__brand {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-color);
}
.stand-card__title {
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--extra-dark-color);
}
.stand-card__inf {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.stand-card__inf-wrap {
  display: flex;
  margin-left: 170px;
  flex-grow: 1;
}
.stand-card__inf-wrap:not(:last-child) {
  margin-right: 24px;
}
.stand-card__inf::after {
  width: 100%;
  display: block;
  flex-shrink: 0;
  content: "";
  height: 4px;
  background-color: var(--divider-color);
}
.stand-card__inf-item {
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
}
.stand-card__inf:not(:last-child) {
  margin-right: 24px;
}
.stand-card__equipment {
  max-width: 300px;
  margin-right: 76px;
}
.stand-card__number {
  display: block;
  margin-right: 4px;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--extra-dark-color);
}
.stand-card__cur {
  display: block;
  margin-right: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--extra-dark-color);
}
.stand-card__prices {
  display: flex;
  flex-direction: column;
}
.stand-card__price {
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: var(--dark-color);
}
.stand-card__price--dealer {
  color: var(--accent-color);
}
.stand-card__p-price {
  margin: 0;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: var(--dark-color);
  font-family: "Stapel";
}
.stand-card__p-price span {
  font-weight: 500;
}
.stand-card__p-price-text {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4e5359;
  font-family: "Stapel";
  margin-bottom: 20px;
}

@media (max-width: 1440px) {
  .stand-card__equipment {
    margin-right: 30px;
  }
  .stand-card__packing {
    margin-right: 30px;
  }
  .stand-nav-wrap__right {
    flex-shrink: 0;
  }
}
@media (max-width: 1200px) {
  .stand-card__visual {
    margin-left: 20px;
  }
  .stand-card__equipment {
    width: 200px;
    flex-shrink: 0;
    margin-right: 0;
    align-self: center;
  }
  .stand-card__prices {
    width: 200px;
    flex-shrink: 0;
    margin-top: 20px;
  }
}
@media (max-width: 800px) {
  .stand-card__top {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .stand-card__name {
    width: calc(100% - 200px);
  }
}
@media (max-width: 658px) {
  .stand-card__container {
    overflow: hidden;
  }
  .stand-card__top {
    display: block;
  }
  .stand-card__name {
    width: 100%;
    margin-bottom: 20px;
  }
}
.auth-page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-modal {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.04);
  border-radius: 8px;
  padding: 30px 55px;
  width: 360px;
  font-family: "Stapel";
}
.auth-modal .btn-main, .auth-modal .ais-RefinementList-showMore, .auth-modal .ais-RangeInput-submit, .auth-modal .btn-second {
  display: block;
  width: 100%;
  margin-bottom: 16px;
}
.auth-modal .h4, .auth-modal .cart-step-head {
  margin-top: 0;
  margin-bottom: 48px;
  text-align: center;
}

.form-button-desc {
  color: var(--gray-mid);
  font-size: 10px;
  text-align: center;
}

.auth-modal-nav {
  display: flex;
  background: #F0F1F2;
  box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.04);
  border-radius: 8px;
  height: 64px;
  padding: 8px;
  margin: 0 0 24px 0;
  gap: 8px;
}
.auth-modal-nav li {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: #4E5359;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  font-family: "Stapel";
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 900px) {
  .auth-modal-nav li:hover {
    background: #fff;
  }
}
.auth-modal-nav li.active {
  background: var(--accent-color);
  color: #fff;
  cursor: auto;
}

.auth-modal-content {
  display: none;
}
.auth-modal-content.active {
  display: block;
}

.auth-modal-error-title {
  display: block;
  text-align: left;
  color: var(--accent-color);
  font-weight: 500;
}

.h4.auth-modal-error-title, .auth-modal-error-title.cart-step-head {
  margin-bottom: 24px;
  text-align: left;
}

.auth-modal-error-desc {
  color: var(--black);
  text-align: left;
  margin: 24px 0;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
}
.auth-modal-error-desc a {
  color: var(--accent-color);
}
@media screen and (min-width: 900px) {
  .auth-modal-error-desc a:hover {
    color: var(--accent-hover-color);
  }
}

.send-code-block {
  margin: 40px 0 16px;
}
.send-code-block.show-btn .send-code-block__time {
  display: none;
}
.send-code-block.show-btn .send-code-block__btn {
  display: block;
}
.send-code-block__time {
  text-align: center;
  color: var(--black);
}
.send-code-block__time span {
  color: var(--accent-color);
}
.send-code-block__btn {
  display: none;
}

.reset-pass-link {
  text-align: center;
  display: block;
  color: var(--gray-dark);
  margin: 35px auto;
  transition: color 0.2s ease-in-out;
}
@media screen and (min-width: 900px) {
  .reset-pass-link:hover {
    color: var(--accent-color);
  }
}

.input-error-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: var(--accent-color);
  margin-top: -20px;
  margin-bottom: 24px;
}

.auth-error {
  color: var(--error-color);
  font-size: var(--smallest);
  margin-top: 5px;
}

.lk-start-title {
  font-size: 60px;
  font-family: "Stapel";
  font-weight: 500;
}

.lk-start-desc {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  width: 480px;
  max-width: 100%;
  margin-top: 40px;
}

.lk-start-padding {
  padding: 0 64px;
}
.lk-start-padding-small {
  padding: 0 40px;
}

.lk-start-first {
  display: flex;
}
.lk-start-first__img {
  width: 250px;
  flex-shrink: 0;
  margin-left: 80px;
}
.lk-start-first__absolute-img {
  position: absolute;
  right: 20px;
  top: 0;
}

.lk-start-slider {
  position: relative;
  display: flex;
  margin-top: 56px;
  margin-bottom: 56px;
}
.lk-start-slider > .lk-start-slider-itm {
  min-width: 100%;
}
.lk-start-slider-itm {
  position: relative;
  padding-bottom: 70px;
  height: 100%;
}
.lk-start-slider-itm__bottom {
  position: absolute;
  bottom: 0;
  left: 64px;
  right: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lk-start-slider-itm__bottom .btn-main, .lk-start-slider-itm__bottom .ais-RefinementList-showMore, .lk-start-slider-itm__bottom .ais-RangeInput-submit, .lk-start-slider-itm__bottom .btn-second {
  width: 295px;
  max-width: 100%;
}
.lk-start-slider .owl-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
}

.lk-start-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  padding-right: 150px;
  margin-top: 40px;
}
.lk-start-grid-itm {
  background: #F7F7F7;
  border-radius: 20px;
  min-height: 150px;
  padding: 26px;
  display: flex;
  align-items: flex-end;
  color: var(--text);
  letter-spacing: 0.02em;
}
.lk-start-grid-itm p {
  margin: 0;
}

.partner-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 80px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.partner-grid-itm {
  text-align: center;
}
.partner-grid-itm img {
  max-width: 100%;
}

@media (max-width: 1024px) {
  .lk-start-title {
    font-size: 40px;
  }
  .lk-start-slider-itm {
    padding-left: 24px;
    padding-right: 24px;
  }
  .lk-start-slider {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .lk-start-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .lk-start-grid-itm {
    min-height: 100px;
  }
}
@media (max-width: 800px) {
  .lk-start-title {
    font-size: 30px;
  }
  .lk-start-desc {
    font-size: 16px;
  }
  .lk-start-slider-itm {
    padding-left: 16px;
    padding-right: 16px;
  }
  .lk-start-first__img {
    margin-left: 0;
  }
  .lk-start-first__absolute-img {
    margin-left: 0;
    width: 290px;
    right: 10px;
    top: -30px;
  }
  .lk-start-slider {
    margin-bottom: 50px;
    margin-left: -16px;
    margin-right: -16px;
  }
  .lk-start-slider .owl-dots {
    bottom: -40px;
  }
  .lk-start-grid {
    margin-bottom: 40px;
  }
  .partner-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 40px;
    gap: 20px 50px;
  }
  .partner-grid img {
    width: 80%;
  }
}
@media (max-width: 576px) {
  .lk-start-slider-itm__bottom {
    left: 16px;
    right: 16px;
  }
  .lk-start-slider-itm__bottom .btn-main, .lk-start-slider-itm__bottom .ais-RefinementList-showMore, .lk-start-slider-itm__bottom .ais-RangeInput-submit, .lk-start-slider-itm__bottom .btn-second {
    padding: 16px 5px;
    font-size: 14px;
  }
  .lk-start-slider-itm__bottom .btn-link {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .lk-start-first {
    display: block;
  }
  .lk-start-first__img {
    width: 190px;
    margin-left: auto;
    margin-top: 20px;
  }
  .lk-start-grid {
    gap: 8px;
    padding-right: 0;
    margin-top: 20px;
  }
  .lk-start-grid-itm {
    font-size: 12px;
    padding: 14px;
  }
  .lk-start-first__absolute-img {
    bottom: 60px;
    top: auto;
    width: 160px;
  }
  .partner-grid {
    padding: 0;
    gap: 20px 10px;
  }
}
.lk-title-margin {
  margin-top: 0;
  margin-bottom: 15px;
}

.lk-bonus-grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 55px;
}
.lk-bonus-grid > .btn-main, .lk-bonus-grid > .ais-RefinementList-showMore, .lk-bonus-grid > .ais-RangeInput-submit, .lk-bonus-grid > .btn-second {
  align-self: center;
}
.lk-bonus-grid-itm {
  background: #FFFFFF;
  border-radius: 8px;
  margin-right: 16px;
  padding: 8px 16px;
  min-height: 111px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 250px;
  max-width: 100%;
}
.lk-bonus-grid-itm .h3, .lk-bonus-grid-itm .bonus-count__value {
  color: var(--text);
  font-weight: 700;
  padding-bottom: 4px;
  border-bottom: 3px solid var(--accent-color);
  display: inline-block;
}
.lk-bonus-grid-itm__desc {
  color: #C1C7CF;
  font-size: 14px;
  font-weight: 500;
  min-height: 34px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.table-bloger {
  background: #fff;
  border-radius: 8px;
}
.table-bloger .dataTables_wrapper {
  overflow: auto;
}
.table-bloger table.dataTable {
  border-collapse: collapse;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--dark-color);
}
.table-bloger table.dataTable td {
  border: 1px solid #EDEFF2;
  text-align: center;
  padding: 16px;
}
.table-bloger table.dataTable td:nth-child(2) {
  text-align: left;
}
.table-bloger table.dataTable td:first-child {
  border-left: none;
}
.table-bloger table.dataTable td:not(:first-child) {
  border-right: none;
  border-left: none;
}
.table-bloger table.dataTable th {
  border-bottom: none !important;
}
.table-bloger table.dataTable.no-footer {
  border-bottom: none;
}
.table-bloger .dataTables_wrapper .dataTables_paginate {
  border-top: none !important;
  position: sticky;
  left: 0;
}
.table-bloger--bonus table.dataTable td:nth-child(2) {
  text-align: center;
}
.table-bloger--bonus table.dataTable td:nth-child(3) {
  text-align: left;
}
.table-bloger--bonus table.dataTable td:last-child {
  text-align: left;
}

.table-small-btn {
  padding: 8px;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  color: var(--accent-color);
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}
@media screen and (min-width: 900px) {
  .table-small-btn:hover {
    background: var(--accent-color);
    color: #fff;
  }
}

.lk-margin-widget {
  margin-bottom: 60px;
}

.referral-block {
  display: flex;
}
.referral-block__left {
  width: 306px;
  flex-shrink: 0;
  max-width: 100%;
  margin-right: 80px;
}
.referral-block__left__desc {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: var(--dark-color);
}
.referral-block__right {
  color: var(--extra-dark-color);
  width: 700px;
  max-width: 100%;
}
.referral-block__right .h3, .referral-block__right .bonus-count__value {
  margin-bottom: 16px;
}

.promo-how-work-itm {
  display: flex;
  align-self: center;
  margin-bottom: 25px;
  color: var(--extra-dark-color);
}
.promo-how-work-itm__title {
  font-weight: 600;
  margin-bottom: 8px;
}
.promo-how-work-itm__text {
  font-size: 14px;
}
.promo-how-work-itm__ico {
  width: 56px;
  flex-shrink: 0;
  margin-right: 16px;
  display: flex;
  align-self: center;
  justify-content: center;
}

.bloger-input {
  position: relative;
  margin-bottom: 16px;
  display: block;
  width: 250px;
}
.bloger-input input {
  height: 48px;
  padding: 0 12px;
  color: var(--dark-color);
  font-family: "Stapel";
  font-size: 14px;
  border: 1px solid #C1C7CF;
  border-radius: 4px;
  width: 100%;
  padding-top: 7px;
  transition: border-color 0.2s ease-in-out;
  outline: none;
  -webkit-appearance: none;
}
.bloger-input input::-webkit-outer-spin-button, .bloger-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bloger-input input:not(:placeholder-shown) ~ .bloger-input__label, .bloger-input input:focus ~ .bloger-input__label {
  transform: translateY(-160%);
}
.bloger-input__label {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  font-size: 10px;
  line-height: 12px;
  color: #728095;
  transition: all 0.2s ease-in-out;
}
.bloger-input__copy {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 8px;
  width: 40px;
  cursor: pointer;
  user-select: none;
}
.bloger-input__copy svg {
  width: 100%;
  height: 100%;
  fill: #C1C7CF;
  transition: fill 0.2s ease-in-out;
}
@media screen and (min-width: 900px) {
  .bloger-input__copy:hover svg {
    fill: #334466;
  }
  .bloger-input__copy:hover ~ input {
    border-color: var(--accent-color);
  }
}

.blogger-bonus-add-line {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
}
.blogger-bonus-add-line .bloger-input {
  margin-bottom: 0;
  flex: 1;
}
.blogger-bonus-add-line .js-select-wrap {
  flex: 1;
}

.blogger-bonus-add {
  display: flex;
  gap: 15px 80px;
}
.blogger-bonus-add__right {
  width: 423px;
  max-width: 45%;
}
.blogger-bonus-add__left {
  width: 45%;
}

.input-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--dark-color);
  margin-bottom: 6px;
}

.input-hint {
  font-size: 10px;
  color: #728095;
  margin-top: 4px;
  font-weight: 600;
}

a.input-hint {
  text-decoration: underline;
  color: var(--accent-color);
}
@media screen and (min-width: 900px) {
  a.input-hint:hover {
    text-decoration: none;
  }
}

.bonus-count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  border-bottom: 1px solid #EDEFF2;
  padding-bottom: 3px;
}
.bonus-count__text {
  font-size: 12px;
  line-height: 18px;
  color: #202B40;
}
.bonus-count__value {
  color: #334466;
  font-weight: 700;
}

@media (max-width: 800px) {
  .blogger-bonus-add {
    display: block;
  }
  .blogger-bonus-add__left {
    width: 100%;
    margin-bottom: 35px;
  }
  .blogger-bonus-add__right {
    width: 100%;
    max-width: 100%;
  }
  .lk-bonus-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  .lk-bonus-grid-itm {
    width: 100%;
    margin-right: 0;
  }
  .referral-block {
    display: block;
  }
  .referral-block__left {
    margin-right: 0;
    margin-bottom: 25px;
  }
}
@media (max-width: 576px) {
  .lk-bonus-grid {
    gap: 8px;
  }
  .lk-bonus-grid-itm {
    padding: 8px;
  }
  .lk-bonus-grid-itm__desc {
    font-size: 12px;
    min-height: 28px;
  }
}
.order-block {
  display: flex;
  flex: 1;
  align-items: flex-start;
  font-family: "Stapel";
}
.order-block__filter {
  position: sticky;
  top: 90px;
  width: 265px;
  flex-shrink: 0;
  margin-right: 24px;
}
.order-block__aside {
  position: sticky;
  top: 90px;
  width: 265px;
  flex-shrink: 0;
}
.order-block__aside ul {
  padding-left: 0;
}
.order-block__table {
  flex: 1;
  padding-bottom: 20px;
  overflow: hidden;
}
.order-block__table .dataTables_wrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow: auto;
}
.order-block__table .dataTable {
  flex: 1;
}
.order-block__content {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 16px;
  flex: 1;
  margin-bottom: 16px;
  max-width: 824px;
  flex-shrink: 0;
  margin-right: 24px;
}
.order-block__content h1, .order-block__content h5 {
  margin: 0;
}
.order-block__content .margin-normal {
  margin-bottom: 16px;
}

.js-link-url {
  display: none;
}

.order-table th:nth-last-child(2), .order-table td:nth-last-child(2) {
  border-left: none;
}

.order-block__table .dataTables_paginate {
  position: sticky;
  left: 0;
}

a.status-label {
  color: #fff;
}
@media screen and (min-width: 900px) {
  a.status-label:hover {
    color: #fff;
  }
}
@media screen and (min-width: 900px) {
  a.status-label--second:hover {
    background: var(--hover-color);
  }
}

.status-label {
  display: inline-flex;
  align-items: center;
  min-height: 21px;
  padding: 2px 8px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  background: var(--gray-mid);
  white-space: nowrap;
  font-size: 10px;
  line-height: 1;
  font-family: "Stapel";
  transition: background 0.2s ease-in-out;
}
.status-label--red {
  background: var(--laying-type-color);
}
.status-label--orange {
  background: #FF6633;
}
.status-label--yellow {
  background: #FFCB33;
}
.status-label--green {
  background: #29CC39;
}
.status-label--second {
  background: var(--second-color);
  --hover-color: var(--second-hover-color) ;
}

.sort-date {
  display: none;
}

.js-tr-link-table tr {
  cursor: pointer;
}
.js-tr-link-table tr td {
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 900px) {
  .js-tr-link-table tr:hover td {
    background: var(--bg-btn-action) !important;
  }
}

.order-logo {
  width: 133px;
  height: auto;
  object-fit: contain;
}

.order-action {
  display: flex;
}
.order-action-itm {
  cursor: pointer;
}
@media screen and (min-width: 900px) {
  .order-action-itm:hover svg {
    fill: var(--accent-color);
  }
}
.order-action-itm svg {
  width: 24px;
  height: 24px;
  fill: var(--gray-mid);
}
.order-action-itm:not(:last-child) {
  margin-right: 24px;
}

.order-hr {
  display: block;
  border: none;
  outline: none;
  border-bottom: 1px solid #B7BABE;
  margin: 24px 0;
}

.order-attr-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--gray-mid);
}
.order-attr-right {
  text-align: right;
}

p.order-attr-desc {
  margin: 0 0 8px 0;
}

p.order-attr-val {
  margin: 0 0 8px 0;
}

.order-detail-table {
  font-size: 10px;
  width: 100%;
  color: var(--black);
}
.order-detail-table th {
  padding: 8px;
  text-align: left;
  font-weight: 500;
}
.order-detail-table td {
  padding: 8px;
  border-top: 1px solid var(--gray-light);
}
.order-detail-table__img {
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
  margin-right: 8px;
}
.order-detail-table__link {
  color: inherit;
  display: flex;
  align-items: center;
  min-width: 150px;
}
@media screen and (min-width: 900px) {
  .order-detail-table__link:hover {
    color: var(--accent-color);
  }
}

.order-footer {
  font-size: 10px;
  color: var(--gray-mid);
  gap: 0.5rem;
}
.order-footer p {
  margin: 0 0 8px 0;
}
.order-footer p:last-child {
  margin-bottom: 0;
}
.order-footer a {
  color: inherit;
}
@media screen and (min-width: 900px) {
  .order-footer a:hover {
    color: var(--accent-color);
  }
}

@media (max-width: 800px) {
  .order-block {
    display: block;
  }
  .order-block__filter {
    position: relative;
    top: 0;
    margin-bottom: 25px;
  }
}
@media (max-width: 576px) {
  .order-block__filter {
    width: 100%;
  }
  .order-attr-right {
    margin-top: 24px;
  }
  .order-attr:not(:last-child) {
    margin-bottom: 24px;
  }
  .order-block__content {
    padding: 8px;
  }
  .order-table td, .order-table th {
    padding: 8px !important;
    min-width: 80px;
  }
}
.cart-page {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 266px;
  gap: 1.5rem;
  font-family: "Stapel";
  padding-bottom: 2rem;
}

.cart-content h1 {
  margin: 0 0 2rem 0;
  color: var(--v-2-header-color, #346);
  font-weight: 500;
}

.hr-light {
  border: none;
  border-bottom: 1px solid var(--gray-light);
}

.cart-result {
  position: sticky;
  top: 90px;
}
.cart-result .check_box__text .h5 {
  display: inline-block;
  margin-left: 8px;
}
.cart-result ul {
  padding-left: 0;
}

.cart-attr {
  font-size: var(--small);
}
.cart-attr:not(:last-child) {
  margin-bottom: 1rem;
}
.cart-attr__val {
  font-weight: 500;
  font-size: 1rem;
  margin-left: 0.25rem;
}

.cart-cost-result {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--accent-color);
  font-size: 1rem;
  margin-bottom: 2rem;
}
.cart-cost-result__val {
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: 500;
  font-size: 1.25rem;
  color: var(--accent-color);
}

.cart-steps {
  margin-top: 1.5rem;
}

.cart-step {
  display: none;
}
.cart-step.active {
  display: block;
}
.cart-step-head {
  padding: 1rem;
  border-radius: 8px;
  background: var(--v-2-background, #F7F8FA);
  display: flex;
  gap: 1rem;
  align-items: center;
  color: var(--accent-color);
}
.cart-step-head svg {
  width: 24px;
  height: 24px;
  fill: var(--gray-mid);
  flex-shrink: 0;
}
.cart-step-head__name {
  flex: 1;
}
.cart-step-head__switch {
  display: flex;
  gap: 1rem;
  font-size: 1rem;
  color: var(--gray-mid);
  flex: 1;
}
.cart-step-head__switch__label {
  flex: 1;
  text-align: right;
}
.cart-step-head__switch__control {
  flex: 1;
}
.cart-step-head__desc {
  color: var(--gray-mid);
}
.cart-step .delivery-price {
  color: var(--accent-color);
}
.cart-step-content {
  padding: 1.5rem 0;
}

.delivery-line {
  display: flex;
  flex-wrap: wrap;
  gap: 0 1.5rem;
}
.delivery-line .select {
  width: 325px;
  max-width: 100%;
}

.cart-remove {
  display: block;
  width: 16px;
  height: 16px;
}
.cart-remove svg {
  max-width: 100%;
  height: 100%;
}
@media screen and (min-width: 900px) {
  .cart-remove:hover svg {
    fill: var(--accent-color);
  }
}

.td-price {
  min-width: 80px;
}

.stat-desc {
  font-size: 15px;
  margin-bottom: 0.5rem;
}

@media (max-width: 1024px) {
  .cart-page {
    display: block;
  }
  .cart-content {
    margin-bottom: 2rem;
  }
}
@media (max-width: 576px) {
  .cart-step-head {
    font-size: var(--small);
    gap: 0.5rem;
  }
  .cart-content {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .cart-result {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
.table-stat {
  font-size: 10px;
}
.table-stat table.dataTable th, .table-stat table.dataTable td {
  padding: 1rem;
  font-weight: 400;
}
.table-stat table.dataTable th:first-child, .table-stat table.dataTable td:first-child {
  position: sticky !important;
  left: 0;
  background: #fff;
  border-right: 1px solid var(--gray-mid);
  z-index: 1;
  max-width: 100%;
}
.table-stat .dataTables_wrapper {
  overflow: auto;
  width: 100%;
}
.table-stat .dataTables_paginate {
  position: sticky;
  left: 0;
}

.ag-theme-quartz {
  --ag-font-size: 10px !important;
  --ag-font-family: "Inter", sans-serif !important;
  --ag-row-hover-color: var(--bg-btn-action) !important;
  --ag-foreground-color: var(--dark-color) !important;
}

.ag-header-cell {
  font-weight: 800 !important;
}

.user-search-checkbox {
  width: 176px;
  margin: 1rem 0;
}

.user-table-ava {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  object-fit: cover;
  object-position: center;
  background: url("../../img/user-avatar-empty.svg") no-repeat center;
  background-size: contain;
  border-radius: 8px;
}

.user-ava {
  display: flex;
}
.user-ava.empty .user-ava__img .svg {
  display: block;
}
.user-ava.empty .ava-btn--replace, .user-ava.empty .ava-btn--remove {
  display: none;
}
.user-ava.empty .ava-btn--load {
  display: flex;
}
.user-ava__img {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  border: 2px solid var(--v-2-grey-mid, #B7BABE);
  background-color: var(--v-2-white, #FFF);
  margin-right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}
.user-ava__img .svg {
  width: 2.5rem;
  height: 2.5rem;
  display: none;
}
.user-ava input {
  display: none;
}
.user-ava__btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
}
.user-ava .ava-btn--replace, .user-ava .ava-btn--remove {
  display: flex;
}
.user-ava .ava-btn--load {
  display: none;
}

.ava-btn {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--v-2-grey-light, #F0F1F2);
  font-size: 0.9375rem;
  letter-spacing: 0.01875rem;
  font-weight: 500;
  color: var(--v-2-grey-dark, #4E5359);
  padding: 0 1rem;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.ava-btn--replace {
  display: none;
}
.ava-btn--remove {
  display: none;
}
@media screen and (min-width: 900px) {
  .ava-btn:hover {
    background: var(--laying-type-hover);
  }
}

.user-detail-grid input {
  margin-bottom: 0;
}
.user-detail-grid__r2 {
  grid-column-start: 2;
  grid-column-end: 4;
}
.user-detail-grid .ava-col {
  grid-row-start: 1;
  grid-row-end: 4;
}
.user-detail-grid button {
  grid-column-end: 4;
}

.user-pass-grid {
  align-items: flex-start;
}

.user-pass-btns {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media (max-width: 1024px) {
  .user-detail-grid__r2 {
    grid-column-end: 3;
    grid-column-start: 1;
  }
  .user-detail-grid .ava-col {
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .user-detail-grid button {
    grid-column-end: 3;
  }
  .user-pass-btns {
    grid-column-end: 3;
  }
}
@media (max-width: 576px) {
  .user-detail-grid__r2 {
    grid-column-end: 2;
  }
  .user-detail-grid .ava-col {
    grid-column-end: 2;
  }
  .user-detail-grid button {
    grid-column-end: 2;
  }
  .user-pass-btns {
    grid-column-end: 2;
  }
}
.template--fineapp .menu__list {
  justify-content: flex-end;
  margin-left: 2rem;
}
.template--fineapp .menu__item--active {
  width: auto;
}
.template--fineapp .header-main {
  flex: inherit;
}
.template--fineapp .header__menu {
  flex: 1;
}
.template--fineapp .top-search__wrapper--wider {
  width: auto;
  flex: 1;
}

.widget-slider {
  position: relative;
  display: flex;
  overflow: hidden;
}
.widget-slider-itm {
  min-width: 100%;
  background: #fff;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 0.5rem;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
}
.widget-slider-itm-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.widget-slider-itm__image {
  position: relative;
  overflow: hidden;
  border-radius: 0 0.5rem 0.5rem 0;
}
.widget-slider-itm__content {
  padding: 1.5rem 1.5rem 5rem;
}
.widget-slider-itm__content .h2 {
  margin-bottom: 1.5rem;
}
.widget-slider-itm__content__text {
  margin-bottom: 1.5rem;
}
.widget-slider .owl-nav {
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
}
.widget-slider .owl-dots {
  position: absolute;
  display: flex;
  align-items: center;
  left: 1.5rem;
  bottom: 1.5rem;
  justify-content: center;
  gap: 0.5rem;
}
.widget-slider .owl-dot {
  position: relative;
  display: inline-block;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: var(--accent-color);
  transform: rotate(90deg);
  cursor: pointer;
}
.widget-slider .owl-dot.active path {
  animation: dash 10s linear infinite;
}
.widget-slider .owl-dot svg {
  width: 1rem;
  height: 1rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.8;
}
.widget-slider .owl-dot svg path {
  stroke-dasharray: 240px;
  stroke-dashoffset: 240px;
}
@media screen and (min-width: 900px) {
  .widget-slider .owl-dot:hover {
    background: var(--accent-hover-color);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 0px;
    opacity: 0;
  }
  5% {
    stroke-dashoffset: 0px;
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 240px;
  }
}
.owl-nav button {
  position: relative;
  font-size: 0;
  width: 2rem;
  height: 2rem;
  background: var(--accent-color);
  transition: 0.2s ease-in-out;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
.owl-nav button:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.25rem;
  margin-top: -0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  background: url("../img/svg/arrow-small.svg") no-repeat center;
}
.owl-nav button.owl-next:before {
  transform: scaleX(-1);
}
.owl-nav button:not(:last-child) {
  margin-right: 0.5rem;
}
@media screen and (min-width: 900px) {
  .owl-nav button:hover {
    background: var(--second-color);
  }
}
.owl-nav button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.widget-margin {
  margin-bottom: 2.5rem;
}

.widget-tile-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
}

.widget-tile-itm {
  position: relative;
  display: block;
  aspect-ratio: 10/15;
  box-shadow: 0px 2px 4px 0px rgba(29, 31, 33, 0.2);
  border-radius: 0.5rem;
}
.widget-tile-itm:before {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 0.5rem;
  opacity: 0;
  border: 2px solid var(--accent-color);
  transition: opacity 0.2s;
}
.widget-tile-itm .fill-img, .widget-tile-itm .catalog-itm-slider__itm, .widget-tile-itm .catalog-itm-slider__itm img, .catalog-itm-slider__itm .widget-tile-itm img {
  transition: all 0.4s;
  will-change: transform;
  border-radius: 0.5rem;
  overflow: hidden;
}
@media screen and (min-width: 900px) {
  .widget-tile-itm:hover:before {
    opacity: 1;
  }
}
.widget-tile-itm--block {
  pointer-events: none;
}
.widget-tile-itm--block .fill-img, .widget-tile-itm--block .catalog-itm-slider__itm, .widget-tile-itm--block .catalog-itm-slider__itm img, .catalog-itm-slider__itm .widget-tile-itm--block img {
  filter: grayscale(1);
}
.widget-tile-itm__block {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #F5333F;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25rem;
  border-radius: 0.25rem;
  white-space: nowrap;
}
.widget-tile-itm__block img {
  margin-right: 0.15rem;
}
.widget-tile-itm__block span {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.widget-price h2 {
  color: var(--accent-color);
}
.widget-price--green h2 {
  color: var(--second-color);
}
.widget-price--green .widget-tile-itm:before {
  border-color: var(--second-color);
}

.widget-contact {
  color: var(--extra-dark-color);
}
.widget-contact b {
  font-weight: 500;
}
.widget-contact h2 {
  margin-top: 0;
  margin-bottom: 2.5rem;
  font-weight: 500;
  color: var(--accent-color);
}
.widget-contact__link {
  color: var(--accent-color);
  font-weight: 500;
}
@media screen and (min-width: 900px) {
  .widget-contact__link:hover {
    color: var(--accent-hover-color);
  }
}
.widget-contact__mail {
  color: inherit;
}
@media screen and (min-width: 900px) {
  .widget-contact__mail:hover {
    color: var(--accent-hover-color);
  }
}
.widget-contact__map {
  display: flex;
  align-items: center;
}
.widget-contact__map__img {
  width: 7.5rem;
  height: 5rem;
  border-radius: 0.5rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
}

.user-block {
  display: flex;
  align-items: center;
}
.user-block__ava {
  position: relative;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;
  margin-right: 1rem;
}
.user-block__name {
  font-weight: 500;
}

.widget-contact-table tr:last-child td {
  padding-bottom: 0;
}
.widget-contact-table td {
  padding-bottom: 2.5rem;
}
.widget-contact-table td:first-child {
  max-width: 33%;
}
.widget-contact-table td .text-light {
  font-weight: 400;
}
.widget-contact-table td:first-child {
  font-weight: 500;
}
.widget-contact-table td:not(:last-child) {
  padding-right: 2.5rem;
}

.promo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: var(--dark-color);
  gap: 2.5rem;
  margin-bottom: 2.5rem;
}
.promo-grid-itm {
  background: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 0.1rem;
}
.promo-grid-itm__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.promo-grid-itm__head__logo {
  max-height: 2.5rem;
  max-width: 5.6rem;
}
.promo-grid-itm__img {
  position: relative;
  display: block;
  aspect-ratio: 23.75/11.5;
  margin: 0 -1rem 1rem;
}
@media screen and (min-width: 900px) {
  .promo-grid-itm__img:hover ~ .promo-grid-itm__name {
    color: var(--accent-color);
  }
}
.promo-grid-itm__name {
  color: inherit;
  display: block;
  margin-bottom: 1rem;
  transition: color 0.2s;
}
@media screen and (min-width: 900px) {
  .promo-grid-itm__name:hover {
    color: var(--accent-color);
  }
}
.promo-grid-itm__text {
  font-size: var(--small);
}
.promo-grid-itm__text ul {
  margin: 0;
}
.promo-grid-itm__text ul li {
  position: relative;
}
.promo-grid-itm__text ul li:before {
  content: "";
  position: absolute;
  left: -0.5rem;
  top: 0.33rem;
  width: 3px;
  height: 3px;
  background: var(--dark-color);
  transform: rotate(45deg);
}

.widget-detail-row {
  display: grid;
  grid-template-columns: 1fr 40%;
  gap: 2rem;
}
.widget-detail-row__img {
  position: relative;
  aspect-ratio: 35.4/26.2;
}

table.style-table .decor-preview-td {
  padding: 0 !important;
}

.decor-preview-td {
  width: 4.5rem;
  max-width: 4.5rem !important;
  min-width: 4.5rem !important;
}
.decor-preview-td img {
  width: 2rem;
  height: 2rem;
}

.promo-product-table td:last-child, .promo-product-table th:last-child {
  display: none;
}
.promo-product-table .t-decor-menu {
  width: 90px;
}

.widget-faq-head {
  display: grid;
  grid-template-columns: 1fr 40%;
}
.widget-faq-head h1 {
  margin: 0;
  align-self: center;
}

.faq-search {
  position: relative;
  display: block;
  align-self: center;
}
.faq-search svg {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  fill: #B7BABE;
}
.faq-search input {
  display: block;
  height: 3rem;
  padding: 1rem 1rem 1rem 3rem;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
  border-radius: 0.5rem;
  background: var(--v-2-background, #F7F8FA);
  border: none;
  outline: none;
  width: 100%;
}

.accordion-head {
  position: relative;
}
.accordion.hide {
  display: none;
}
.accordion .hide {
  display: none;
}
.accordion-arrow {
  position: absolute;
  right: 1rem;
  top: 50%;
  width: 1.75rem;
  height: 1.75rem;
  margin-top: -0.875em;
  transition: transform 0.3s;
  transform-origin: center;
}
.accordion-arrow:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: var(--accent-color);
  width: 100%;
  height: 2px;
}
.accordion-arrow:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 100%;
  width: 2px;
  background: var(--accent-color);
}
.accordion--faq {
  border-radius: 0.5rem;
}
.accordion--faq .accordion-head {
  transition: color 0.2s ease;
  padding-right: 3rem;
}
.accordion--faq .accordion-head:after {
  display: none;
}
@media screen and (min-width: 900px) {
  .accordion--faq .accordion-head:hover {
    color: var(--accent-color);
  }
}
.accordion--faq .accordion-head.active .accordion-arrow {
  transform: rotate(45deg);
}
.accordion--faq .accordion-content {
  padding: 1rem;
  display: none;
  max-width: 43rem;
}
.accordion--faq .accordion-content p {
  margin-top: 0;
}
.accordion--faq.active {
  background: var(--v-2-background, #F7F8FA);
}
.accordion--brand > .accordion-head {
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  background: var(--ffsl-grey, #F7F8FA);
  border-bottom: 2px solid var(--ffl-grey, #CACDD2);
  transition: all 0.2s ease;
}
.accordion--brand > .accordion-head:after {
  display: none;
}
.accordion--brand > .accordion-head.active {
  border-color: var(--accent-color);
}
@media screen and (min-width: 900px) {
  .accordion--brand > .accordion-head:hover {
    color: var(--accent-color);
  }
}
.accordion--brand > .accordion-head .accordion-arrow {
  width: 1.2rem;
  height: 1.2rem;
  margin-top: -0.6rem;
  will-change: transform;
}
.accordion--brand > .accordion-head.active .accordion-arrow {
  transform: rotate(45deg);
}
.accordion--brand > .accordion-content {
  display: none;
}
.accordion--decor {
  border-radius: 0;
  border-bottom: 1px solid #CACDD2;
}
.accordion--decor.active {
  border-color: var(--accent-color);
}
.accordion--decor .accordion-head {
  transition: all 0.2s ease;
}
.accordion--decor .accordion-head:after {
  display: none;
}
@media screen and (min-width: 900px) {
  .accordion--decor .accordion-head:hover {
    color: var(--accent-color);
  }
}
.accordion--decor .accordion-head .accordion-arrow-img {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transition: all 0.2s;
  margin-top: -0.75rem;
}
.accordion--decor .accordion-head .accordion-arrow-img svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--gray-mid);
  transition: all 0.2s;
}
.accordion--decor .accordion-head.active .accordion-arrow-img svg {
  fill: var(--accent-color);
  transform: rotate(180deg);
}
.accordion--decor .accordion-content {
  display: none;
  padding-bottom: 1.5rem;
  padding-top: 1rem;
}

.faq-block .accordion:not(:last-child) {
  margin-bottom: 1.5rem;
}

.brand-grid {
  display: grid;
  grid-template-columns: 20rem 1.75rem;
  gap: 1rem;
  align-items: center;
}
.brand-grid img {
  display: block;
}

.brand-name {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  line-height: 1;
}
.brand-name__img {
  position: relative;
  width: 2.9375rem;
  height: 1.75rem;
}

.decor-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.accordion-flag-block .texinfo-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media screen and (min-width: 900px) {
  .accordion-flag-block .texinfo-btns a:hover {
    color: #fff;
  }
}
.accordion-flag-block .btn-line {
  padding: 0.5rem 1rem;
}
.accordion-flag-block .accordion {
  margin-bottom: 1.5rem;
  border-radius: 0;
}
.accordion-flag-block .accordion-head {
  position: relative;
  background: #F7F8FA;
  padding: 16px 65px 16px 24px;
  border-bottom: 2px solid var(--gray-mid);
  cursor: pointer;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
}
.accordion-flag-block .accordion-head:after {
  display: none;
}
.accordion-flag-block .accordion-head.active {
  border-color: var(--accent-color);
}
.accordion-flag-block .accordion-head.active .accordion-head__arrow {
  transform: translateY(-50%) rotate(0deg);
}
.accordion-flag-block .accordion-head-name {
  font-size: var(--h4);
  font-weight: 500;
  flex: 1;
}
.accordion-flag-block .accordion-head-desc {
  margin-top: 4px;
  font-size: var(--small);
  line-height: 1.2;
}
.accordion-flag-block .accordion-head__arrow {
  position: absolute;
  right: 36px;
  width: 16px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  transition: all 0.3s ease;
}
.accordion-flag-block .accordion-head__arrow:before, .accordion-flag-block .accordion-head__arrow:after {
  background: var(--accent-color);
}
.accordion-flag-block .accordion-head__content {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
}
.accordion-flag-block .accordion-head__content img {
  margin-bottom: 0;
}
.accordion-flag-block .accordion-head__content.flex {
  display: flex;
  align-items: center;
  gap: 18px;
}
.accordion-flag-block .accordion-body {
  display: none;
  font-size: 1rem;
  overflow: hidden;
}
.accordion-flag-block .accordion-body a {
  color: var(--accent-color);
}
.accordion-flag-block .accordion--inner {
  border-bottom: 1px solid var(--gray-mid);
}
.accordion-flag-block .accordion--inner.active {
  border-color: var(--accent-color);
}
.accordion-flag-block .accordion--inner__collection {
  width: 47px;
}
.accordion-flag-block .accordion--inner .accordion-head {
  background: none;
  border: none;
}
.accordion-flag-block .accordion--inner .accordion-head-name {
  font-size: var(--small);
}
.accordion-flag-block .accordion--inner .accordion-head__arrow {
  transform: translateY(-50%) rotate(90deg);
  width: 16px;
  height: 19px;
}
.accordion-flag-block .accordion--inner .accordion-head__arrow:before, .accordion-flag-block .accordion--inner .accordion-head__arrow:after {
  display: none;
}
.accordion-flag-block .accordion--inner .accordion-head__arrow path {
  fill: var(--gray-mid);
}
.accordion-flag-block .accordion--inner .accordion-head__content {
  display: flex;
  align-items: center;
  gap: 18px;
}
.accordion-flag-block .accordion--inner .accordion-head.active .accordion-head__arrow {
  transform: translateY(-50%) rotate(270deg);
}
.accordion-flag-block .accordion--inner .accordion-head.active .accordion-head__arrow path {
  fill: var(--accent-color);
}
.accordion-flag-block .accordion--inner.with-flag .accordion-head__content__left {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
}
.accordion-flag-block .accordion--inner.with-flag .accordion-head__content__left .accordion-head-name {
  width: auto !important;
}

@media (max-width: 1024px) {
  .widget-margin {
    margin-bottom: 1.5rem;
  }
  .promo-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .widget-detail-row {
    display: block;
  }
  .widget-detail-row__img {
    display: none;
  }
}
@media (max-width: 576px) {
  .widget-slider-itm {
    display: block;
  }
  .widget-slider-itm__image {
    display: none;
  }
  .template--fineapp .header__logo {
    max-width: 100%;
    background: none;
  }
  .template--fineapp .header__logo img {
    margin-right: 0;
  }
  .header-phone {
    display: none;
  }
  .widget-tile-list {
    grid-template-columns: repeat(2, 1fr);
  }
  .widget-contact-table {
    display: block;
  }
  .widget-contact-table td {
    display: block;
  }
  .widget-contact-table td:first-child {
    max-width: 100%;
    padding-bottom: 0.5rem;
  }
  .promo-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .brand-grid {
    width: 100%;
    grid-template-columns: 1fr 1.75rem;
    padding-right: 2rem;
  }
  .widget-faq-head {
    display: block;
  }
  .faq-search {
    margin-top: 1rem;
  }
}
/* Sliders */
.ais-RangeSlider {
  margin-bottom: 1.5rem;
  margin-top: 0.75rem;
  padding: 0 0.375rem;
}
.ais-RangeSlider .rheostat-tooltip {
  font-weight: bold;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0;
}
.ais-RangeSlider .rheostat-handle {
  background-image: linear-gradient(to top, #f5f5fa, #fff);
  box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15), 0 2px 3px 0 rgba(93, 100, 148, 0.2);
  margin-left: -5px;
  top: -9px;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: 2px solid var(--second-color);
  z-index: 2;
}
.ais-RangeSlider .rheostat-background {
  height: 4px;
  border-radius: 0.5rem;
  background: var(--V2-Grey-Mid, #B7BABE);
}
.ais-RangeSlider .rheostat-progress {
  position: absolute;
  height: 4px;
  top: 0px;
  background: var(--accent-color);
}
.ais-RangeSlider .rheostat-horizontal {
  cursor: pointer;
  width: calc(100% - 10px);
}

.ais-RangeSlider .rheostat-marker {
  background-color: rgba(65, 66, 71, 0.08);
}

/* RangeInput */
.ais-RangeInput-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem 2.4rem;
}
.ais-RangeInput-label {
  margin: 0;
  flex: 1;
}
.ais-RangeInput-submit {
  grid-column: span 2;
}
.ais-RangeInput-separator {
  display: none;
}
.ais-RangeInput-input {
  background: none;
  color: #21243d;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: none;
  height: 2rem;
  border-radius: 0.5rem;
  border: 1px solid var(--V2-Grey-Mid, #B7BABE);
  width: 100%;
  text-align: center;
  outline: none;
}

.ais-RangeInput-label:first-of-type {
  margin-right: 6px;
}

.ais-RangeInput-label:last-of-type {
  margin-left: 6px;
}

.ais-RangeSlider .rheostat-tooltip::before {
  color: #e2a400;
  content: "$";
  font-size: 0.6;
  margin-right: 4px;
}

@media (max-width: 899px) {
  .ais-RangeSlider .rheostat-handle {
    height: 1.5rem;
    top: -12px;
    width: 1.5rem;
  }
}
.facet-block {
  flex: 1;
  padding-top: 1.5rem;
}
.facet-block .facet-filters:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  right: -10px;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease;
}
.facet-block.load .product-itm-skeleton {
  display: none;
}
.facet-block:not(.load) [data-widget=hits] {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.facet-block:not(.load) .facet-filters {
  overflow: hidden;
  aspect-ratio: 1/1.75;
}
.facet-block:not(.load) .facet-filters:before {
  opacity: 1;
  z-index: 2;
  background: #ddd;
}
.facet-block:not(.load) .facet-filters:after {
  content: "";
  width: 20rem;
  height: 100%;
  position: absolute;
  left: 100%;
  top: 0;
  background: linear-gradient(90deg, #ddd, #eee, #ddd);
  z-index: 3;
  animation: skeleton 1s ease infinite;
  transform: skewX(-15deg);
}
.facet-block.view-table:not(.load) [data-widget=hits] {
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: 1rem;
  padding-top: 0;
}
.facet-block.view-table:not(.load) [data-widget=hits]:after {
  content: "";
  width: 20rem;
  height: 100%;
  position: absolute;
  left: 100%;
  top: 0;
  background: linear-gradient(90deg, #ddd, #eee, #ddd);
  z-index: 3;
  animation: skeleton 1s ease infinite;
  transform: skewX(-15deg);
}
.facet-block.view-table:not(.load) .product-itm-skeleton {
  aspect-ratio: auto;
  height: 7rem;
  margin-bottom: 2px;
}
.facet-block.view-table:not(.load) .product-itm-skeleton:after {
  display: none;
}
.facet-grid {
  display: grid;
  grid-template-columns: 16.4rem minmax(0, 1fr);
  gap: 1.6rem;
}
.product-itm-skeleton {
  position: relative;
  aspect-ratio: 1/1.5;
  overflow: hidden;
}
.product-itm-skeleton:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: all 0.2s ease;
  opacity: 1;
  z-index: 2;
  background: #ddd;
}
.product-itm-skeleton:after {
  content: "";
  width: 20rem;
  height: 100%;
  position: absolute;
  left: 100%;
  top: 0;
  background: linear-gradient(90deg, #ddd, #eee, #ddd);
  z-index: 3;
  animation: skeleton 1s ease infinite;
  transform: skewX(-15deg);
}

@keyframes skeleton {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}
.facet-filters {
  position: sticky;
  top: 6.75rem;
  max-height: calc(100dvh - 7.5rem);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
  /* W3C standard
    сейчас только для Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) #F0F1F2;
  /* для Chrome/Edge/Safari */
  overscroll-behavior: contain;
}
.facet-filters::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.facet-filters::-webkit-scrollbar-track {
  background: #F0F1F2;
  border-radius: 6px;
  cursor: pointer;
}
.facet-filters::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border: 3px solid #F0F1F2;
  cursor: pointer;
  border-radius: 6px;
}
.facet-filters::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-hover-color);
}
.facet-filters-btn-block {
  padding: 1.5rem 0;
  margin: 0 1rem;
  border-top: 1px solid var(--V2-Background);
}
.facet-filters-btn-block .btn, .facet-filters-btn-block .ais-RefinementList-showMore {
  width: 100%;
}
.facet-filters-btn-block .btn:not(:last-child), .facet-filters-btn-block .ais-RefinementList-showMore:not(:last-child) {
  margin-bottom: 1rem;
}
.facet-filters > .facet-accordion-header {
  padding-right: 0.5rem;
}
.facet-filters > .facet-body {
  padding-right: 0;
  margin-right: -0.5rem;
}

.facet-body {
  display: none;
}
.facet-body [data-widget].hide {
  display: none;
}
.facet-body [data-widget]:not(:first-child) .ais-Panel-header {
  border-top: 1px solid var(--V2-Background);
}
.facet-body > .ais-Panel .ais-Panel-header {
  border-top: 1px solid var(--V2-Background);
}

.facet-results {
  background: none;
  box-shadow: none;
  padding-right: 2.5rem;
}

.ais-CurrentRefinements-list {
  padding-left: 0;
}
.ais-CurrentRefinements-list .ais-CurrentRefinements-item {
  display: inline;
}

.ais-CurrentRefinements-label {
  display: none;
}
.ais-CurrentRefinements-category {
  border-radius: 0.25rem;
  background: var(--V2-Red, #F5333F);
  color: #fff;
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0.02438rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  border: none;
  display: inline-block;
}
.ais-CurrentRefinements-delete {
  background: none;
  border: none;
  color: #fff;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  will-change: transform;
  margin-left: 0.25rem;
  position: relative;
}
.ais-CurrentRefinements-delete:after {
  content: "";
  position: absolute;
  left: -5px;
  top: -5px;
  right: -5px;
  bottom: -5px;
}
@media screen and (min-width: 900px) {
  .ais-CurrentRefinements-delete:hover {
    transform: scale(1.2);
  }
}

.ais-Panel {
  padding: 0 1rem;
}
.ais-Panel-header {
  font-size: var(--h5);
  font-weight: 500;
  color: var(--v-2-header-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0;
  cursor: pointer;
}
@media screen and (min-width: 900px) {
  .ais-Panel-header:hover {
    color: var(--accent-color);
  }
}
.ais-Panel-header.active .ais-Panel-collapseButton:before {
  transform: scaleY(-1);
  border-top-color: var(--accent-color);
}
.ais-Panel-collapseButton {
  position: relative;
  background: none;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}
.ais-Panel-collapseButton:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 8px;
  transform-origin: 20% 15%;
  border: 8px solid transparent;
  border-top: 10px solid var(--v-2-header-color);
  transition: all 0.2s;
}
.ais-Panel-collapseButton svg {
  display: none;
}
.ais-Panel-body {
  padding: 0 0 1rem;
  display: none;
}

.facet-footer .ais-Panel-body {
  display: block;
}

.ais-Pagination-list {
  display: flex;
  padding: 0;
  gap: 0.25rem 0.5rem;
}

.ais-Pagination-item--selected .ais-Pagination-link {
  border-color: var(--accent-color);
}

.ais-Pagination-link {
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  display: block;
  letter-spacing: 0.02438rem;
  font-weight: 500;
  color: var(--gray-dark);
  font-size: var(--small);
  border: 1px solid transparent;
}
@media screen and (min-width: 900px) {
  .ais-Pagination-link:hover {
    background: #EDEFF2;
  }
}

.ais-RefinementList-list {
  padding-left: 0;
  margin: 0;
}

.ais-RefinementList-item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.ais-RefinementList-item.hide {
  display: none;
}

.ais-RefinementList-label {
  position: relative;
  display: flex;
  width: 100%;
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0.02438rem;
  cursor: pointer;
  transition: all 0.2s;
}
@media screen and (min-width: 900px) {
  .ais-RefinementList-label:hover {
    color: var(--accent-color);
  }
}
.ais-RefinementList-labelText {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.ais-RefinementList-labelText img {
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  transform: translateY(-1px);
}
.ais-RefinementList-label .ais-RefinementList-checkbox {
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
}
.ais-RefinementList-label .ais-RefinementList-checkbox ~ .ais-RefinementList-labelText {
  display: flex;
  align-items: flex-start;
}
.ais-RefinementList-label .ais-RefinementList-checkbox ~ .ais-RefinementList-labelText:before {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  margin-top: -1px;
  background: var(--gray-light);
  border-radius: 3px;
  margin-right: 0.5rem;
  transition: background 0.3s;
  flex-shrink: 0;
}
.ais-RefinementList-label .ais-RefinementList-checkbox ~ .ais-RefinementList-labelText:after {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: 0;
  top: 0;
  background: url("../img/svg/check.svg") no-repeat center;
  background-size: 70%;
  opacity: 0;
  transition: opacity 0.3s;
}
.ais-RefinementList-label .ais-RefinementList-checkbox:checked ~ .ais-RefinementList-labelText:before {
  background-color: var(--v2-red);
}
.ais-RefinementList-label .ais-RefinementList-checkbox:checked ~ .ais-RefinementList-labelText:after {
  opacity: 1;
}
.ais-RefinementList-count {
  color: var(--gray-mid);
  font-size: 0.75rem;
  font-weight: 500;
}
.ais-RefinementList-showMore {
  margin-top: 1rem;
}
.ais-RefinementList-showMore--disabled {
  display: none;
}

.ais-ClearRefinements-button {
  background: none;
  padding: 0;
  border: none;
  -webkit-appearance: none;
  outline: none;
  width: 100%;
}

.ais-Hits {
  overflow: hidden;
}

.ais-Hits-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding: 0;
  list-style: none;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

.facet-accordion-header {
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
@media screen and (min-width: 900px) {
  .facet-accordion-header:hover .facet-accordion-header__title {
    color: var(--accent-color);
  }
}
.facet-accordion-header__title {
  font-weight: 500;
  font-family: var(--h4);
  color: var(--v-2-header-color);
  line-height: 100%;
  margin: 0;
  flex: 1;
  transform: translateY(2px);
  transition: color 0.2s;
}
.facet-accordion-header__ico {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--accent-color);
  flex-shrink: 0;
}
.facet-accordion-header__arrow {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--v-2-header-color);
  flex-shrink: 0;
  transform: scaleY(-1);
  transition: all 0.3s;
}
.facet-accordion-header.active .facet-accordion-header__arrow {
  fill: var(--accent-color);
  transform: scaleY(1);
}

.facet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

[data-widget].hide {
  pointer-events: none;
  opacity: 0.2;
}
[data-widget].hide .ais-Panel-body {
  display: none !important;
}

[data-widget=searchbox] {
  position: relative;
  flex: 1;
  margin-right: 1rem;
}

.ais-SearchBox-input {
  width: 100%;
  height: 3rem;
  padding: 1rem 1rem 1rem 2.5rem;
  border-radius: 0.5rem;
  background: var(--V2-White, #FFF);
  border: none;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
  -webkit-appearance: none;
  outline: none;
}
.ais-SearchBox-input::-webkit-search-decoration, .ais-SearchBox-input::-webkit-search-cancel-button, .ais-SearchBox-input::-webkit-search-results-button, .ais-SearchBox-input::-webkit-search-results-decoration {
  display: none;
}

.ais-SearchBox-reset {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: none;
  border: none;
  width: 2.5rem;
}

.ais-SearchBox-loadingIndicator {
  position: absolute;
  right: 2rem;
  top: 0;
  height: 100%;
  background: none;
  border: none;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ais-SearchBox-loadingIndicator[hidden] {
  display: none;
}

.ais-SearchBox-submit {
  position: absolute;
  left: 0;
  top: 0;
  width: 2.5rem;
  height: 100%;
  border: none;
  outline: none;
  background: url("../img/svg/main-search.svg") no-repeat center;
}
.ais-SearchBox-submit svg {
  display: none;
}

.ais-SortBy-select {
  width: 100%;
  height: 3rem;
  padding: 1rem 1rem 1rem 2.5rem;
  border-radius: 0.5rem;
  background: var(--V2-White, #FFF);
  border: none;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
  -webkit-appearance: none;
}

.facet-catalog {
  margin-bottom: 1rem;
}

.ais-RefinementList .ais-SearchBox-form {
  position: relative;
  margin-bottom: 1.5rem;
}
.ais-RefinementList .ais-SearchBox-form .ais-SearchBox-input {
  border: 1px solid var(--gray-light);
  height: 2.5rem;
}

[data-widget=sort-by] {
  width: 200px;
}
[data-widget=sort-by] .custom-select__single {
  border: none;
  background: #fff;
}
[data-widget=sort-by] .custom-select__dropdown {
  border: none;
}
[data-widget=sort-by] .custom-select__dropdown-list {
  padding: 0.5rem;
}
[data-widget=sort-by] .custom-select__dropdown-item {
  border-radius: 0.5rem;
  padding: 1rem;
}
[data-widget=sort-by] .custom-select__dropdown-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.hit-table-grid {
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
  border-radius: 0.5rem 0.5rem 0 0;
  font-size: 0.75rem;
  overflow: auto;
  --grid-template: repeat(3, minmax(6rem, 10rem)) minmax(9rem, 1fr) minmax(6rem, 6rem) repeat(2, minmax(4.5rem, 4.5rem)) minmax(7rem, 7rem);
}
.hit-table-grid ~ .facet-footer {
  background: #fff;
  overflow: hidden;
  border-radius: 0 0 0.5rem 0.5rem;
  border-top: 1px solid var(--V2-Grey-Mid, #B7BABE);
}
.hit-table-grid ~ .facet-footer .ais-Panel-body {
  padding-bottom: 0;
}
.hit-table-grid ol {
  margin: 0;
}
.hit-table-grid-head {
  display: grid;
  grid-template-columns: var(--grid-template);
}
.hit-table-grid__th {
  padding: 1rem;
  border-bottom: 1px solid var(--V2-Grey-Mid, #B7BABE);
  color: var(--V2-Grey-Dark, #4E5359);
  font-weight: 500;
}
.hit-table-grid__td {
  position: relative;
  padding: 1rem;
  border-bottom: 1px solid var(--V2-Grey-Light, #F0F1F2);
  word-break: break-word;
}
.hit-table-grid__td a {
  color: inherit;
  transition: color 0.2s ease;
}
@media screen and (min-width: 900px) {
  .hit-table-grid__td a:hover {
    color: var(--accent-color);
  }
}
.hit-table-grid__td--action {
  display: flex;
  padding-right: 0;
}
.hit-table-grid__td .popup-preview {
  top: auto;
  bottom: 100%;
  margin-bottom: -10px;
}
@media screen and (min-width: 900px) {
  .hit-table-grid__td:hover .popup-preview {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    pointer-events: auto;
  }
}
.hit-table-grid .ais-Hits {
  overflow: visible;
}
.hit-table-grid .ais-Hits-list {
  display: block;
}
.hit-table-grid .ais-Hits-item {
  display: grid;
  grid-template-columns: var(--grid-template);
}
.hit-table-grid .ais-Hits-item:last-child .hit-table-grid__td {
  border-bottom: none;
}
.hit-table-grid .ais-Hits-item:first-child .popup-preview {
  bottom: auto;
  top: 100%;
  margin-top: -10px;
}

.facet-view-type {
  display: flex;
  gap: 0.5rem;
  margin-left: 0.5rem;
}
.facet-view-type__btn {
  background: #fff;
  border: none;
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  cursor: pointer;
  border-radius: 0.5rem;
}
.facet-view-type__btn.active {
  pointer-events: none;
}
.facet-view-type__btn.active svg {
  fill: var(--accent-color);
}
.facet-view-type__btn svg {
  width: 100%;
  height: 100%;
  fill: var(--gray-mid);
}
@media screen and (min-width: 900px) {
  .facet-view-type__btn:hover {
    background: var(--gray-light);
  }
}

.modal-facet-grid {
  column-count: 4;
}
.modal-facet-grid .col {
  break-inside: avoid;
  break-before: column;
}
.modal-facet-grid > div {
  break-inside: avoid;
}

#modal-facet-filter .modal-close {
  display: none;
}
#modal-facet-filter .modal-content {
  width: 79rem;
  background: var(--V2-Background);
  display: flex;
  flex-direction: column;
}

.popup-menu {
  top: -30px;
  min-width: 200px;
  right: 100px;
}
.popup-menu.show {
  opacity: 1;
  visibility: visible;
}

.modal-facet-filter {
  background: #fff;
  border-radius: 1rem;
  padding: 1.5rem;
  overflow: auto;
  /* W3C standard
    сейчас только для Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) #F0F1F2;
  /* для Chrome/Edge/Safari */
  overscroll-behavior: contain;
  min-height: 20rem;
}
.modal-facet-filter::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.modal-facet-filter::-webkit-scrollbar-track {
  background: #F0F1F2;
  border-radius: 6px;
  cursor: pointer;
}
.modal-facet-filter::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border: 3px solid #F0F1F2;
  cursor: pointer;
  border-radius: 6px;
}
.modal-facet-filter::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-hover-color);
}
.modal-facet-filter__head {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: var(--h4);
  font-weight: 500;
  color: var(--v-2-header-color);
}
.modal-facet-filter__head svg {
  margin-right: 0.5rem;
}
.modal-facet-filter-btns {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
  z-index: 2;
}
.modal-facet-filter-btns .btn-line {
  background: #fff;
}
@media screen and (min-width: 900px) {
  .modal-facet-filter-btns .btn-line:hover {
    background: var(--accent-color);
  }
}
.modal-facet-filter-btns .btn-main, .modal-facet-filter-btns .btn-second, .modal-facet-filter-btns .ais-RangeInput-submit, .modal-facet-filter-btns .ais-RefinementList-showMore, .modal-facet-filter-btns .btn-line {
  width: 14.5rem;
}
.modal-facet-filter-search {
  position: relative;
  display: block;
}
.modal-facet-filter-search-ico {
  content: "";
  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  fill: #B7BABE;
}
.modal-facet-filter-search input {
  height: 3rem;
  background: var(--V2-Background);
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 1rem 1rem 2.88rem;
  font-size: 0.875rem;
  outline: none;
}
.modal-facet-filter-search input::-webkit-input-placeholder {
  color: var(--gray-mid);
}
.modal-facet-filter-search input:-moz-placeholder {
  color: var(--gray-mid);
}
.modal-facet-filter-search input::-moz-placeholder {
  color: var(--gray-mid);
}
.modal-facet-filter-search input:-ms-input-placeholder {
  color: var(--gray-mid);
}

[data-widget=results-number-modal] {
  display: inline;
}
[data-widget=results-number-modal] .ais-Stats {
  display: inline;
}

.ais-HierarchicalMenu > .ais-HierarchicalMenu-list {
  margin-top: 0.5rem;
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.ais-HierarchicalMenu-list {
  padding-left: 0;
  letter-spacing: 0.02438rem;
  font-weight: 500;
  font-size: 0.8125rem;
}
.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  padding-left: 1rem;
}
.ais-HierarchicalMenu-list a {
  color: var(--dark-color);
  transition: color 0.25s;
}
@media screen and (min-width: 900px) {
  .ais-HierarchicalMenu-list a:hover {
    color: var(--accent-color);
  }
}

.ais-HierarchicalMenu-item--selected > div > .ais-HierarchicalMenu-link {
  color: var(--accent-color);
}
.ais-HierarchicalMenu-item--selected > div > .ais-HierarchicalMenu-link:after {
  transform: rotate(90deg) translateY(5px) translateX(1px);
  border-left-color: var(--accent-color);
}
.ais-HierarchicalMenu-item--selected > div > .ais-HierarchicalMenu-link:before {
  background-color: var(--accent-color);
  background-image: url("/skins/img/check.svg");
  background-size: 65%;
  background-position: center;
  background-repeat: no-repeat;
}
.ais-HierarchicalMenu-item .ais-HierarchicalMenu-list {
  padding-right: 0;
}

.ais-HierarchicalMenu-count {
  color: var(--gray-mid);
  font-size: 0.75rem;
  font-weight: 500;
  margin-left: 0.25rem;
}

.ais-HierarchicalMenu-link {
  position: relative;
  padding: 0.25rem 0 0.25rem 0;
  /*	&:before {
  		content: "";
  		width: 1rem;
  		height: 1rem;
  		display: inline-block;
  		background: var(--gray-light);
  		border-radius: .25rem;
  		margin-right: .25rem;
  		flex-shrink: 0;
  		transform: translateY(-2px);
  	}

  	&:after {
  		content: "";
  		position: absolute;
  		left: 0;
  		top: 4px;
  		border: 5px solid transparent;
  		border-left: 5px solid var(--gray-mid);
  		transform-origin: center 75%;
  	}*/
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}
.ais-HierarchicalMenu-link .ais-HierarchicalMenu-label {
  display: flex;
  gap: 0.25rem;
  flex: 1;
  line-height: 110%;
}
.ais-HierarchicalMenu-link .ais-HierarchicalMenu-label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  background: var(--gray-dark);
  mask: url("/skins/img/svg/folder-parent.svg") no-repeat center;
  mask-size: 90%;
  transform: translateY(-3px);
  flex-shrink: 0;
}

.ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-label:before {
  mask: url("/skins/img/svg/folder.svg") no-repeat center;
  mask-size: 90%;
}

.hits-empty-state {
  position: relative;
  padding-left: 5.5rem;
  overflow: hidden;
}
.hits-empty-state-image {
  position: absolute;
  left: 0;
  top: 50%;
  transform: scale(0.5) translateY(-50%);
  transform-origin: left top;
}
.hits-empty-state-image circle, .hits-empty-state-image path:last-child {
  fill: var(--accent-color);
}

.filter-btn-tablet {
  display: none;
}

.modal-facet-filter-search {
  margin-bottom: 1rem;
}
.modal-facet-filter-search input {
  width: 50%;
}

@media (max-width: 1024px) {
  .facet-block {
    padding: 1.5rem 0;
  }
  .ais-Hits-list {
    margin-top: 0;
  }
  .facet-grid {
    display: block;
  }
  .facet-aside {
    display: none;
  }
  .facet-results {
    padding-right: 0;
  }
  .filter-btn-tablet {
    display: flex;
    margin-left: 1rem;
  }
  [data-widget=pagination] .ais-Panel {
    padding: 0;
  }
}
@media (max-width: 768px) {
  .ais-Hits-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .facet-block:not(.load) [data-widget=hits] {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .facet-header {
    flex-wrap: wrap;
  }
  .facet-header [data-widget=searchbox] {
    width: 100%;
    flex: auto;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .modal-facet-grid {
    column-count: 1;
  }
  .modal-facet-grid .ais-Panel {
    padding: 0;
  }
  .modal-facet-grid .col {
    break-before: auto;
  }
  .modal-facet-filter-btns {
    margin-top: 2rem;
  }
  .modal-facet-filter-btns:before {
    content: "";
    position: absolute;
    left: -1.5rem;
    bottom: -1.5rem;
    right: -1.5rem;
    top: -1rem;
    background: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  }
  .modal-facet-filter-btns button {
    position: relative;
  }
  .modal-facet-filter-search {
    margin-bottom: 1rem;
  }
  .modal-facet-filter-search input {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .ais-Hits-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
  }
  .ais-Hits-list .catalog-itm__name {
    word-break: break-word;
  }
  .facet-block:not(.load) [data-widget=hits] {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .filter-btn-tablet {
    margin-left: 0;
    margin-top: 1rem;
  }
  .modal-facet-filter-btns {
    display: block;
    padding: 0 1.5rem;
  }
  .modal-facet-filter-btns .btn, .modal-facet-filter-btns .ais-RefinementList-showMore {
    padding: 9px 15px;
    width: 100%;
  }
  .modal-facet-filter-btns .btn:not(:first-child), .modal-facet-filter-btns .ais-RefinementList-showMore:not(:first-child) {
    margin-top: 0.5rem;
  }
}