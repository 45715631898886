.collection-decors {
  margin-bottom: 24px;
  padding: 24px 0 74px 0;

  &__container {
    padding: 0 24px 0 16px;
  }

  &-type {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 42px;
    column-gap: 40px;

    &__name {
      margin-top: 4px;
    }
  }
}


@include tablet {
  .collection-decors-type {
    grid-template-columns: repeat(3, 1fr);
  }
}

@include big-mobile {
  .collection-decors-type {
    display: block;

    &__item {

      img {
        width: 100%;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
