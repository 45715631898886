.ag-theme-quartz {
  //--ag-font-size: var(--small) !important;
  --ag-font-size: 10px !important;
  //--ag-font-size: $fs_small !important;
  --ag-font-family: "Inter", sans-serif !important;
  //--ag-row-hover-color: rgb(0, 0, 0, 0.03) !important;
  --ag-row-hover-color: var(--bg-btn-action) !important;
	--ag-foreground-color: var(--dark-color) !important;
}
.ag-header-cell{
  font-weight: 800 !important;
}