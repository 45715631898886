.popup-preview {
  position: absolute;
  top: -125px;
  left: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  pointer-events: none;
  z-index: 10;

  &__card {
    border-radius: 4px;
    padding: 8px;
    background-color: var(--light-color);
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }

  &__image {
    //margin-bottom: 8px;
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: var(--extra-dark-color);
  }
}
