.lk-title-margin {
	margin-top: 0;
	margin-bottom: 15px;
}

.lk-bonus-grid {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 55px;

	> .btn-main {
		align-self: center;
	}

	&-itm {
		background: #FFFFFF;
		border-radius: 8px;
		margin-right: 16px;
		padding: 8px 16px;
		min-height: 111px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		width: 250px;
		max-width: 100%;

		.h3 {
			color: var(--text);
			font-weight: 700;
			padding-bottom: 4px;
			border-bottom: 3px solid var(--accent-color);
			display: inline-block;
		}

		&__desc {
			color: #C1C7CF;
			font-size: 14px;
			font-weight: 500;
			min-height: 34px;
			display: flex;
			align-items: flex-end;
			flex-wrap: wrap;
		}
	}
}

.table-bloger {
	background: #fff;
	border-radius: 8px;

	.dataTables_wrapper {
		overflow: auto;
	}

	table.dataTable {
		border-collapse: collapse;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: var(--dark-color);

		td {
			border: 1px solid #EDEFF2;
			text-align: center;
			padding: 16px;

			&:nth-child(2) {
				text-align: left;
			}

			&:first-child {
				border-left: none;
			}

			&:not(:first-child) {
				border-right: none;
				border-left: none;
			}
		}

		th {
			border-bottom: none !important;
		}
	}

	table.dataTable.no-footer {
		border-bottom: none;
	}

	.dataTables_wrapper .dataTables_paginate {
		border-top: none !important;
		position: sticky;
		left: 0;
	}

	&--bonus {
		table.dataTable {
			td {
				&:nth-child(2) {
					text-align: center;
				}

				&:nth-child(3) {
					text-align: left;
				}

				&:last-child {
					text-align: left;
				}
			}
		}

	}
}

.table-small-btn {
	padding: 8px;
	border: 1px solid var(--accent-color);
	border-radius: 4px;
	color: var(--accent-color);
	transition: all .2s ease-in-out;
	white-space: nowrap;

	@include hover {
		background: var(--accent-color);
		color: #fff;
	}
}

.lk-margin-widget {
	margin-bottom: 60px;
}

.referral-block {
	display: flex;

	&__left {
		width: 306px;
		flex-shrink: 0;
		max-width: 100%;
		margin-right: 80px;

		&__desc {
			font-weight: 300;
			font-size: 12px;
			line-height: 15px;
			color: var(--dark-color);
		}
	}

	&__right {
		color: var(--extra-dark-color);
		width: 700px;
		max-width: 100%;

		.h3 {
			margin-bottom: 16px;
		}
	}
}

.promo-how-work-itm {
	display: flex;
	align-self: center;
	margin-bottom: 25px;
	color: var(--extra-dark-color);

	&__title {
		font-weight: 600;
		margin-bottom: 8px;
	}

	&__text {
		font-size: 14px;
	}

	&__ico {
		width: 56px;
		flex-shrink: 0;
		margin-right: 16px;
		display: flex;
		align-self: center;
		justify-content: center;
	}
}

.bloger-input {
	position: relative;
	margin-bottom: 16px;
	display: block;
	width: 250px;

	input {
		height: 48px;
		padding: 0 12px;
		color: var(--dark-color);
		font-family: $F_Title;
		font-size: 14px;
		border: 1px solid #C1C7CF;
		border-radius: 4px;
		width: 100%;
		padding-top: 7px;
		transition: border-color .2s ease-in-out;
		outline: none;
		-webkit-appearance: none;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&:not(:placeholder-shown) ~ .bloger-input__label, &:focus ~ .bloger-input__label, {
			transform: translateY(-160%);
		}
	}

	&__label {
		position: absolute;
		top: 50%;
		left: 12px;
		transform: translateY(-50%);
		font-size: 10px;
		line-height: 12px;
		color: #728095;
		transition: all .2s ease-in-out;
	}

	&__copy {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		padding: 0 8px;
		width: 40px;
		cursor: pointer;
		user-select: none;

		svg {
			width: 100%;
			height: 100%;
			fill: #C1C7CF;
			transition: fill .2s ease-in-out;
		}

		@include hover {
			svg {
				fill: #334466;
			}
			~ input {
				border-color: var(--accent-color);
			}
		}
	}
}


.blogger-bonus-add-line {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 16px;

	.bloger-input {
		margin-bottom: 0;
		flex: 1;
	}

	.js-select-wrap {
		flex: 1;
	}


}

.blogger-bonus-add {
	display: flex;
	gap: 15px 80px;

	&__right {
		width: 423px;
		max-width: 45%;
	}

	&__left {
		width: 45%;
	}
}

.input-label {
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: var(--dark-color);
	margin-bottom: 6px;
}

.input-hint {
	font-size: 10px;
	color: #728095;
	margin-top: 4px;
	font-weight: 600;
}

a.input-hint {
	text-decoration: underline;
	color: var(--accent-color);

	@include hover {
		text-decoration: none;
	}
}

.bonus-count {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 40px;
	border-bottom: 1px solid #EDEFF2;
	padding-bottom: 3px;

	&__text {
		font-size: 12px;
		line-height: 18px;
		color: #202B40;
	}

	&__value {
		@extend .h3;
		color: #334466;
		font-weight: 700;
	}
}

@include extra-tablet {
	.blogger-bonus-add {
		display: block;

		&__left {
			width: 100%;
			margin-bottom: 35px;
		}

		&__right {
			width: 100%;
			max-width: 100%;
		}
	}

	.lk-bonus-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 24px;

		&-itm {
			width: 100%;
			margin-right: 0;
		}
	}

	.referral-block {
		display: block;

		&__left {
			margin-right: 0;
			margin-bottom: 25px;
		}
	}
}

@include mobile {
	.lk-bonus-grid {
		gap: 8px;

		&-itm {
			padding: 8px;

			&__desc {
				font-size: 12px;
				min-height: 28px;
			}
		}
	}
}