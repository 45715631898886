.catalog {
  background-color: #f7f8fa;
  flex: 1;
  display: flex;

  &__content {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    flex: 1;
  }

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &__title {
    margin: 16px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: var(--extra-dark-color);
  }

  &__flex {
    display: flex;
    flex-direction: column;
  }

  .dataTables_wrapper {
    flex: 1;
    width: 100%;
    height: 100%;
    margin: 0;
  }

}


@include tablet {
  .catalog__content {
    padding: 0 24px;
  }
}

@include extra-tablet {
  .catalog__content {
    display: block;
    width: 100vw;
  }
}
